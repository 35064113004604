import { Button } from "@salesforce/design-system-react";
import { useCameraModal } from "./Camera";
import { useAssetId } from "../AssetIdProvider";
import { useInspectionUpdate } from "@/features/media-manager/components/InspectionUpdate/useInspectionUpdate";
import dayjs from "dayjs";

export const CameraButton = () => {
	const { assetId } = useAssetId();
	const modal = useCameraModal();
	const { shouldAskForUpdate, openInspectionModal } =
		useInspectionUpdate(assetId);

	return (
		<Button
			iconCategory="utility"
			iconName="photo"
			iconVariant="border-filled"
			variant="icon"
			onClick={() => {
				modal.show({
					assetId,
					onRequestClose: async () => {
						const tookPhotos = await modal.hide();
						modal.remove();
						if (tookPhotos && shouldAskForUpdate(dayjs().toISOString())) {
							openInspectionModal(dayjs().toISOString());
						}
					},
				});
			}}
		/>
	);
};
