import { buyerValidation } from "./buyerValidation";
import { vestResolver } from "@hookform/resolvers/vest";
import { BuyerForm } from "./BuyerForm";
import { useForm } from "react-hook-form";

export type BuyerFormType = {
	isReadOnly: boolean;
	isActive: boolean;
	isInCareOf: boolean;
	isLienHolder: boolean;
	lastName: string;
	firstName: string;
	middleName: string;
	institutionName: string;
	address1: string;
	address2: string;
	city: string;
	county: string;
	state: string;
	zip: string;
	phoneNumber1: string;
	phoneNumber2: string;
	emailAddress: string;
	dealerLicenseNumber: string;
	identificationTypes: number[];
	driversLicense?: {
		number?: string | null;
		state?: string | null;
		expiration?: string | null;
	} | null;
	ssein: string;
	isIdentificationImaged: boolean;
	ofacPullDate?: string | null;
	ofacPullRequired: boolean;
};

const defaultForm = {
	isReadOnly: false,
	isActive: true,
	isInCareOf: false,
	isLienHolder: false,
	phoneNumber1: "",
	identificationTypes: [],
	driversLicense: {
		number: null,
		state: null,
		expiration: null,
	},
	isIdentificationImaged: false,
	ofacPullDate: null,
	ofacPullRequired: false,
};

export const NewBuyer = ({
	addBuyer,
	isAddingBuyer,
}: {
	addBuyer: (buyer: BuyerFormType) => void;
	isAddingBuyer: boolean;
}) => {
	const { reset, handleSubmit, control, watch } = useForm<BuyerFormType>({
		resolver: vestResolver(buyerValidation),
		defaultValues: defaultForm,
		reValidateMode: "onBlur",
		shouldFocusError: false,
	});

	const onSubmit = handleSubmit((data) => {
		const submissionData: BuyerFormType = {
			...data,
		};

		if (
			!submissionData.driversLicense?.number &&
			!submissionData.driversLicense?.state
		) {
			submissionData.driversLicense = null;
		}
		if (!submissionData.phoneNumber2) {
			//@ts-ignore
			submissionData.phoneNumber2 = null;
		}
		addBuyer(submissionData);
	});

	return (
		<div>
			<form onSubmit={onSubmit}>
				<BuyerForm
					control={control}
					watch={watch}
					reset={reset}
					isSubmitting={isAddingBuyer}
				/>
			</form>
		</div>
	);
};
