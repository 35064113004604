import {
	FormProvider,
	useController,
	useForm,
	useFormContext,
} from "react-hook-form";
import { useEffect, useRef } from "react";
import { debounce } from "lodash";
import { Input, InputIcon } from "@salesforce/design-system-react";
import classes from "./SearchForm.module.scss";
import { useSearchAssetList } from "../../api/useSearchAssetList";
import { useAssetListParams } from "../../api/useAssetListParams";

export const SearchForm = (props) => {
	const { trigger, status } = useSearchAssetList();
	const [{ q }] = useAssetListParams();

	const { handleSubmit, control, reset } = useForm({
		mode: "onSubmit",
		defaultValues: {
			search: "",
		},
	});

	const submitRef = useRef();

	const submitFn = () => {
		submitRef.current?.click();
	};

	useEffect(() => {
		if (!q) {
			reset({
				search: "",
			});
		}
	}, [q, reset]);

	return (
		<FormProvider {...{ handleSubmit, control, submitFn }}>
			<form
				onSubmit={handleSubmit((data) => {
					trigger(data?.search);
				})}
			>
				<SearchInput
					control={control}
					name="search"
					isFetching={status === "pending"}
				/>
				<input type="submit" ref={submitRef} hidden />
			</form>
		</FormProvider>
	);
};

function SearchInput({ name, control, isFetching, ...props }) {
	const { field } = useController({ name });
	const { submitFn } = useFormContext();

	const handleChange = (e, { value }) => {
		field.onChange(value);
		debounce(() => {
			submitFn();
		}, 500)();
	};
	return (
		<Input
			value={field.value}
			inputRef={field.ref}
			placeholder="Search"
			iconRight={
				isFetching ? (
					<InputIcon
						name="spinner"
						category="utility"
						className={
							classes.spinner +
							" slds-input__icon slds-icon-text-default slds-input__icon_right"
						}
					/>
				) : (
					<InputIcon name="search" category="utility" />
				)
			}
			onChange={handleChange}
		/>
	);
}
