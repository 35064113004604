import { bathroomConfig as bathroomConfigObj } from "./bathroomConfig";
import { bedroomConfig as bedroomConfigObj } from "./bedroomConfig";
import { exteriorConfig } from "./exteriorConfig";
import { overviewConfig } from "./overviewConfig";
import { kitchenConfig } from "./kitchenConfig";
import { generalConfig } from "./generalConfig";
import { livingRoomConfig } from "./livingRoomConfig";
import { interiorConfig } from "./interiorConfig";
import { BATHROOM, BEDROOM } from "../constants/constants";

export {
	livingRoomConfig,
	exteriorConfig,
	overviewConfig,
	kitchenConfig,
	generalConfig,
	interiorConfig,
};

export const bedroomConfig = (bedrooms) => {
	return configCreator(bedrooms, bedroomConfigObj, BEDROOM);
};

export const bathroomConfig = (bathrooms) => {
	return configCreator(bathrooms, bathroomConfigObj, BATHROOM);
};

const configCreator = (length, config, title) => {
	const array = Array.from({ length: length }, (element, index) =>
		config.map((field) => {
			return {
				...field,
				props: {
					...field.props,
					name: `${title}[${index}].${field.props.name}`,
				},
			};
		})
	);
	return array;
};
