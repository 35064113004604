import { useSelectAssetList } from "./useLazyAssetList";
import { getAllAssets } from "./endpoints";

export const usePageAssetList = () => {
	const { originalArgs, ...rest } = useSelectAssetList();

	const [trigger] = getAllAssets.useLazyQuerySubscription();

	const triggerPage = (page) => {
		trigger({
			...originalArgs,
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: page,
			},
		});
	};

	const pagination = {
		totalItems: rest?.data?.totalItems,
		pageNumber: rest?.data?.pageNumber,
		itemsPerPage: rest?.data?.itemsPerPage,
	};

	return {
		pagination: pagination,
		status: rest.status,
		trigger: triggerPage,
	};
};
