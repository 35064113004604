import { toggleIncludeWebReady } from "@/ReduxToolkit/MediaSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectIncludeWebReady } from "../selectors";

export const useIncludeWebReady = (assetId) => {
	const isIncluded = useSelector(selectIncludeWebReady(assetId));
	const dispatch = useDispatch();

	const toggleIsIncluded = () => {
		dispatch(toggleIncludeWebReady(assetId));
	};

	return [isIncluded, toggleIsIncluded];
};
