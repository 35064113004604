import {
	PageHeader,
	PageHeaderControl,
	Icon,
	Dropdown,
	Button,
	ButtonGroup,
} from "@salesforce/design-system-react";
import { Desktop, Mobile, useIsMobile } from "../../../../Responsive";
import { useDeleteAllOfflineAssets } from "../../../offline-use/components/OfflineAssetDelete/useDeleteAllOfflineAssets";
import { Modal } from "@salesforce/design-system-react";
import classes from "../../../offline-use/components/OfflineAssetDelete/OfflineAssetDelete.module.scss";
import { useIsOnline } from "@/features/offline-use";
import { useState } from "react";
import { useAssetListSplitView } from "../../hooks/useAssetListSplitView";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import { SortButton } from "./SortButton";
import { useAllPendingSyncItems } from "@/features/offline-use/api/hooks/usePendingSyncItems";
import { SyncRetry } from "@/features/offline-use/components/SyncRetry/SyncRetry";
import { words } from "lodash";
import { upperFirst } from "lodash";
import { camelCase } from "lodash";
import { useFilterPanel } from "@/features/media-manager/hooks/useFilterPanel";
import {
	useDefaultViews,
	useLazyAssetListView,
} from "../../api/useLazyAssetList";
import { SearchForm } from "../Search/SearchForm";
import { usePageAssetList } from "../../api/usePageAssetList";

const TablePageHeader = () => {
	const { triggerDelete, isDeleteDisabled } = useDeleteAllOfflineAssets();
	const isOnline = useIsOnline();
	const { view, trigger: triggerView } = useLazyAssetListView();
	const { setSplitView } = useAssetListSplitView();
	const filterNames = useDefaultViews();
	const { pagination } = usePageAssetList();
	const totalAssets = pagination?.totalItems;
	const { setIsOpenFilterPanel, isOpenFilterPanel } = useFilterPanel();
	const isMobile = useIsMobile();

	const optionsArray = filterNames
		.filter((key) => {
			if (!isMobile && key === "fieldUse") {
				return false;
			} else {
				return true;
			}
		})
		.map((key) => {
			const value = key;
			const label = words(upperFirst(key)).join(" ");
			return {
				label,
				value,
			};
		});
	const switchTitle = (e) => {
		triggerView(e.value);
	};

	const pendingItems = useAllPendingSyncItems();

	const [isOpen, setIsOpen] = useState(false);
	const confirmationDialog = useConfirmationDialog();

	const title = view
		? words(upperFirst(camelCase(view))).join(" ")
		: "My Assets";

	const actions = () => (
		<>
			<PageHeaderControl>
				<ButtonGroup variant="list">
					<Desktop>
						<Dropdown
							align="right"
							assistiveText={{ icon: "Change view" }}
							id="page-header-dropdown-object-home-content-right-2"
							onSelect={(e) => {
								setSplitView(e.value);
							}}
							buttonVariant="icon"
							iconCategory="utility"
							iconName="table"
							iconVariant="border-filled"
							openOn="click"
							variant="icon"
							options={[
								{ label: "Table View", value: "false" },
								{ label: "Split View", value: "true" },
							]}
						/>
					</Desktop>

					<Mobile>
						{!pendingItems.length > 0 && (
							<Button
								assistiveText={{ icon: "Remove Field Use Assets" }}
								iconCategory="utility"
								iconName="delete"
								iconVariant="border-filled"
								variant="icon"
								onClick={() => {
									confirmationDialog.show({
										title: "Remove Assets",
										message:
											"Are you sure you want to remove all field use assets?",
										confirmButtonText: "Remove",
										action: () => triggerDelete(),
									});
								}}
								disabled={isDeleteDisabled}
							/>
						)}
						{pendingItems.length > 0 && isOnline && <SyncRetry />}
						<SortButton />
					</Mobile>
					<Button
						onClick={() => {
							setIsOpenFilterPanel(!isOpenFilterPanel);
						}}
						iconCategory="utility"
						iconName="filterList"
						iconVariant="border-filled"
						variant="icon"
					/>
				</ButtonGroup>
			</PageHeaderControl>
		</>
	);

	return (
		<>
			<PageHeader
				onRenderActions={actions}
				onRenderControls={() => (
					<PageHeaderControl>
						<SearchForm />
					</PageHeaderControl>
				)}
				icon={
					<Icon
						assistiveText={{ label: "User" }}
						category="standard"
						name="home"
					/>
				}
				joined
				label="Asset List"
				truncate
				info={`${totalAssets ?? 0} items`}
				variant="object-home"
				title={isOnline ? title : "Field Use"}
				nameSwitcherDropdown={
					isOnline && (
						<Dropdown
							width="x-small"
							align="right"
							assistiveText={{ icon: "Name Switcher" }}
							buttonClassName="slds-button_icon-small"
							buttonVariant="icon"
							iconCategory="utility"
							iconName="down"
							id="page-header-name-switcher-dropdown"
							checkmark
							onSelect={switchTitle}
							options={optionsArray}
							value={view}
						/>
					)
				}
			/>
			<Modal
				size="large"
				isOpen={isOpen}
				onRequestClose={() => setIsOpen(false)}
				heading="Remove Field Use Assets"
				footer={[
					<Button
						label="Cancel"
						key="cancel"
						onClick={() => setIsOpen(false)}
					/>,
					<Button
						label="Confirm"
						key="confirm"
						variant="brand"
						onClick={() => {
							setIsOpen(false);
							triggerDelete();
						}}
						// {...icon}
					/>,
				]}
			>
				<div className={classes.modalText}>
					Are you sure you want to remove all field use assets?
				</div>
			</Modal>
		</>
	);
};

export default TablePageHeader;
