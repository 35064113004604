import { Slider } from "@salesforce/design-system-react";
import classes from "./PhotoToolbar.module.scss";
import { scalePow } from "d3-scale";

const scale = scalePow().domain([1, 255]).range([0, 1]).exponent(Math.E);

const scaleBrightness = (y) => {
	return scale(Math.abs(y)) * Math.sign(y);
};

const scaleBrightnessInv = (x) => {
	const inv = scale.invert(Math.abs(x));
	return inv * Math.sign(x); //inv * Math.sign(x);
};

export const BrightnessRange = ({ onChange, value }) => {
	const handleChange = (val) => {
		onChange(scaleBrightness(val));
	};

	return (
		<label className={classes.slider} htmlFor="brightness">
			<div style={{ display: "inline-block", paddingLeft: "1rem" }}>
				<Slider
					size="small"
					name="brightness"
					type="range"
					min={-255}
					max={255}
					value={Math.round(scaleBrightnessInv(value))}
					onChange={(e, { value }) => handleChange(value)}
				/>
			</div>
		</label>
	);
};
