import {
	roofLoad,
	hvacType,
	thermalZone,
	windZone,
	lockTypes,
	winterizedType,
	skipReasonType,
} from "@/Utilities/enums";

export const completedDetails = (conditionReport, asset) => {
	return [
		{
			label: "Total Estimated Refurb Cost",
			content: "$" + conditionReport?.totalEstimatedRefurbCost ?? 0,
		},
		{
			label: "Roof Load",
			content: roofLoad[asset?.roofLoad] ?? "None",
		},
		{
			label: "Hvac Type",
			content: hvacType[asset?.hvacType] ?? "None",
		},
		{
			label: "Hitch Under Unit",
			content: conditionReport?.isHitchUnderUnit ? "Yes" : "No",
		},
		{
			label: "Door Strap",
			content: conditionReport?.hasDoorStrap ? "Yes" : "No",
		},
		{ label: "Locks", content: lockTypes[conditionReport?.locks] ?? "None" },
		{ label: "Key Location", content: conditionReport?.keyLocation ?? "None" },
		{ label: "Lock Box Code", content: conditionReport?.lockBoxCode ?? "None" },
		{
			label: "Winterized",
			content: winterizedType[conditionReport?.winterized] ?? "None",
		},
		{
			label: "Skip Reason",
			content: skipReasonType[conditionReport?.skipReason] ?? "None",
		},
		{
			label: "Is Final",
			content: conditionReport?.isFinal ?? "No" ? "Yes" : "No",
		},
		{
			label: "Is Skipped",
			content: conditionReport?.isSkipped ?? "No" ? "Yes" : "No",
		},
		{ label: "Notes", content: conditionReport?.notes ?? "None" },
	];
};

export const incompleteDetails = (asset, bedrooms, bathrooms) => {
	return [
		{
			label: "Width",
			content: asset.width,
		},
		{
			label: "Length",
			content: asset.length,
		},
		{
			label: "Thermal Zone",
			content: thermalZone[asset.thermalZone],
		},
		{
			label: "Wind Zone",
			content: windZone[asset.windZone],
		},
		{
			label: "Roof Load",
			content: roofLoad[asset.roofLoad],
		},
		{
			label: "Sections",
			content: asset.sections,
		},
		{
			label: "Serial Number",
			content: asset.serialNumber,
		},
		{
			label: "Hud Number 1",
			content: asset.hudNumber1,
		},
		{
			label: "Bedrooms",
			content: bedrooms,
		},
		{
			label: "Bathrooms",
			content: bathrooms,
		},
	];
};
