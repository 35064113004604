import { Button, Input } from "@salesforce/design-system-react";
import classes from "./Communities.module.scss";
import { useForm, Controller, useWatch } from "react-hook-form";

export const CommunitiesForm = ({ onSubmit }) => {
	const {
		control,
		handleSubmit,
		formState: { errors },
		//reset,
	} = useForm({
		mode: "onBlur",
	});
	const fields = useWatch({
		name: ["name", "id", "address", "city", "state", "zip", "phone"],
		control,
	});
	const canSubmit =
		fields.filter((item) => {
			return item?.length > 1;
		})?.length > 0;

	return (
		<form id="communitySearch" onSubmit={handleSubmit(onSubmit)}>
			<section className={classes.container}>
				<div className={classes.formElement}>
					<Controller
						name="name"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="Community Name"
								className={classes.label}
								type="text"
								name="name"
								defaultValue=""
								errorText={errors.name?.message}
								placeholder="Community Name"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.formElement}>
					<Controller
						name="id"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="Community Number"
								className={classes.label}
								type="text"
								name="id"
								defaultValue=""
								errorText={errors.id?.message}
								placeholder="Community Number"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.address}>
					<Controller
						name="address"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="Address"
								className={classes.label}
								type="text"
								name="address"
								autoComplete="email"
								defaultValue=""
								errorText={errors.address?.message}
								placeholder="Address"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.formElement}>
					<Controller
						name="city"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="City"
								className={classes.label}
								type="text"
								name="city"
								defaultValue=""
								errorText={errors.city?.message}
								placeholder="City"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.formElement}>
					<Controller
						name="state"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="State"
								className={classes.label}
								type="text"
								name="state"
								defaultValue=""
								errorText={errors.state?.message}
								placeholder="State"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.formElement}>
					<Controller
						name="zip"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="Zip"
								className={classes.label}
								type="text"
								name="zip"
								defaultValue=""
								errorText={errors.phoneNumber?.message}
								placeholder="Zip Code"
								{...rest}
							/>
						)}
					/>
				</div>
				<div className={classes.formElement}>
					<Controller
						name="phone"
						control={control}
						defaultValue=""
						render={({ field: { ref, ...rest } }) => (
							<Input
								ref={ref}
								label="Phone Number"
								className={classes.label}
								type="text"
								name="phone"
								defaultValue=""
								errorText={errors.phoneNumber?.message}
								placeholder="Phone Number"
								{...rest}
							/>
						)}
					/>
				</div>
			</section>
			<div className={classes.footer}>
				{!canSubmit && (
					<p className={classes.message}>
						Type at least 2 characters to search
					</p>
				)}
				<Button
					className={classes.submitButton}
					disabled={!canSubmit}
					label="Search"
					variant="brand"
					onClick={() => handleSubmit(onSubmit)()}
					form="communitySearch"
				/>
			</div>
		</form>
	);
};
