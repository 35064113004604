import { ExpandableSection } from "@salesforce/design-system-react";
import { createContext, useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";

const FormSectionContext = createContext();

export const FormSection = ({
	isStatic: isStaticOption = true,
	isReadOnly,
	isExpandable = true,
	children,
	title,
}) => {
	const {
		isStatic: formIsStatic,
		setIsStatic: setFormIsStatic,
		setFocusField,
		activeSection,
		isConcurrencyError,
		setActiveSection,
	} = useFormContext();

	const sectionIsStatic = !(activeSection === title);
	const isNotActiveSection = isConcurrencyError
		? false
		: !formIsStatic && sectionIsStatic;

	const isStatic = formIsStatic || sectionIsStatic;

	const editField = (name) => {
		setActiveSection(title);
		setFormIsStatic(false);
		setFocusField(name);
	};

	useEffect(() => {
		if (formIsStatic) {
			setActiveSection(null);
		}
		if (!isStaticOption) {
			setActiveSection(title);
		}
	}, [formIsStatic, isStaticOption, setActiveSection, title]);

	return (
		<FormSectionContext.Provider
			value={{ isStatic, isReadOnly, onClickEdit: editField }}
		>
			{isExpandable ? (
				<ExpandableSection title={title}>
					<fieldset disabled={isNotActiveSection}>{children}</fieldset>
				</ExpandableSection>
			) : (
				<fieldset disabled={isNotActiveSection}>{children}</fieldset>
			)}
		</FormSectionContext.Provider>
	);
};

export const useFormSection = () => {
	return useContext(FormSectionContext);
};
