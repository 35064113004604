import { Modal } from "@salesforce/design-system-react";
import { useCommunities } from "../api/useCommunities";
import { useState } from "react";
import { CommunitiesForm } from "./CommunitiesForm";
import { CommunitiesSearch } from "./CommunitiesSearch";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const Communities = ({ isOpen, remove, submission }) => {
	const { isSuccess, result, search } = useCommunities();
	const [isSearching, setIsSearching] = useState(true);
	const onSubmit = (data) => {
		setIsSearching(false);
		search(data);
	};
	return (
		<Modal
			size="large"
			isOpen={isOpen}
			onRequestClose={remove}
			heading="Community Search"
		>
			<CommunitiesForm onSubmit={onSubmit} />
			{!isSearching && isSuccess && (
				<CommunitiesSearch
					submit={(data) => {
						submission(data);
						remove();
					}}
					onCancel={() => {
						setIsSearching(true);
					}}
					communities={result}
				/>
			)}
		</Modal>
	);
};

const CommunitiesModal = NiceModal.create(({ submission }) => {
	const modal = useModal();
	return (
		<Communities
			isOpen={modal.visible}
			submission={submission}
			remove={modal.remove}
		/>
	);
});

NiceModal.register("communities-modal", CommunitiesModal);

export const useCommunitiesModal = () => useModal("communities-modal");
