import React from "react";

import {
	DataTable,
	DataTableCell,
	DataTableColumn,
} from "@salesforce/design-system-react";
import { useSort } from "@/features/asset-list/hooks/useSort";

const CommunitySelect = (props) => {
	return (
		<DataTableCell {...props}>
			<input
				type="checkbox"
				checked={props.communitySelected === props.item.id}
				onChange={() => props.onChange(props.item.id)}
			/>
		</DataTableCell>
	);
};
CommunitySelect.displayName = DataTableCell.displayName;

const config = {
	name: {
		label: "Name",
		title: "community",
	},
	id: {
		label: "#",
		title: "communityNumber",
		width: "20px",
	},
	streetAddressLine1: {
		label: "Address",
		title: "address",
		width: "80px",
	},
	city: {
		label: "City",
		title: "city",
	},
	state: {
		label: "State",
		title: "state",
		width: "20px",
	},
	zip: {
		label: "Zip",
		title: "zip",
		width: "30px",
	},
	managerName: {
		label: "Manager Name",
		title: "managerName",
	},
	phone: {
		label: "Phone",
		title: "phone",
		width: "35px",
	},
	select: {
		label: " ",
		component: CommunitySelect,
		title: "select",
		width: "10px",
	},
};

export const CommunitiesTable = ({ data, ...props }) => {
	const { sort, state, dispatch } = useSort({
		column: "communityNumber",
		direction: "desc",
	});
	const tableData = sort(
		data.map((item) => {
			const { address, ...rest } = item;
			return {
				...address,
				...rest,
			};
		})
	);
	const handleSort = (sortColumn) => {
		const { property: sortProperty, sortDirection } = sortColumn;
		dispatch({ column: sortProperty, direction: sortDirection });
	};

	const configCols = Object.keys(config);

	return (
		<>
			<div style={{ overflow: "auto", height: "100%", maxHeight: "50vh" }}>
				<DataTable
					assistiveText={{
						columnSort: "sort this column",
						columnSortedAscending: "asc",
						columnSortedDescending: "desc",
						selectAllRows: "Select all rows",
						selectRow: "Select this row",
					}}
					style={{ fontSize: ".8em", minWidth: "800px" }}
					items={tableData}
					id="CommunitiesSearchTable"
					onSort={handleSort}
					fixedHeader
					fixedLayout
					columnBordered
				>
					{configCols.map((column) => {
						return (
							<DataTableColumn
								key={column}
								width={config[column]?.width ? config[column].width : "50px"}
								label={config[column]?.label ? config[column].label : column}
								property={column}
								sortable
								isSorted={state.column === column}
								sortDirection={state.direction}
							>
								{config[column]?.component ? (
									React.createElement(config[column].component, {
										...{ anchor: column.id },
										...config[column].props,
										title: config[column]?.title,
										...props,
									})
								) : (
									<DataTableCell title={config[column]?.title} />
								)}
							</DataTableColumn>
						);
					})}
				</DataTable>
			</div>
		</>
	);
};
