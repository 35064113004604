import React from "react";
import { DesktopTableView } from "./DesktopTable/DesktopTableView";
import { MobileTableView } from "./MobileTableView/MobileTableView";
import classes from "./AssetList.module.scss";
import { Desktop, Mobile } from "../../../Responsive";
import AssetListSplitView from "./SplitView/AssetListSplitView";
import TablePageHeader from "./TablePageHeader/TablePageHeader";
import { useAssetListSplitView } from "../hooks/useAssetListSplitView";
import { FilterPanelContext } from "@/features/media-manager/hooks/useFilterPanel";

const AssetList = () => {
	const { isSplitView } = useAssetListSplitView();

	return (
		<FilterPanelContext>
			<div className={classes.Container}>
				<TablePageHeader />
				<div className={classes.Content}>
					<>
						<Desktop>
							{!isSplitView ? <DesktopTableView /> : <AssetListSplitView />}
						</Desktop>
						<Mobile>
							<MobileTableView />
						</Mobile>
					</>
				</div>
			</div>
		</FilterPanelContext>
	);
};

export default AssetList;
