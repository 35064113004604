import {
	getDispositionOfTitle,
	getLoanOrigination,
	getWindZone,
} from "@/Utilities/enums";
import dayjs from "dayjs";
import { get } from "lodash";
import {
	ACCOUNT_STATUS,
	ASSET_MANAGER,
	ASSIGNED_DATE,
	BALANCE,
	CITY,
	CUSTOMER_NAME,
	DISPLAY_ACCOUNT,
	DISPOSITION_OF_TITLE,
	LOAN_ORIGINATION,
	LOT_NUMBER,
	MARKET_READY_DATE,
	MASTER_DEALER,
	NOTICE_OF_SALE_EXPIRES,
	PERSONAL_ITEMS_LETTER_EXPIRATION,
	PROJECTED_RECOVERY_PERCENT,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	VANTAGE_STATUS,
	WIND_ZONE,
	ZIP,
} from "../../constants";

const formatDate = (value) => {
	return value ? dayjs(value).format("MM/DD/YYYY") : "";
};

export const headerDetails = (asset, statuses) => {
	const now = dayjs();
	const age = now.diff(get(asset, ASSIGNED_DATE.path), "days");

	return [
		{ label: "Display Number", content: get(asset, DISPLAY_ACCOUNT.path) },
		{ label: "Account Balance", content: "$" + get(asset, BALANCE.path) },
		{ label: "City", content: get(asset, CITY.path) },
		{ label: "State", content: get(asset, STATE.path) },
		{ label: "Zip", content: get(asset, ZIP.path) },
		{
			label: "Assigned",
			content: dayjs(get(asset, ASSIGNED_DATE.path)).format("MM/DD/YYYY"),
		},
		{ label: "Age", content: age + " Days" },
		{ label: "Last Name", content: get(asset, CUSTOMER_NAME.path) },
		{ label: "Responsible AM", content: get(asset, ASSET_MANAGER.path) },
		{ label: "VMF Account Status", content: get(asset, ACCOUNT_STATUS.path) },
		{
			label: "Remarketing Asset Status",
			content: statuses[get(asset, REMARKETING_STATUS_TYPE_ID.path)],
		},
		{
			label: "Personal Items Letter Expiration Date",
			content: formatDate(get(asset, PERSONAL_ITEMS_LETTER_EXPIRATION.path)),
		},
		{
			label: "Notice of Sale Letter Expiration Date",
			content: formatDate(get(asset, NOTICE_OF_SALE_EXPIRES.path)),
		},
		{
			label: "Projected Recovery Percentage",
			content: <span>{get(asset, PROJECTED_RECOVERY_PERCENT.path)}%</span>,
		},
		{
			label: "Market Ready Date",
			content: formatDate(get(asset, MARKET_READY_DATE.path)),
		},
		{ label: "Wind Zone", content: getWindZone(get(asset, WIND_ZONE.path)) },
		{
			label: "Disposition of Title",
			content: getDispositionOfTitle(get(asset, DISPOSITION_OF_TITLE)),
		},
		{
			label: "Loan Origination",
			content: getLoanOrigination(get(asset, LOAN_ORIGINATION)),
		},
		{ label: "Master Dealer", content: get(asset, MASTER_DEALER) },
		{ label: "Lot", content: get(asset, LOT_NUMBER) },
		{ label: "Vantage Status", content: get(asset, VANTAGE_STATUS) },
	];
};
