import { AiOutlineExclamationCircle } from "react-icons/ai";

export const ErrorBadge = ({ onClick }) => {
	return (
		<AiOutlineExclamationCircle
			style={{
				color: "red",
				width: "1.5rem",
				height: "1.5rem",
			}}
		/>
	);
};
