import {
	BrowserAuthError,
	InteractionRequiredAuthError,
	PublicClientApplication,
} from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import config, { load } from "./providers/config/config";

let instance;
let client_id;

const getInstance = async () => {
	await load();

	const clientId =
		config.AUTH_CLIENT_ID === "#{AUTH_CLIENT_ID}"
			? process.env.REACT_APP_CLIENT_ID
			: config.AUTH_CLIENT_ID;

	const tenantId =
		config.AUTH_TENANT_ID === "#{AUTH_TENANT_ID}"
			? process.env.REACT_APP_TENANT_ID
			: config.AUTH_TENANT_ID;

	const configuration = {
		auth: {
			authority: `https://login.microsoftonline.com/${tenantId}`,
			clientId: clientId,
			protocolMode: "OIDC",
			redirectUri: window.location.origin,
			postLogoutRedirectUri: window.location.origin,
		},
		cache: {
			cacheLocation: "localStorage",
			secureCookies: true,
		},
	};

	const pca = new PublicClientApplication(configuration);
	pca.initialize();
	instance = pca;
	client_id = clientId;
	return pca;
};

getInstance();

export const useIsAuth = useIsAuthenticated;

export const acquireAccessToken = async () => {
	const accounts = instance.getAllAccounts();

	// if (!activeAccount && accounts.length === 0) {
	// 	/*
	// 	 * User is not signed in. Throw error or wait for user to login.
	// 	 * Do not attempt to log a user in outside of the context of MsalProvider
	// 	 */
	// }

	const request = {
		scopes: [
			"openId",
			"profile",
			"offline_access",
			`${client_id}/HomebaseGatewayAPI`,
		],
		account: accounts[0], //activeAccount || accounts[0],
	};

	try {
		const authResult = await instance.acquireTokenSilent(request);
		return authResult.accessToken ?? "";
	} catch (e) {
		if (e instanceof InteractionRequiredAuthError) {
			await instance.acquireTokenRedirect(request);
		}

		if (
			e instanceof BrowserAuthError &&
			e.message.includes("no_network_connectivity")
		) {
			return "";
		}
	}
};

export { instance as pca };
