import React from "react";
import { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal } from "@salesforce/design-system-react";
import {
	useGetSaleByIdQuery,
	useUpdateSaleMutation,
} from "../../api/endpoints";
import { Buyer } from "../../types";
import { SaleIdProvider } from "../SaleContext";
import { EditBuyer } from "./EditBuyer";

export const EditBuyerModal = NiceModal.create(
	({ saleId, buyerId }: { saleId: string; buyerId: string }) => {
		const { data: sale } = useGetSaleByIdQuery(saleId);
		const [isEditingBuyer, setIsEditingBuyer] = useState<boolean>(false);
		const [trigger] = useUpdateSaleMutation();
		const editBuyer = async (buyer: Buyer) => {
			if (sale) {
				setIsEditingBuyer(true);

				const existingBuyers = sale.buyerInterests.filter(
					(item) => item.id !== buyerId
				);

				const res = await trigger({
					...sale,
					buyerInterests: [...existingBuyers, buyer],
				});
				setIsEditingBuyer(false);
				if (!("error" in res)) {
					modal.remove();
				}
			}
		};

		const modal = useModal();
		const buyer = sale?.buyerInterests.find((item) => item.id === buyerId);

		return (
			<Modal
				heading="Edit Buyer"
				dismissOnClickOutside={false}
				isOpen={modal.visible}
				size="large"
				onRequestClose={modal.remove}
			>
				<div className="logrocket-hide">
					<SaleIdProvider saleId={saleId}>
						{saleId && buyer && (
							<EditBuyer
								buyer={buyer}
								editBuyer={editBuyer}
								isEditingBuyer={isEditingBuyer}
							/>
						)}
					</SaleIdProvider>
				</div>
			</Modal>
		);
	}
);

NiceModal.register("edit-buyer", EditBuyerModal);

export const useEditBuyerModal = () => useModal("edit-buyer");
