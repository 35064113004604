import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useDeleteMediaMutation } from "../endpoints";

export const useDeleteMedia = (assetId) => {
	const [trigger, result] = useDeleteMediaMutation({
		fixedCacheKey: "delete-media",
	});
	const dispatch = useDispatch();

	const deleteMedia = (media) => trigger({ assetId, media });

	useEffect(() => {
		if (result?.error) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong deleting media`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	}, [dispatch, result?.error, result.requestId]);

	return { deleteMedia, ...result };
};
