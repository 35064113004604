import { InsuranceTableLayout } from "@/features/insurance-cancellation-queue/layouts/InsuranceTableLayout";
import { useInsuranceQueue } from "./hooks/useInsuranceQueue";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { ErrorContainer } from "../errors/ErrorContainer";
export const InsuranceCancellationQueue = () => {
	const { data, isLoading, isError, isSuccess, error } = useInsuranceQueue();
	return (
		<>
			{isLoading && (
				<div
					style={{
						backgroundColor: "white",
						display: "flex",
						height: "fit-content",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<Spinner />
					<p style={{ color: "black", padding: "2rem" }}>
						Pulling in the insurance cancellation queue. Please wait a moment
						while we process the request.
					</p>
				</div>
			)}
			{isError && <ErrorContainer error={error} />}
			{isSuccess && <InsuranceTableLayout data={data} />}
		</>
	);
};
