import React, { useRef } from "react";
import classes from "./ImagingFile.module.scss";
import { File, Icon } from "@salesforce/design-system-react";
import { startCase } from "lodash";
import { useDownloadDocument } from "../api/useDownloadDocument";

export const ImagingFile = ({ document, accountNumber }) => {
	const linkRef = useRef();

	const relevantKeywords = document?.keywords
		?.filter(
			(item) =>
				item.key.toLowerCase().includes("doc subtype") ||
				item.key.toLowerCase().includes("doc type")
		)
		.map((item) => startCase(item.value.toLowerCase()));
	const docName =
		relevantKeywords.length > 0
			? relevantKeywords.join(" - ")
			: document?.docName;

	const { fetchDoc, progress, isLoading } = useDownloadDocument(
		document?.documentId,
		accountNumber
	);

	const initiateDownload = async () => {
		const { data, error } = await fetchDoc();
		if (!error) {
			linkRef.current.href = data;
			linkRef.current.click();
		}
	};

	return (
		<>
			<File
				id={`media-card-hello`}
				labels={{
					title: isLoading
						? `Downloaded ${Number((progress ?? 0) / 1048576).toFixed(2)} MB`
						: docName ?? "",
				}}
				icon={<Icon category="doctype" name="pdf" />}
				className={classes.card}
				onClickImage={() => initiateDownload()}
			/>
			<a
				href="/"
				style={{ display: "none" }}
				title={`${document?.docName}.pdf`}
				target="_blank"
				ref={linkRef}
			>
				{document?.documentId}
			</a>
		</>
	);
};
