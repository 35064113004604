import { createContext, useContext, useEffect, useRef } from "react";
import TrimbleMaps from "@/lib/trimblemaps";
import { reset } from "../store/tripPlanningSlice";
import { useDispatch } from "react-redux";
const TripPlanningContext = createContext({});

export const TripPlanningProvider = ({ children }) => {
	const mapRef = useRef(null);
	const routeRef = useRef(null);
	const dispatch = useDispatch();
	useEffect(() => {
		mapRef.current = new TrimbleMaps.Map({
			container: "trimbleMap",
			style: TrimbleMaps.Common.Style.MOBILE_DAY,
			center: new TrimbleMaps.LngLat(-96, 39),
			region: "na",
			zoom: 3.2,
		});
		mapRef.current?.on("load", () => {
			routeRef.current = new TrimbleMaps.Route({
				routeId: "homebaseRoute",
				stops: [],
				showArrows: false,
				frameRoute: false,
				routeOptimization:
					TrimbleMaps.Common.RouteOptimization.OPTIMIZE_INTERMEDIATE_STOPS,
			});
			routeRef.current.addTo(mapRef.current);
		});
		// look for destroy methods for cleanup functions
		return () => {
			routeRef.current.remove();
			mapRef.current.remove();
			dispatch(reset());
		};
		// eslint-disable-next-line
	}, []);

	return (
		<TripPlanningContext.Provider
			value={{
				mapRef,
				routeRef,
			}}
		>
			{children}
		</TripPlanningContext.Provider>
	);
};

export const useTripPlanningProvider = () => {
	const { mapRef, routeRef } = useContext(TripPlanningContext);

	return {
		mapRef,
		routeRef,
	};
};
