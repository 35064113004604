import { Button } from "@salesforce/design-system-react";
import { CSSProperties, ReactNode, useState } from "react";
import { useIsEditable } from "../hooks/useIsEditable";
import classes from "./StaticInput.module.scss";

interface StaticInputProps {
	children: ReactNode;
	editable?: boolean;
	onClickEdit?: () => void;
	containerStyle?: CSSProperties;
	onDoubleClick?: () => void;
}

export const StaticInput = ({
	children,
	editable,
	onClickEdit,
	containerStyle,
	onDoubleClick,
	...props
}: StaticInputProps) => {
	const [isHovered, setIsHovered] = useState(false);
	const isEditable = useIsEditable();
	return (
		<div
			className={classes.container}
			style={containerStyle}
			{...(isEditable && {
				onMouseEnter: () => {
					setIsHovered(true);
				},
				onMouseLeave: () => {
					setIsHovered(false);
				},
				onClick: onDoubleClick,
			})}
		>
			<p className={classes.input} {...props}>
				{children}
			</p>
			{editable && isEditable && (
				<Button
					variant="icon"
					iconCategory="utility"
					iconName="edit"
					style={{
						marginLeft: ".5rem",
						visibility: isHovered ? "visible" : "hidden",
					}}
					onClick={onClickEdit}
				></Button>
			)}
		</div>
	);
};

export const StaticPercentInput = ({ children }: { children: string }) => {
	return <p className={`${classes.input} ${classes.percent}`}>{children}</p>;
};
