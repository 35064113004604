import { pricingIndex } from "@/features/condition-report/constants/constants";
import { createSlice } from "@reduxjs/toolkit";

export const conditionReportSlice = createSlice({
	name: "conditionReport",
	initialState: {
		pricing: pricingIndex,
	},
	reducers: {
		setPricingValue: (state, action) => {
			const { unit, price } = action.payload;
			state.pricing[unit] = price;
			return state;
		},
	},
});

export const { setHomeArea, setIsOpen, setPricingValue } =
	conditionReportSlice.actions;
