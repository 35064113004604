import React from "react";

import { DataTable, DataTableColumn } from "@salesforce/design-system-react";
import { Cell } from "../TableValues/TableValues";
import { FilterPanelLayout } from "@/features/media-manager/components/layouts";
import { AssetListFilterPanelContainer } from "../Filters/Filters";
import classes from "./DesktopTableView.module.scss";
import {
	useAssetList,
	// usePageAssetList,
	// useSortAssetList,
} from "../../api/useLazyAssetList";
import { get } from "lodash";
import { columns } from "../columns/columns";
import { usePageAssetList } from "../../api/usePageAssetList";
import { useSortAssetList } from "../../api/useSortAssetList";
import { useFeature } from "@/providers/feature-flags/FeatureProvider";
import { PRICING_WORKSHEET_APPROVAL_STATUS } from "@/features/asset/constants";
import { PRICING_WORKSHEET_FEATURE_FLAG } from "@/providers/feature-flags/constants";

export const DesktopTableView = () => {
	const { data } = useAssetList();
	const { pagination, trigger: triggerPage, status } = usePageAssetList();
	const { direction, property, trigger: triggerSort } = useSortAssetList();
	const totalAssets = pagination?.totalItems;
	const isEnabledPricingWorksheet = useFeature(PRICING_WORKSHEET_FEATURE_FLAG);

	const pricingWorksheetFeatureFilter = (key) =>
		!(
			key === PRICING_WORKSHEET_APPROVAL_STATUS.key &&
			!isEnabledPricingWorksheet
		);

	const assetList = data?.map((item) => {
		return {
			id: item.id,
			accountId: item.accountId,
			...Object.fromEntries(
				Object.keys(columns)
					.filter(pricingWorksheetFeatureFilter)
					.map((key) => {
						const title = key;
						const value = columns[key].formatter
							? columns[key].formatter(get(item, columns[key].path))
							: get(item, columns[key].path);

						return [title, value];
					})
			),
		};
	});

	const handleSort = (sortColumn) => {
		const { property: sortProperty, sortDirection } = sortColumn;
		triggerSort({
			property: sortProperty,
			direction: sortDirection,
		});
	};

	const handleLoadMore = () => {
		if (status !== "pending") {
			const currentPage = pagination.pageNumber;
			triggerPage(currentPage + 1);
		}
	};

	const configCols = Object.keys(columns).filter(pricingWorksheetFeatureFilter);
	return (
		<div className={classes.container}>
			<FilterPanelLayout panel={<AssetListFilterPanelContainer />}>
				<div style={{}}>
					<DataTable
						assistiveText={{
							columnSort: "sort this column",
							columnSortedAscending: "asc",
							columnSortedDescending: "desc",
							selectAllRows: "Select all rows",
							selectRow: "Select this row",
						}}
						style={{ fontSize: ".8em", overflow: "auto", minWidth: "800px" }}
						items={assetList}
						id="DataTableExample-FixedHeaders"
						onSort={handleSort}
						fixedHeader
						fixedLayout
						columnBordered
						onLoadMore={() => handleLoadMore()}
						hasMore={data.length < totalAssets}
					>
						{configCols.map((column) => {
							return (
								<DataTableColumn
									key={column}
									width={
										columns[column]?.width ? columns[column].width : "50px"
									}
									label={
										columns[column]?.label ? columns[column].label : column
									}
									property={column}
									sortable={columns[column]?.sortable ?? true}
									isSorted={property === column}
									sortDirection={direction}
								>
									{columns[column]?.component ? (
										React.createElement(columns[column].component, {
											...{ anchor: column.id },
											...columns[column].props,
											title: column,
										})
									) : (
										<Cell
											title={columns[column]?.label}
											searchable={columns[column].searchable}
										/>
									)}
								</DataTableColumn>
							);
						})}
					</DataTable>
				</div>
			</FilterPanelLayout>
		</div>
	);
};
