import { useContext, useEffect, useMemo } from "react";
import { useFormFocus } from "./useFormFocus";
import { FormSectionContext } from "../providers/FormSectionProvider";

export const useFormSection = (section: string | null = null) => {
	const { sections, setSections, activateSection, isEditable, isFetching } =
		useContext(FormSectionContext);

	const { setFocusField } = useFormFocus();

	useEffect(() => {
		if (section && setSections) {
			setSections((sections) => {
				if (Object.keys(sections).includes(section)) return sections;
				return { ...sections, [section]: false };
			});
		}
	}, [section, setSections]);

	const isActive = useMemo(() => {
		if (section) {
			return sections?.[section];
		} else {
			return Object.values(sections)?.reduce((acc, c) => acc || c, false);
		}
	}, [section, sections]);

	const editField = (field: string) => {
		if (section) {
			activateSection(section);
		}
		setFocusField(field);
	};

	return {
		isStatic: !isActive,
		editField,
		isEditable,
		isFetching,
	};
};
