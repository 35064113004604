import {
	useSelectedMedia,
	useSelectedMediaActions,
} from "../api/hooks/useSelectedMedia";

export function useSelectionDropdownActions({
	selectionActions,
	bucket,
	assetId,
}) {
	const {
		selectAll,
		selectNone,
		selectedIds,
		allAreSelected,
		noneAreSelected,
	} = useSelectedMedia({
		bucket,
		assetId,
	});

	const {
		deleteSelected,
		removeSelectedFromWeb,
		openAddTagsModal,
		openRemoveTagsModal,
		openEmailSelected,
		addSelectedToWeb,
	} = useSelectedMediaActions({ bucket, assetId });

	const availableActions = {
		selectAll: {
			label: "Select All",
			value: selectAll,
			disabled: allAreSelected,
		},
		selectNone: {
			label: "Select None",
			value: selectNone,
			disabled: noneAreSelected,
		},
		deleteSelected: {
			label: "Delete Selected",
			value: deleteSelected,
			disabled: noneAreSelected,
		},
		addSelectedToWeb: {
			label: "Add Selected to Web",
			value: addSelectedToWeb,
			disabled: noneAreSelected,
		},
		removeSelectedFromWeb: {
			label: "Remove Selected from the Web",
			value: removeSelectedFromWeb,
			disabled: noneAreSelected,
		},
		addTagsToSelected: {
			label: "Add Tags to Selected",
			value: openAddTagsModal,
			disabled: noneAreSelected,
		},
		removeTagsFromSelected: {
			label: "Remove Tags from Selected",
			value: openRemoveTagsModal,
			disabled: noneAreSelected,
		},
		emailSelected: {
			label: `Email ${selectedIds.length} Selected Images`,
			value: openEmailSelected,
			disabled: noneAreSelected,
		},
	};

	return selectionActions
		?.map((action) => availableActions[action])
		.filter((action) => action);
}
