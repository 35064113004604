export const airConditioners = {
	"0109310": {
		system: "2 ton 14 SEER Package AC",
		price: 1582.3,
	},
	"0109311": {
		system: "2.5 ton 14 SEER Package AC",
		price: 1668.02,
	},

	"0109312": {
		system: "3 ton 14 SEER Package AC",
		price: 1816.97,
	},
	"0109313": {
		system: "3.5 ton 14 SEER Package AC",
		price: 1960.31,
	},
	"0109314": {
		system: "4 ton 14 SEER Package AC",
		price: 2116.29,
	},

	"0109315": {
		system: "5 ton 14 SEER  Package AC",
		price: 2310.22,
	},
};

export const getACPricing = () => {
	return Object.values(airConditioners).map((val) => val.price);
};
