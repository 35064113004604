import {
	ACREAGE,
	BATHROOMS,
	BEDROOMS,
	RETAIL_ASKING_PRICE,
	SQUARE_FEET,
	YEAR,
} from "@/features/asset/constants";
import { useGenerateDescriptionMutation } from "./endpoints";
import { get } from "lodash";
import { Asset } from "@/features/asset/types";

export const useGenerateDescription = () => {
	const [trigger, { isLoading, data, error }] =
		useGenerateDescriptionMutation();

	const getDescription = async (prompt: string) => {
		const { data } = await trigger(prompt);
		return data;
	};

	const prompt = (asset: Asset) => {
		const year = get(asset, YEAR.path);
		const bedrooms = get(asset, BEDROOMS.path);
		const bathrooms = get(asset, BATHROOMS.path);
		const acreage = get(asset, ACREAGE.path);
		const squareFeet = get(asset, SQUARE_FEET.path);
		const retailAskingPrice = get(asset, RETAIL_ASKING_PRICE.path);
		const prompt = `Given the structured meta data:
    ${year ? `- Year Built: ${year}` : "- Year built not listed"}
	${bedrooms ? `- Bedrooms: ${bedrooms}` : "- Bedrooms not listed"}
	${bathrooms ? `- Baths: ${bathrooms}` : "- Bathrooms not listed"}
	${acreage ? `- Acreage: ${acreage}` : "- Acreage not listed"}
	${
		squareFeet
			? `- Square Footage: ${squareFeet}`
			: "- Square footage not listed"
	}
	${
		retailAskingPrice
			? `- Price: ${retailAskingPrice}`
			: "- Retail asking price not listed"
	}
Handle the following:
	- Create a description as if you were a Real Estate agent describing a home.
    - Create a brief SEO-optimized summary of a manufactured home for sale.
	- Do not include the word SEO in the description.
    - The description should highlight the home's features from the metadata provided.
    - Please do not mention inclusion of land.
    - The summary should be direct, but engaging, creative, and fun with restraint.
    - Please do not include introductory or concluding remarks, "Here is a summary of a home for sale:"
    or "Of course! Here is a brief SEO-optimized summary of the manufactured home for sale:"
    - Please, do not use words "serenity", "serene oasis", "luxury", "ultimate", or "exquisite".
	- Replace words  "master", "master suite", and "master bedroom" with "primary", "primary suite", and "primary bedroom".
    - Please finish the summary with an SEO optimized sentence highlighting the amenities if there are amenities in the metadata.
    - The description should not be more than 5 sentences.
    - If year built or number of bathrooms or number of bedrooms or acreage or square footage, or retail price are not listed. Do not mention them.
	`;
		return prompt;
	};

	return { getDescription, data: data ?? "", prompt, isLoading, error };
};
