import { useGetPaymentAllocationPurposesQuery } from "@/features/lookups";
import {
	useCreateFundsMutation,
	useDeleteFundsByIdMutation,
	useGetPaymentsQuery,
	useUpdateFundsMutation,
	bookingUtilities,
} from "../api/endpoints";
import { useDispatch } from "react-redux";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { SourceOfFundsForm } from "../components/SourceOfFunds";

export const usePayments = (saleId: string) => {
	const {
		data: funds,
		isSuccess: isSuccessFunds,
		isLoading,
		isFetching,
	} = useGetPaymentsQuery(saleId, {
		skip: !saleId,
	});

	const dispatch = useDispatch();

	const [createFunds] = useCreateFundsMutation();
	const [updateFunds] = useUpdateFundsMutation();
	const [deleteFunds] = useDeleteFundsByIdMutation();

	const { data: allocationPurposes } = useGetPaymentAllocationPurposesQuery();
	const submission = async (data: SourceOfFundsForm) => {
		const { funds: fundsForm } = data;

		//Optimistically update the payment data;
		dispatch(
			bookingUtilities.updateQueryData("getPayments", saleId, () => {
				return fundsForm;
			})
		);

		// For each payment in the form array
		fundsForm.forEach(async (payment) => {
			// if id exists, update the information
			if (payment.id) {
				const update = await updateFunds(payment);
				if ("error" in update) {
					dispatch(
						openToast({
							id: "funds",
							labels: {
								heading: "Error",
								details: "Something went wrong updating funds",
							},
							timeout: 3000,
							variant: "error",
						})
					);
				}
			}
			// if no id, create the fund
			else {
				const create = await createFunds({
					...payment,
					paymentAllocations: payment.paymentAllocations.map((allocation) => {
						return {
							...allocation,
							saleId: saleId,
							paymentAllocationPurposeId: allocationPurposes
								? Object.keys(allocationPurposes).find(
										(key) => allocationPurposes[key] === "Misc"
								  ) ?? ""
								: "",
						};
					}),
				});
				if ("error" in create) {
					dispatch(
						openToast({
							id: "funds",
							labels: {
								heading: "Error",
								details: "Something went wrong creating funds",
							},
							timeout: 3000,
							variant: "error",
						})
					);
				}
			}
		});
		// Check for any deleted payments and delete them if found
		funds?.forEach(async (fund) => {
			const item = fundsForm.find((item) => item.id === fund.id);
			if (!item) {
				const deleteFundsOperation = await deleteFunds(fund.id);
				if ("error" in deleteFundsOperation) {
					dispatch(
						openToast({
							id: "funds",
							labels: {
								heading: "Error",
								details: "Something went deleting funds",
							},
							timeout: 3000,
							variant: "error",
						})
					);
				}
			}
		});
	};

	return {
		data: saleId ? funds : [],
		submit: submission,
		isLoading,
		isFetching,
		isSuccess: saleId ? isSuccessFunds : true,
	};
};
