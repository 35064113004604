import { Combobox } from "@salesforce/design-system-react";

export function BooleanSelect({ predicate, setPredicate }) {
	const options = [
		{
			id: `option-true-1`,
			label: "true",
			value: true,
		},
		{
			id: `option-false-2`,
			label: "false",
			value: false,
		},
	];

	const selection = options.filter((option) => option.value === predicate);

	return (
		<Combobox
			placeholder="Select"
			variant="readonly"
			value={predicate?.toString()}
			selection={selection}
			hasDeselect
			deselectOption={selection}
			options={options}
			menuPosition="relative"
			events={{
				onSelect: (e, { selection: [option] }) => {
					setPredicate(option?.value);
				},
			}}
		/>
	);
}
