import React from "react";
import "./App.scss";
import { Dashboard } from "./Containers/Layout";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from "./routes";

function App() {
	return (
		<div className="App">
			<Router>
				<Dashboard>
					<AppRoutes />
				</Dashboard>
			</Router>
		</div>
	);
}

export default App;
