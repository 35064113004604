import { useSelectAssetList } from "./useLazyAssetList";
import { useSetAssetListParams } from "./useAssetListParams";
import { getAllAssets } from "./endpoints";

export const useFilterAssetList = () => {
	const { originalArgs } = useSelectAssetList();

	const [trigger] = getAllAssets.useLazyQuerySubscription();

	const { setFiltered } = useSetAssetListParams();

	const triggerFilters = (filters) => {
		trigger({
			...originalArgs,
			name: "filtered",
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: filters,
		});
		setFiltered(true);
	};

	return { filters: originalArgs?.filteringCriteria, trigger: triggerFilters };
};
