import React, { useMemo } from "react";
import { Button } from "@salesforce/design-system-react";
import { useIsOnline } from "../../hooks/useIsOnline";
import {
	useAddOfflineAssetMutation,
	useGetOfflineAssetsQuery,
	useRemoveOfflineAssetMutation,
} from "../../api";
import styles from "./OfflineAssetButton.module.scss";
import { AiOutlineWarning } from "react-icons/ai";
import { useDeleteAllOfflineAssets } from "../OfflineAssetDelete/useDeleteAllOfflineAssets";
import { useConfirmationDialog } from "@/Components/ConfirmationDialog/ConfirmationDialog";
import { usePendingSyncItems } from "../../api/hooks/usePendingSyncItems";

export const OfflineAssetButton = ({
	accountId,
	assetId,
	variant,
	...props
}) => {
	const {
		data: isAvailableOffline,
		isWarning,
		warnings,
	} = useGetOfflineAssetsQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			const ids = data?.ids ?? [];
			const isWarning =
				data?.entities[assetId]?.assetMedia?.isError ||
				data?.entities[assetId]?.conditionReports?.isError;

			const warnings = [
				data?.entities[assetId]?.assetMedia?.isError && "media",
				data?.entities[assetId]?.conditionReports?.isError &&
					"condition reports",
			]
				.filter((x) => x)
				.join(", ");

			return { data: ids.includes(assetId), isWarning, warnings, ...rest };
		},
	});

	const { isLoading: isRemovingAssets } = useDeleteAllOfflineAssets();

	const isOnline = useIsOnline();

	const confirmationDialog = useConfirmationDialog();

	const [addOfflineAssetFromEndpoint, { isLoading: isAdding }] =
		useAddOfflineAssetMutation(assetId);

	const [removeOfflineAssetFromEndpoint, { isLoading: isRemoving }] =
		useRemoveOfflineAssetMutation({ accountId, assetId });

	const isAddingOrRemoving = isAdding || isRemoving || isRemovingAssets;

	const toggleOffline = () => {
		if (isAddingOrRemoving) return;
		if (!isAvailableOffline) {
			addOfflineAssetFromEndpoint(assetId);
		} else {
			removeOfflineAssetFromEndpoint(assetId);
		}
	};

	const syncItems = usePendingSyncItems(assetId);
	const isPendingSync = useMemo(
		() => Object.values(syncItems).reduce((acc, c) => c || acc, false),
		[syncItems]
	);

	const icon = {
		...(isAvailableOffline && !isAddingOrRemoving && !isPendingSync
			? {
					iconName: "check",
					iconCategory: "utility",
					...(variant === "card" && { iconPosition: "left" }),
			  }
			: {
					...(isOnline &&
						!isAddingOrRemoving && {
							iconName: isPendingSync ? "threedots" : "download",
							iconCategory: "utility",
							...(variant === "card" && { iconPosition: "left" }),
						}),
					...(isAddingOrRemoving && {
						iconName: "spinner",
						iconCategory: "utility",
						...(variant === "card" && { iconPosition: "left" }),
						iconClassName: styles.spinner,
					}),
			  }),
	};

	const classes = [
		variant === "card" ? "mobile-table-offline" : styles.mobile,
		isAvailableOffline && !isOnline && "mobile-table-offline_available",
	]
		.filter((css) => css)
		.join(" ");
	if (variant === "header") {
		return (
			<Button
				variant={"icon"}
				onClick={toggleOffline}
				disabled={!isOnline}
				{...icon}
				{...props}
				iconVariant={isOnline ? "border-filled" : "border"}
				iconClassName={!isOnline ? styles.offline : ""}
			></Button>
		);
	}

	return (
		<div className={classes}>
			{isOnline && (
				<Button
					variant={isOnline ? "outline-brand" : "icon"}
					onClick={(event) => {
						event.stopPropagation();
						if (isAvailableOffline) {
							confirmationDialog.show({
								title: "Confirm Remove",
								message:
									"Are you sure you want to remove this asset from field use?",
								confirmButtonText: "Remove",
								action: () => toggleOffline(),
							});
						} else {
							toggleOffline();
						}
					}}
					disabled={!isOnline || isRemovingAssets || isPendingSync}
					{...icon}
					{...props}
				>
					{isAvailableOffline &&
						!isAddingOrRemoving &&
						!isPendingSync &&
						"Available for Field Use"}
					{!isAvailableOffline &&
						!isAddingOrRemoving &&
						isOnline &&
						!isPendingSync &&
						"Save for Field Use"}
					{isAdding && !isRemovingAssets && !isPendingSync && "Adding Asset..."}
					{isRemoving &&
						!isRemovingAssets &&
						!isPendingSync &&
						"Removing Asset..."}
					{isRemovingAssets &&
						!isPendingSync &&
						"Removing All Field Use Assets"}
					{isPendingSync && "Pending Items"}
				</Button>
			)}
			{isWarning && (
				<span
					style={{ color: "black", textAlign: "center", margin: 0, padding: 0 }}
				>
					<AiOutlineWarning /> Some items not saved ({warnings})
				</span>
			)}
		</div>
	);
};
