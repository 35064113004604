import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { Button, Tooltip } from "@salesforce/design-system-react";
import { useRef } from "react";
import { useInspectionUpdate } from "../../InspectionUpdate/useInspectionUpdate";
import { usePresignedUpload } from "../../../api/hooks/usePresignedUpload";
import FileResizer from "react-image-file-resizer";
import { nanoid } from "@reduxjs/toolkit";
import { isVideo } from "@/Utilities/utils";

const resizeFile = (file) =>
	new Promise((resolve) => {
		FileResizer.imageFileResizer(
			file,
			500,
			500,
			"JPEG",
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			"blob"
		);
	});

export const FileUploadButton = () => {
	const fileInputRef = useRef();
	const { assetId } = useAssetId();

	const { uploadFilesPresigned } = usePresignedUpload(assetId);
	const { shouldAskForUpdate, openInspectionModal } =
		useInspectionUpdate(assetId);

	const handleClick = async (e) => {
		const files = e.target.files;
		const resized = await Promise.all(
			[...files].map(async (file) => {
				// if file is video, don't resize
				if (isVideo(file)) {
					return file;
				}
				const resized = await resizeFile(file);
				return new File([resized], `${nanoid()}.jpg`, { type: file.type });
			})
		);
		uploadFilesPresigned(resized);

		const max = Array.from(files).reduce((a, b) => {
			if (b) {
				return Math.max(a, b.lastModified);
			}
			return a;
		}, 0);
		if (shouldAskForUpdate(max)) {
			openInspectionModal(max);
		}
		e.target.value = null;
	};
	const handleFileUpload = () => {
		fileInputRef.current.click();
	};

	return (
		<>
			<Tooltip content="Upload an image or video" align="bottom">
				<Button
					htmlFor="file-upload"
					assistiveText={{ icon: "Add media" }}
					iconCategory="utility"
					iconName="add"
					variant="icon"
					iconVariant="border-filled"
					onClick={() => handleFileUpload()}
				/>
			</Tooltip>
			<input
				style={{ display: "none" }}
				type="file"
				id="file-upload-hidden"
				accept={"image/*,video/*"}
				multiple={true}
				ref={fileInputRef}
				onChange={handleClick}
			/>
		</>
	);
};
