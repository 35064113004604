import React, { useState } from "react";
import { useRef } from "react";
import { useUploadMedia } from "@/features/media-manager/api/hooks/useUploadMedia";
import { IoIosFlashOff as FlashIcon } from "react-icons/io";
import classes from "./Camera.module.scss";
import { ShutterButton } from "./ShutterButton";
import { nanoid } from "@reduxjs/toolkit";
import { useMediaStream } from "./useMediaStream";
import Resizer from "react-image-file-resizer";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal } from "@salesforce/design-system-react";

const resizeFile = (file) =>
	new Promise((resolve) => {
		Resizer.imageFileResizer(
			file,
			640,
			640,
			"JPEG",
			100,
			0,
			(uri) => {
				resolve(uri);
			},
			"blob"
		);
	});

export const Camera = NiceModal.create(({ assetId, onRequestClose }) => {
	const modal = useModal();

	const [pictureTaken, setPictureTaken] = useState(false);
	const videoRef = useRef(null);
	const uploadRef = useRef(null);

	const { uploadFiles } = useUploadMedia(assetId);

	const stream = useMediaStream();
	const [tookPhotos, setTookPhotos] = useState(false);

	if (stream && videoRef.current && !videoRef.current.srcObject) {
		videoRef.current.srcObject = stream;
		videoRef.current.play();
	}

	const takePicture = () => {
		videoRef.current.pause();
		setPictureTaken(true);
	};

	const handleNativeUpload = async (e) => {
		try {
			const file = e.target.files[0];
			const blob = await resizeFile(file);

			const newFile = new File([blob], `${nanoid()}.jpg`, { type: file.type });
			uploadFiles([newFile]);

			setTookPhotos(true);
		} catch (err) {
			console.log(err);
		}
	};

	const handleUpload = async () => {
		const photo = new OffscreenCanvas(
			videoRef.current.videoWidth,
			videoRef.current.videoHeight
		);

		const ctx = photo.getContext("2d");
		ctx.drawImage(
			videoRef.current,
			0,
			0,
			videoRef.current.videoWidth,
			videoRef.current.videoHeight
		);

		const jpegBlob = await photo.convertToBlob({ type: "image/jpeg" });

		const jpegFile = new File([jpegBlob], `${nanoid()}.jpg`, {
			type: "image/jpeg",
		});
		uploadFiles([jpegFile]);

		setTookPhotos(true);
		retake();
	};

	const onClose = () => {
		onRequestClose();
		modal.resolveHide(tookPhotos);
	};

	const retake = () => {
		videoRef.current.play();
		setPictureTaken(false);
	};

	const handleClickFlashButton = (e) => {
		uploadRef.current.click();
	};

	return (
		<Modal
			isOpen={modal.visible}
			containerClassName={classes.container}
			contentClassName={classes.pictureToolContainer}
			onRequestClose={onClose}
		>
			<button className={classes.flash} onClick={handleClickFlashButton}>
				<FlashIcon />
			</button>
			<input
				type="file"
				capture="environment"
				ref={uploadRef}
				accept="image/*"
				onChange={handleNativeUpload}
				onClick={(e) => {
					videoRef.current.pause();
					window.addEventListener(
						"focus",
						() => {
							videoRef.current.play();
						},
						{ once: true }
					);
				}}
				hidden
			/>
			<video
				playsInline={true}
				muted={true}
				className={classes.videoContainer}
				ref={videoRef}
			/>

			{!pictureTaken && <ShutterButton takePicture={takePicture} />}

			{pictureTaken && (
				<div className={classes.submitButtons}>
					<button className={classes.discard} onClick={() => retake()}>
						Retake
					</button>
					<button className={classes.keep} onClick={handleUpload}>
						Use Photo
					</button>
				</div>
			)}
		</Modal>
	);
});

NiceModal.register("camera-modal", Camera);

export const useCameraModal = () => {
	return useModal("camera-modal");
};
