import { useState } from "react";
import {
	useGetCurrentUserQuery,
	useUpdateUserMutation,
} from "../api/endpoints";
import { useCallback } from "react";

export const useFavorites = () => {
	const { data: favorites, isSuccess } = useGetCurrentUserQuery(undefined, {
		selectFromResult: ({ data, ...rest }) => {
			return {
				data: data?.favoriteAssets ?? [],
				...rest,
			};
		},
	});

	const [trigger, { isLoading }] = useUpdateUserMutation({
		fixedCacheKey: "update-favorites",
	});

	const [current, setCurrent] = useState(null);

	const toggleFavorite = async (id) => {
		setCurrent(id);
		const set = new Set(favorites);

		if (set.has(id)) {
			set.delete(id);
		} else {
			set.add(id);
		}

		const update = trigger({ favoriteAssets: Array.from(set) });
		await update.unwrap();
		setCurrent(null);
	};

	const updateUser = useCallback(toggleFavorite, [favorites, trigger]);

	return {
		favorites,
		toggleFavorite: updateUser,
		isSuccess,
		isLoading,
		current,
	};
};
