import React, { useState, useEffect } from "react";
import {
	ExpandableSection,
	LocationMap,
} from "@salesforce/design-system-react";
import classes from "./MapView.module.scss";
import { AiOutlineWarning } from "react-icons/ai";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";

export const MapView = ({
	test = false,
	isOpenDefault = false,
	id,
	...props
}) => {
	const { data: asset } = useGetAssetQuery(id);
	const account = asset?.account;
	const [isOpen, setIsOpen] = useState(isOpenDefault);
	const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

	const [userLocation, setUserLocation] = useState({
		latitude: 0,
		longitude: 0,
	});

	const location = asset?.physicalLocation;
	const [error, setError] = useState({
		error: false,
		errorMessage: "",
	});
	useEffect(() => {
		if (!test) {
			let mounted = true;
			navigator.geolocation.getCurrentPosition(
				//Success Function
				(location) => {
					if (mounted) {
						const latitude = location?.coords?.latitude;
						const longitude = location?.coords?.longitude;
						setUserLocation({ latitude, longitude });
					}
				},
				//Failure Function
				(err) => {
					if (mounted) {
						setError({
							...error,
							error: true,
							errorMessage:
								"Allow HomeBase to access your location to enable maps",
						});
					}
				}
			);
			return () => {
				mounted = false;
			};
		}

		//eslint-disable-next-line
	}, []);

	const locations = [
		{
			id: "1",
			name: "Asset Street Address",
			address:
				location?.address + ", " + location?.city + ", " + location?.state,
		},
		{
			id: "2",
			name: "Asset Latitude & Longitude",
			address: location?.latitude + "," + location?.longitude,
		},
		{
			id: "3",
			name: "Your Latitude & Longitude",
			address: userLocation.latitude + "," + userLocation.longitude,
		},
	];
	const [selected, setSelected] = useState(undefined);
	return (
		<ExpandableSection
			title="Map View"
			isOpen={isOpen}
			onToggleOpen={() => setIsOpen(!isOpen)}
		>
			{error.error ? (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<AiOutlineWarning style={{ width: "20px", height: "20px" }} />
					<p style={{ padding: ".5rem", textAlign: "center" }}>
						Homebase does not have access to your location. Please enable if
						you'd like to see current location on the map.
					</p>
				</div>
			) : null}
			<LocationMap
				className={classes.locationMap}
				classNameContainer={classes.locationMapContainer}
				defaultLocation={locations?.[0]}
				id="location-map-multiple-locations-example"
				data-testid="location-Map-test"
				onClickLocation={(event, data) => {
					setSelected(data);
				}}
				googleAPIKey={apiKey}
				labels={{
					title:
						"Account #" + account?.accountNumber?.toString() + " locations",
				}}
				locations={locations}
				selection={selected}
			/>
		</ExpandableSection>
	);
};
