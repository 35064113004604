import { createEntityAdapter } from "@reduxjs/toolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { offlineAssetDB } from "@/storage";

export const offlineAssetAdapter = createEntityAdapter({});

export const getOfflineAssetsEndpoint = {
	queryFn: async (arg, api, extraOptions, baseQuery) => {
		const assets = await Promise.all(
			(
				await offlineAssetDB.keys()
			).map(async (key) => {
				const asset = await offlineAssetDB.getItem(key);
				return asset;
			})
		);

		return {
			data: offlineAssetAdapter.setAll(
				offlineAssetAdapter.getInitialState(),
				assets ?? offlineAssetAdapter.getInitialState() 
			),
		};
	},
	providesTags: (result, error, id) => {
		if (error)
			return concatErrorCache([{ type: "OfflineAsset", id: "LIST" }], error);

		return [
			"OfflineAsset",
			...result.ids.map((id) => ({ type: "OfflineAsset", id })),
			{ type: "OfflineAsset", id: "LIST" },
		];
	},
};
