import React, { useState } from "react";

import {
	AppLauncher,
	AppLauncherLink,
	AppLauncherTile,
	AppLauncherExpandableSection,
	GlobalNavigationBar,
	GlobalNavigationBarLink,
	GlobalNavigationBarRegion,
	Button,
	Search,
} from "@salesforce/design-system-react";
import "../../App.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAccessTripPlanning } from "@/providers/roles/AccessHooks";

export const AppLauncherHeader = () => {
	const [state, setState] = useState({ search: "" });
	const navigate = useNavigate();
	const onSearch = (event) => {
		setState({ search: event.target.value });
	};

	const search = (
		<Search
			onChange={(event) => {
				onSearch(event);
			}}
			placeholder="Find an app"
			assistiveText={{ label: "Find an app" }}
		/>
	);

	const headerButton = <Button disabled label="HomeBase Apps" />;

	const location = useLocation();
	const { canRead: hasAccessTripPlanning } = useAccessTripPlanning();

	return (
		<GlobalNavigationBar>
			<GlobalNavigationBarRegion region="primary">
				<AppLauncher
					triggerName="HomeBase"
					search={search}
					modalHeaderButton={headerButton}
				>
					<AppLauncherExpandableSection title="Tile Section">
						<AppLauncherTile
							description="View all assets in the VMF Homes Remarketing Portfolio"
							iconText="AM"
							search={state.search}
							title="Asset List"
							onClick={() => navigate("/")}
						/>
						{hasAccessTripPlanning && (
							<AppLauncherTile
								description="View Trip Planning"
								iconText="T"
								search={state.search}
								title="Trip Planning"
								onClick={() => navigate("/trip-planning")}
							/>
						)}
						<AppLauncherTile
							description="View Insurance Cancellation Queue"
							iconText="IQ"
							search={state.search}
							title="Insurance Cancellation Queue"
							onClick={() => navigate("/insuranceQueue")}
						/>
					</AppLauncherExpandableSection>

					<hr />
					<AppLauncherExpandableSection title="All Items">
						<AppLauncherLink
							onClick={() => navigate("/")}
							search={state.search}
						>
							Asset List
						</AppLauncherLink>
						{hasAccessTripPlanning && (
							<AppLauncherLink
								onClick={() => navigate("/trip-planning")}
								search={state.search}
							>
								Trip Planning
							</AppLauncherLink>
						)}
						<AppLauncherLink
							onClick={() => navigate("/insuranceQueue")}
							search={state.search}
						>
							Insurance Cancellation Queue
						</AppLauncherLink>
					</AppLauncherExpandableSection>
				</AppLauncher>
			</GlobalNavigationBarRegion>
			<GlobalNavigationBarRegion
				navigation
				style={{ overflow: "hidden" }}
				region="secondary"
			>
				<GlobalNavigationBarLink
					active={location?.pathname === "/"}
					onClick={() => navigate("/")}
					label="Assets"
				/>
				{hasAccessTripPlanning && (
					<GlobalNavigationBarLink
						active={location?.pathname === "/trip-planning"}
						onClick={() => navigate("/trip-planning")}
						label="Trips"
					/>
				)}
			</GlobalNavigationBarRegion>
		</GlobalNavigationBar>
	);
};
AppLauncherHeader.displayName = "HomeBase";
