import React from "react";
import {
	PageHeader,
	Icon,
	Dropdown,
	PageHeaderControl,
	Button,
	Input,
	InputIcon,
	Tooltip,
} from "@salesforce/design-system-react";
import classes from "./DocumentationHeader.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSearch, setSource } from "@/ReduxToolkit/DocumentationSlice";
import { useGetNoteSourcesQuery } from "../../api/endpoints";
import { selectFilters, selectSearch, selectSource } from "../../api/selectors";
import { useTabs } from "@/hooks/useTabs";
import { useIsMobile } from "@/Responsive";
import { useFilterPanel } from "@/features/media-manager/hooks/useFilterPanel";

export const DocumentationHeader = ({
	onAddNote,
	accountId,
	close,
	info,
}: {
	onAddNote: Function;
	accountId: string;
	close: Function;
	info: string;
}) => {
	const dispatch = useDispatch();

	const { data } = useGetNoteSourcesQuery(undefined);
	const search = useSelector(selectSearch(accountId));
	const selectedSources = useSelector(selectSource(accountId));

	const sourceOptions =
		data?.map((item) => {
			return { label: item.description, value: item.id };
		}) ?? [];

	const nameSwitcherOptions = [...sourceOptions];

	const { isOpenFilterPanel, setIsOpenFilterPanel } = useFilterPanel();

	const isMobile = useIsMobile();
	const { currentTab, setCurrentTab } = useTabs();
	const sideView = currentTab !== "notes" && !isMobile;

	const title =
		selectedSources.length < 2
			? sourceOptions.find((item) => selectedSources.includes(item.value))
					?.label ?? "All Items"
			: `${selectedSources.length} Selected`;

	const controls = () => (
		<PageHeaderControl>
			<Input
				value={search}
				name="search-documentation"
				iconLeft={
					<InputIcon
						assistiveText={{
							icon: "Search",
						}}
						name="search"
						category="utility"
					/>
				}
				styleInput={{ width: "100%" }}
				placeholder="Search this list"
				//@ts-ignore
				onChange={(e, { value }) =>
					dispatch(setSearch({ id: accountId, search: value }))
				}
			/>
		</PageHeaderControl>
	);

	const filters = useSelector(selectFilters(accountId));

	const actions = () => (
		<PageHeaderControl>
			<Tooltip content="Add Note">
				<Button
					variant="icon"
					iconCategory="utility"
					iconVariant="border-filled"
					iconName="add"
					title="add-documentation"
					name="add-documentation"
					onClick={() => {
						onAddNote();
					}}
				/>
			</Tooltip>
			{!isMobile && (
				<Tooltip content={sideView ? "Expand" : "Contract"}>
					<Button
						variant="icon"
						iconCategory="utility"
						iconName={sideView ? "expand" : "contract"}
						iconVariant="border-filled"
						onClick={() => {
							sideView ? setCurrentTab("notes") : setCurrentTab("details");
						}}
					/>
				</Tooltip>
			)}
			{!isMobile && sideView && (
				<Tooltip content="Close Side Module">
					<Button
						variant="icon"
						iconCategory="utility"
						iconName="close"
						iconVariant="border-filled"
						onClick={() => close()}
					/>
				</Tooltip>
			)}
			{!sideView && (
				<Tooltip content="Filter Panel">
					<Button
						variant="icon"
						iconCategory="utility"
						iconName="filterList"
						iconVariant="border-filled"
						onClick={() => setIsOpenFilterPanel(!isOpenFilterPanel)}
					/>
				</Tooltip>
			)}
		</PageHeaderControl>
	);

	return (
		<PageHeader
			className={classes.DocumentationHeader}
			icon={
				<Icon
					assistiveText={{ label: "Home" }}
					category="standard"
					name="note"
				/>
			}
			onRenderControls={controls}
			onRenderActions={actions}
			info={
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						fontSize: ".75rem",
					}}
				>
					{info}
					{filters.length > 0 && (
						<Tooltip
							variant="learnMore"
							content={`${filters.length} filter${
								filters.length > 1 ? "s" : ""
							} active`}
						/>
					)}
				</div>
			}
			label="Notes"
			nameSwitcherDropdown={
				<Dropdown
					width="x-small"
					align="right"
					assistiveText={{ icon: "Name Switcher" }}
					buttonClassName="slds-button_icon-small"
					buttonVariant="icon"
					iconCategory="utility"
					iconName="down"
					id="page-header-name-switcher-dropdown"
					checkmark
					value={selectedSources}
					//@ts-ignore
					onSelect={(value) => {
						dispatch(setSource({ id: accountId, sourceId: value.value }));
					}}
					multiple={true}
					options={nameSwitcherOptions}
				/>
			}
			title={title}
			variant="object-home"
		/>
	);
};
