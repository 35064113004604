import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getImagingByAccountNumber } from "./endpoints";

const initialState = {
	progress: {},
	docTypes: []
};

export const imagingSlice = createSlice({
	name: "imaging",
	initialState: {},
	reducers: {
		addImagingState: (state, action) => {
			state[action.payload] = initialState;
			return state;
		},
		removeImagingState: (state, action) => {
			delete state[action.payload];
		},
		setProgress: (state, action) => {
			const { documentId, accountNumber, progress } = action.payload;
			state[accountNumber].progress = {
				...state[accountNumber].progress,
				[documentId]: progress
			};
			return state;
		},
		removeProgress: (state, action) => {
			const { documentId, accountNumber } = action.payload;
			delete state[accountNumber].progress[documentId];
			return state;
		},
		setDocTypes: (state, action) => {
			const { docType, accountNumber } = action.payload;
			if (state[accountNumber].docTypes.includes(docType)) {
				state[accountNumber].docTypes = state[accountNumber].docTypes.filter(
					(item) => item !== docType
				);
			} else {
				state[accountNumber].docTypes = [
					...state[accountNumber].docTypes,
					docType
				];
			}
		}
	}
});

export const {
	setProgress,
	addImagingState,
	removeImagingState,
	removeProgress,
	setDocTypes
} = imagingSlice.actions;

const selectImagingByAccountNumber = (accountNumber) => (state) =>
	state.imaging[accountNumber];

// Function to select result from the query
const selectImagingResult = (accountNumber) =>
	getImagingByAccountNumber.select(accountNumber);

const selectImagingFromResult = (accountId) =>
	createSelector(
		selectImagingResult(accountId),
		(result) => result?.data ?? []
	);

export const selectImagingProgress = (state) => state.imaging?.progress;

export const selectImagingDownloadProgress = (accountNumber, id) =>
	createSelector(
		selectImagingByAccountNumber(accountNumber),
		(result) => result?.progress[id]
	);

export const selectImagingDocTypes = (accountNumber) =>
	createSelector(
		selectImagingByAccountNumber(accountNumber),
		(state) => state?.docTypes ?? []
	);

export const selectAvailableDoctypes = (accountNumber) =>
	createSelector(selectImagingFromResult(accountNumber), (documents) =>
		documents
			?.map((doc) => doc.documentType)
			.filter((value, index, array) => array.indexOf(value) === index)
	);

export const selectFilteredImagingDocuments = (accountNumber) =>
	createSelector(
		selectImagingFromResult(accountNumber),
		selectImagingDocTypes(accountNumber),
		(data, docTypes) =>
			data.filter((item) => {
				if (docTypes.length === 0) {
					return true;
				}
				return docTypes.includes(item.documentType);
			})
	);
