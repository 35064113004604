import { useDispatch } from "react-redux";
import {
	usePresignedUploadMutation
} from "../endpoints";
import {openToast} from '../../../../ReduxToolkit/ToastsSlice';

export const usePresignedUpload = (assetId: string) => {
	const [trigger] = usePresignedUploadMutation();
	const dispatch = useDispatch();
	const uploadFile = async (file: File) => {
		const {data, error} = await trigger({file, assetId});
		if (error) {
			dispatch(openToast({
				id: `${assetId}-${file.name}`,
				labels: {
					details: `Something went wrong uploading ${file.name}, please try again later`,
					heading: "Error"
				},
				timeout: 5000,
				variant: 'error',
			}))
		}
		return data;
	};
	const uploadFilesPresigned = async (files: FileList) => {
		const results = Array.from(files).map((item) => {
			return uploadFile(item);
		});
		return results;
	};
	return { uploadFilesPresigned };
};
