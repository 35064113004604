import { useIsOnline } from "@/features/offline-use";
import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const { useUploadMediaMutation } = require("../endpoints");

export const useUploadMedia = (assetId) => {
	const isOnline = useIsOnline();
	const dispatch = useDispatch();

	const [trigger, result] = useUploadMediaMutation({
		fixedCacheKey: "uploading-media",
	});

	const uploadFiles = (files) => {
		trigger({ assetId, files });
	};

	useEffect(() => {
		if (result?.error && !isOnline) {
			result.reset();
		}
		if (result?.error && isOnline) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong uploading media`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	}, [dispatch, isOnline, result, result?.error, result.requestId]);

	return { uploadFiles, ...result };
};
