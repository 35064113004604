import React, { useState } from "react";
import { ImagingHeader } from "./imaging-header/ImagingHeader";
import { ImagingFile } from "./imaging-file/ImagingFile";
import classes from "./Imaging.module.scss";
import { useTabs } from "@/hooks/useTabs";
import { useIsMobile } from "@/Responsive";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { useImaging } from "./api/useImaging";
import useVirtual from "react-cool-virtual";
import { ImagingCard } from "./imaging-card/ImagingCard";

export const Imaging = ({ accountNumber, close = () => {} }) => {
	const { data, isSuccess, total } = useImaging(accountNumber);

	const { currentTab } = useTabs();
	// Is mobile here to check for initial state of cards per row
	const isMobile = useIsMobile();
	const [cardsPerRow, setCardsPerRow] = useState(
		isMobile ? "TwoPerRow" : "FourPerRow"
	);

	const sideView = currentTab !== "imaging" && !isMobile;

	const documents = sideView ? (
		<VirtualList accountNumber={accountNumber} documents={data} />
	) : (
		<div className={classes[cardsPerRow]}>
			{data?.map((item) => (
				<div key={item.documentId}>
					<ImagingFile document={item} accountNumber={accountNumber} />
				</div>
			))}
		</div>
	);

	return (
		<div className={classes.container}>
			<ImagingHeader
				close={close}
				accountNumber={accountNumber}
				info={total ? `Showing ${data.length} / ${total}` : ""}
				cardsPerRow={cardsPerRow}
				setCardsPerRow={(value) => setCardsPerRow(value)}
			/>
			{isSuccess ? (
				documents
			) : (
				<div className={classes.spinnerContainer}>
					<Spinner />
				</div>
			)}
		</div>
	);
};

const VirtualList = ({ documents, accountNumber }) => {
	const { outerRef, innerRef, items } = useVirtual({
		itemCount: documents.length,
		itemSize: 50
	});

	return (
		<div className={classes.virtualContainer} ref={outerRef}>
			<div ref={innerRef}>
				{items.map(({ index, measureRef }) => (
					<div key={`imaging-card-${index}`} ref={measureRef}>
						<ImagingCard
							accountNumber={accountNumber}
							document={documents[index]}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
