import { useSearchParams } from "react-router-dom";

export const useSplitViewAsset = () => {
	const [params, setParams] = useSearchParams();

	const setAssetId = (newAssetId) => {
		const { assetId, ...rest } = Object.fromEntries(params.entries());
		setParams({ ...rest, assetId: newAssetId }, { replace: true });
	};

	return { assetId: params.get("assetId"), setAssetId };
};
