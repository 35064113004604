import {
	ControlledInput as Input,
	ControlledDateInput as DateInput,
} from "@/Components/Form";
import classes from "@/Components/ControlledForm.module.scss";
import { useFormContext } from "react-hook-form";
import { FormSection } from "@/Components/Form/ControlledForm/FormSection";
import {
	APPRAISED_VALUED,
	FHA_APPRAISAL_RECEIVED,
	FHA_APPRAISAL_REQUEST,
	NOTICE_OF_SALE_EXPIRES,
	NOTICE_OF_SALE_REQUIRED,
	NOTICE_OF_SALE_SENT,
	TAX_PAID_THROUGH,
	VSI_CLAIM_DATE,
	VSI_REFUND_AMOUNT,
} from "@/features/asset/constants";

export const OperationsInfo = ({ assetId, ...props }) => {
	return (
		<FormSection title="Operations Info" {...props}>
			<Fields />
		</FormSection>
	);
};

function Fields() {
	const { isLoaded } = useFormContext();
	return (
		isLoaded && (
			<div className={classes.formGrid}>
				<DateInput
					name={FHA_APPRAISAL_REQUEST.path}
					label={FHA_APPRAISAL_REQUEST.label}
					type="date"
				/>
				<DateInput
					name={FHA_APPRAISAL_RECEIVED.path}
					label={FHA_APPRAISAL_RECEIVED.label}
					type="date"
				/>
				<Input
					name={APPRAISED_VALUED.path}
					label={APPRAISED_VALUED.label}
					type="number"
				/>
				<DateInput
					name={TAX_PAID_THROUGH.path}
					label={TAX_PAID_THROUGH.label}
					type="date"
				/>
				<Input name={VSI_REFUND_AMOUNT.path} label={VSI_REFUND_AMOUNT.label} />
				<DateInput
					name={VSI_CLAIM_DATE.path}
					label={VSI_CLAIM_DATE.label}
					type="date"
				/>
				<DateInput
					name={NOTICE_OF_SALE_REQUIRED.path}
					label={NOTICE_OF_SALE_REQUIRED.label}
					type="date"
				/>
				<DateInput
					name={NOTICE_OF_SALE_SENT.path}
					label={NOTICE_OF_SALE_SENT.label}
					type="date"
				/>
				<DateInput
					name={NOTICE_OF_SALE_EXPIRES.path}
					label={NOTICE_OF_SALE_EXPIRES.label}
					type="date"
				/>
			</div>
		)
	);
}
