import React, { useEffect, useRef } from "react";
import { Checkbox, SLDSInput } from "@salesforce/design-system-react";
import { StaticInput } from "../StaticInput";
import { useController, useFormContext } from "react-hook-form";
import { ControlledConcurrency } from "../ControlledConcurrency/ControlledConcurrency";
import { useFormSection } from "../ControlledForm/FormSection";
const CheckboxInput = ({ ...props }) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({ name: props.name });
	const { focusField } = useFormContext();
	const { isStatic, isReadOnly, onClickEdit } = useFormSection();
	const focusRef = useRef(null);
	useEffect(() => {
		if (focusRef && focusField === props.name) {
			focusRef.current?.input?.focus();
		}
	}, [focusField, props.name]);
	const staticDisplay = rest.value ? "Yes" : "No";
	if (!isStatic && props.readOnly) {
		return (
			<SLDSInput
				name={props.name}
				key={`field-${props.name}`}
				label={props.label || props.labels.label}
				value={staticDisplay}
				readOnly={true}
			/>
		);
	}
	return isStatic || isReadOnly ? (
		<StaticInput
			name={props.name}
			key={`field-${props.name}`}
			label={props.label || props.labels.label}
			value={rest.value}
			readOnly={isReadOnly || props.readOnly}
			onClickEdit={() => onClickEdit(props.name)}
		/>
	) : (
		<div
			className="slds-form-element"
			style={{ gridColumn: `span ${props.span ?? 2}` }}
		>
			<label
				htmlFor={`checkbox-${props.name}`}
				className="slds-form-element__label"
			>
				{props.label}
			</label>
			<Checkbox
				id={`checkbox-${props.name}`}
				errorText={error?.message}
				ref={(inputRef) => {
					ref(inputRef);
					focusRef.current = inputRef;
				}}
				checked={rest.value}
				{...rest}
			/>
		</div>
	);
};

export const ControlledCheckbox = ({ ...props }) => {
	const {
		field: { ref, ...rest },
	} = useController({ name: props.name });
	const { isConcurrencyError, concurrencyDiff } = useFormContext();

	const staticDisplay = rest.value ? "Yes" : "No";
	const concurrencyOptions = [
		{ label: "Yes", value: true },
		{ label: "No", value: false },
	];

	return !isConcurrencyError ? (
		<CheckboxInput {...props} />
	) : (
		<span style={{ gridColumn: `span ${props.span ?? 2}` }}>
			{concurrencyDiff[props.name] && (
				<ControlledConcurrency
					{...props}
					type="checkbox"
					options={concurrencyOptions}
				/>
			)}
			{!concurrencyDiff[props.name] && (
				<SLDSInput
					name={props.name}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					value={staticDisplay}
					readOnly={true}
				/>
			)}
		</span>
	);
};
