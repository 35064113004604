import { ControlledInput } from "@/Components/Form";
import {
	HvacTypeSelect,
	HvacStatusSelect,
	ThermalZoneSelect,
	UtilitySystemSelect,
	WasteSystemSelect,
	WaterSystemSelect,
	WindZoneSelect,
	RoofLoadSelect,
} from "@/features/enums/EnumSelect";
import {
	FoundationTypesSelect,
	RoofTypesSelect,
	SidingTypesSelect,
} from "@/features/lookups/components";

import {
	BATHROOMS,
	BEDROOMS,
	FOUNDATION_TYPE_ID,
	HUD_NUMBER_1,
	HUD_NUMBER_2,
	HVAC_STATUS,
	HVAC_TYPE,
	LENGTH,
	MANUFACTURER,
	MODEL_NAME,
	ROOF_LOAD,
	ROOF_TYPE_ID,
	SECTIONS,
	SERIAL_NUMBER,
	SIDING_TYPE_ID,
	STOCK_NUMBER,
	THERMAL_ZONE,
	UTILITY_SYSTEM,
	WASTE_SYSTEM,
	WATER_SYSTEM,
	WIDTH,
	WIND_ZONE,
	YEAR,
} from "@/features/asset/constants";

export const HomeInformationConfig = [
	{
		component: ControlledInput,
		props: {
			name: WIDTH.path,
			label: WIDTH.label,
			type: "number",
			span: 1,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: LENGTH.path,
			label: LENGTH.label,
			type: "number",
			span: 1,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: BEDROOMS.path,
			label: BEDROOMS.label,
			type: "number",
			span: 1,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: BATHROOMS.path,
			label: BATHROOMS.label,
			type: "number",
			span: 1,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: SERIAL_NUMBER.path,
			label: SERIAL_NUMBER.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: STOCK_NUMBER.path,
			label: STOCK_NUMBER.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: HUD_NUMBER_1.path,
			label: HUD_NUMBER_1.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: HUD_NUMBER_2.path,
			label: HUD_NUMBER_2.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: MANUFACTURER.path,
			label: MANUFACTURER.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: MODEL_NAME.path,
			label: MODEL_NAME.label,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: SECTIONS.path,
			label: SECTIONS.label,
			type: "number",
		},
	},
	{
		component: ControlledInput,
		props: {
			name: YEAR.path,
			label: YEAR.label,
			type: "number",
		},
	},
	{
		component: FoundationTypesSelect,
		props: {
			name: FOUNDATION_TYPE_ID.path,
			labels: { label: FOUNDATION_TYPE_ID.label },
		},
	},
	{
		component: SidingTypesSelect,
		props: {
			name: SIDING_TYPE_ID.path,
			labels: { label: SIDING_TYPE_ID.label },
		},
	},
	{
		component: RoofTypesSelect,
		props: {
			name: ROOF_TYPE_ID.path,
			labels: { label: ROOF_TYPE_ID.label },
		},
	},
	{
		component: RoofLoadSelect,
		props: {
			name: ROOF_LOAD.path,
			labels: { label: ROOF_LOAD.label },
		},
	},
	{
		component: HvacTypeSelect,
		props: {
			name: HVAC_TYPE.path,
			labels: { label: HVAC_TYPE.label },
		},
	},
	{
		component: HvacStatusSelect,
		props: {
			name: HVAC_STATUS.path,
			labels: { label: HVAC_STATUS.label },
		},
	},
	{
		component: UtilitySystemSelect,
		props: {
			name: UTILITY_SYSTEM.path,
			labels: { label: UTILITY_SYSTEM.label },
		},
	},
	{
		component: WasteSystemSelect,
		props: {
			name: WASTE_SYSTEM.path,
			labels: { label: WASTE_SYSTEM.label },
		},
	},
	{
		component: WaterSystemSelect,
		props: {
			name: WATER_SYSTEM.path,
			labels: { label: WATER_SYSTEM.label },
		},
	},
	{
		component: ThermalZoneSelect,
		props: {
			name: THERMAL_ZONE.path,
			labels: { label: THERMAL_ZONE.label },
			formName: "HomeInformation",
		},
	},
	{
		component: WindZoneSelect,
		props: {
			name: WIND_ZONE.path,
			labels: { label: WIND_ZONE.label },
		},
	},
];
