import { useGetMediaTypesQuery } from "@/features/lookups";

export const useMediaType = () => {
	const { data: mediaTypes, isSuccess } = useGetMediaTypesQuery();
	const idLookup = Object.fromEntries(
		Object.entries(mediaTypes ?? {}).map(([key, value]) => [value, key])
	);

	const photoType = idLookup.photo;
	const videoType = idLookup.video;

	const isPhoto = (image) => mediaTypes?.[image?.mediaTypeId] === "photo";
	const isVideo = (image) => mediaTypes?.[image?.mediaTypeId] === "video";

	return { photoType, videoType, isPhoto, isVideo, isSuccess };
};
