import {
	useGetOfflineAssetsQuery,
	useRemoveAllOfflineAssetsMutation,
} from "@/features/offline-use";

export const useDeleteAllOfflineAssets = () => {
	const { data: offlineData, isSuccess } = useGetOfflineAssetsQuery();

	const [triggerDelete, { isLoading }] = useRemoveAllOfflineAssetsMutation({
		fixedCacheKey: "deleteAllOfflineAssets",
	});

	return {
		triggerDelete,
		isLoading,
		isDeleteDisabled: isSuccess && !offlineData?.ids.length,
	};
};
