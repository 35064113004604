import { Checkbox } from "@salesforce/design-system-react";
import {
	useFeature,
	useToggleFeature,
} from "@/providers/feature-flags/FeatureProvider";

export const Flag = ({ title, value }) => {
	const isEnabled = useFeature(value);
	const toggleFeature = useToggleFeature(value);

	return (
		<>
			<Checkbox
				labels={{
					label: title,
				}}
				id={`flag-${title}`}
				checked={isEnabled}
				onChange={toggleFeature}
			/>
		</>
	);
};
