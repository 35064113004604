import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

export const filterAdaptor = createEntityAdapter({
	sortComparer: (a, b) => a.id - b.id,
});

export const ALL_MEDIA = "allMedia";
export const WEB_READY_MEDIA = "webReady";

export const initialState = {
	[WEB_READY_MEDIA]: {
		selected: {},
	},
	[ALL_MEDIA]: {
		includeWebReady: false,
		sort: {
			property: "dateFileCreated",
			direction: "desc",
		},
		filters: filterAdaptor.getInitialState(),
		selected: {},
	},
};

export const mediaSlice = createSlice({
	name: "media",
	initialState: {},
	reducers: {
		addAssetMediaState: (state, action) => {
			state[action.payload] = initialState;
			return state;
		},
		removeAssetMediaState: (state, action) => {
			delete state[action.payload];
		},
		toggleIncludeWebReady: (state, action) => {
			// const { assetId } = action.payload;
			state[action.payload][ALL_MEDIA].includeWebReady =
				!state[action.payload][ALL_MEDIA].includeWebReady;
		},
		addFilter: (state, action) => {
			const { assetId, filter } = action.payload;
			filterAdaptor.addOne(state[assetId][ALL_MEDIA].filters, filter);
		},
		removeFilter: (state, action) => {
			const { assetId, filterId } = action.payload;
			filterAdaptor.removeOne(state[assetId][ALL_MEDIA].filters, filterId);
		},
		removeAllFilters: (state, action) => {
			const { assetId } = action.payload;
			filterAdaptor.removeAll(state[assetId][ALL_MEDIA].filters);
		},
		updateFilter: (state, action) => {
			const { assetId } = action.payload;
			filterAdaptor.updateOne(state[assetId][ALL_MEDIA].filters, action);
		},
		setAllFilters: (state, action) => {
			const { assetId, filters } = action.payload;
			filterAdaptor.setAll(state[assetId][ALL_MEDIA].filters, filters);
		},
		addOrUpdateFilter: (state, action) => {
			const { assetId, filter } = action.payload;
			filterAdaptor.upsertOne(state[assetId][ALL_MEDIA].filters, filter);
		},
		setSelection: (state, action) => {
			const { assetId, bucket, update } = action.payload;

			state[assetId][bucket].selected = update;
		},
		toggleSelection: (state, action) => {
			const { assetId, bucket, mediaId } = action.payload;

			state[assetId][bucket].selected[mediaId] =
				!state[assetId][bucket].selected[mediaId];
		},
		updateSort: (state, action) => {
			const { assetId, ...update } = action.payload;

			state[assetId][ALL_MEDIA].sort = {
				...state[assetId][ALL_MEDIA].sort,
				...update,
			};
		},
	},
});

export const {
	addFilter,
	removeFilter,
	removeAllFilters,
	updateFilter,
	setAssetId,
	addOrUpdateFilter,
	toggleIncludeWebReady,
	updateSort,
	addAssetMediaState,
	removeAssetMediaState,
	setSelection,
	toggleSelection,
} = mediaSlice.actions;

export const setWebReadySelection = ({ assetId, update }) =>
	setSelection({ assetId, update, bucket: WEB_READY_MEDIA });

export const setAllMediaSelection = ({ assetId, update }) =>
	setSelection({ assetId, update, bucket: ALL_MEDIA });

export const availableFilters = {
	tags: ["includes", "doesNotInclude", "includesAny", "includesAll"],
	date: ["before", "between"],
};
