import React from "react";
import classes from "./DocumentationCard.module.scss";
import {
	useGetNoteReasonsQuery,
	useGetNoteSourcesQuery,
} from "../../api/endpoints";
import { selectSearch } from "../../api/selectors";
import { useSelector } from "react-redux";
import { Note } from "../../types";

export const DocumentationCard = ({
	note,
	sideView,
}: {
	note: Note | undefined;
	sideView: boolean;
}) => {
	const search = useSelector(selectSearch(note?.accountId ?? ""));
	const { data: sources } = useGetNoteSourcesQuery(undefined);
	const { data: reasons } = useGetNoteReasonsQuery(undefined);
	const reason = reasons?.find((item) => item.id === note?.reasonId);
	const source =
		sources?.find((item) => item.id === note?.sourceId)?.description ?? "";

	const sourceClass = source?.split("/")[0].toLowerCase();
	const re = new RegExp(search, "i");
	const match = note?.content?.match(re);

	return (
		<div className={`${classes.Container} ${sideView && classes.SideView}`}>
			<div className={classes.info}>
				<p style={{ fontWeight: "bold" }}>{note?.createdBy}</p>
				<p className={`${classes.source} ${classes[sourceClass]}`}>{source}</p>
				<p>{new Date(note?.createdDate ?? "").toLocaleString()}</p>
			</div>
			<div className={classes.gridItem}>
				<p style={{ fontWeight: "bold" }}>{reason?.description}</p>
				<p className={classes.text}>
					{match && match.index && match.index !== 0 ? (
						<span>
							<span>{note?.content.slice(0, match?.index)}</span>
							<span style={{ backgroundColor: "yellow" }}>{match?.[0]}</span>
							{match?.index && (
								<span>
									{note?.content.slice(match?.index + match?.[0]?.length)}
								</span>
							)}
						</span>
					) : (
						<span>{note?.content}</span>
					)}
				</p>
			</div>
		</div>
	);
};
