import { useDispatch, useSelector } from "react-redux";
import { Toast, ToastContainer } from "@salesforce/design-system-react";
import classes from "@/Components/Toasts/SalesforceToast.module.scss";
import { closeToast, selectToasts } from "@/ReduxToolkit/ToastsSlice";

export const ToastProvider = ({ children }) => {
	const toasts = useSelector(selectToasts);
	const dispatch = useDispatch();

	return (
		<>
			{toasts.length > 0 && (
				<ToastContainer className={classes.ToastContainer}>
					{toasts.map((toast) => (
						<Toast
							className={classes.Toast}
							key={toast.id}
							{...toast}
							onRequestClose={() => dispatch(closeToast(toast.id))}
						/>
					))}
				</ToastContainer>
			)}
			{children}
		</>
	);
};
