import { each, enforce, test } from "vest";
import { get } from "lodash";
import {
	ADDRESS,
	ADDRESS_2,
	CITY,
	STATE,
	ZIP,
	COUNTY,
	CONTACT_NAME,
	LATITUDE,
	LONGITUDE,
} from "@/features/asset/constants";
import { states } from "@/Utilities/enums";

export const locationModalValidation = (data = {}) => {
	each(
		[
			ADDRESS.path,
			ADDRESS_2.path,
			CITY.path,
			COUNTY.path,
			STATE.path,
			CONTACT_NAME.path,
		],
		(field) => {
			test(field, "Must be a string", () => {
				enforce(get(data, field)).oneOf(enforce.isString(), enforce.isNull());
			});
		}
	);

	test(ZIP.path, "Must be a valid Zip Code (#####)", () => {
		enforce(get(data, ZIP.path)).oneOf(enforce.isZipCode(), enforce.isNull());
	});

	test(STATE.path, "Must be a valid state", () => {
		enforce(get(data, STATE.path)).oneOf(
			enforce.inside(Object.keys(states)),
			enforce.isNull()
		);
	});

	each([LATITUDE.path, LONGITUDE.path], (field) => {
		test(field, "Must be a number", () => {
			enforce(get(data, field)).oneOf(enforce.isNumeric(), enforce.isNull());
		});
	});
};
