import { Button } from "@salesforce/design-system-react";
import React from "react";
import Proptypes from 'prop-types'

export function ShowContentButton({ isVisible, onShowContent }) {
	const iconName = isVisible ? "chevronup" : "chevrondown";
	const handleShowContent = onShowContent;

	return (
		<Button
			assistiveText={{ icon: "Expand" }}
			iconCategory="utility"
			iconName={iconName}
			iconVariant="container"
			variant="icon"
			onClick={handleShowContent} />
	);
}

ShowContentButton.propTypes = {
	isVisible: Proptypes.bool,
	onShowContent: Proptypes.func
}