import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateMediaPropertiesMutation } from "../endpoints";
import { selectWebReadyMedia } from "../selectors";

const createMoveWebReadyUpdate = ({ webReadyMedia, image, destination }) => {
	const notMoving = webReadyMedia.filter((web) => web.id !== image.id);

	return [
		...notMoving.slice(0, destination).map((image, index) => ({
			...image,
			order: index,
			...(index === 0 ? { isPrimary: true } : { isPrimary: false }),
		})),
		{
			...image,
			order: destination,
			...(destination === 0 ? { isPrimary: true } : { isPrimary: false }),
		},
		...notMoving.slice(destination).map((image, index) => ({
			...image,
			order: index + destination + 1,
			isPrimary: false,
		})),
	];
};

export const useMoveWebReady = (assetId) => {
	const webReadyMedia = useSelector(selectWebReadyMedia(assetId));
	const dispatch = useDispatch();

	const update = (image, destination) =>
		createMoveWebReadyUpdate({ webReadyMedia, image, destination });

	const [trigger, result] = useUpdateMediaPropertiesMutation({
		fixedCacheKey: "reorder-web-ready",
	});

	const moveWebReady = (image, destination) => {
		trigger(update(image, destination));
	};

	useEffect(() => {
		if (result?.error) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong when reordering web ready images`,
					},
					variant: "error",
					// timeout: 5000,
				})
			);
		}
	}, [dispatch, result?.error, result.requestId]);

	return { moveWebReady, ...result };
};
