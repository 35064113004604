import { useEffect, useRef, useState } from "react";

export const useMediaStream = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const stream = useRef();

	useEffect(() => {
		if (!isLoaded) {
			(async () => {
				stream.current = await navigator.mediaDevices.getUserMedia({
					video: {
						facingMode: "environment",
					},
					audio: false,
				});

				setIsLoaded(true);
			})();
		} else {
			return () => {
				stream.current.getTracks().forEach((track) => track.stop());
				setIsLoaded(false);
			};
		}
	}, [isLoaded]);

	return isLoaded && stream.current;
};
