import Spinner from "@/Components/UI/Spinner/Spinner";
import Illustration from "@/media/error_Illustration.svg";
import classes from "./ErrorContainer.module.scss";
import { Button } from "@salesforce/design-system-react";

export const ErrorContainer = ({ error, ...props }) => {
	if (error?.status === 401 || error?.statusCode === 401) {
		return <Spinner />;
	}
	return (
		<div className={classes.container}>
			<h2 style={{ fontSize: "20px" }}>Oops</h2>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<p>
					Something went wrong. If it persists, please contact the Service Desk
				</p>
				<div>
					<a href="mailto:servicedesk@claytonhomes.com">Email</a> or{" "}
					<a href="tel:8653803555">Phone</a>
				</div>
			</div>

			<img src={Illustration} alt="desert" />
			<Button variant="brand" onClick={() => window.location.reload()}>
				Refresh
			</Button>
		</div>
	);
};
