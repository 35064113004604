import { gatewayApi } from '@/ReduxToolkit/GatewayApi';
import { assetListStringify } from '@/Utilities/utils';
import { Asset } from '@/features/asset/types';
import { EntityState, createAction, createEntityAdapter } from '@reduxjs/toolkit';
import { find, get } from 'lodash';
import * as home from "@/features/asset/constants";
import { addAppListener } from '@/ReduxToolkit/listenerMiddleware';

type filteringCriteria = {
	propertyPath: string,
	operation: string,
	value: string
}


export type AssetListPayload = {
	paginationCriteria: {
		itemsPerPage: number,
		pageNumber: number
	}
	filteringCriteria: filteringCriteria[],
	sortCriteria: {
		direction: "asc" | "desc",
		propertyPath: string,
	}
	fuzzySearchCriteria: {
		propertyPath: string[],
		value: string
	}
	name?: string
}

type AssetListResponse = {
    items: Asset[],
	totalItems: number,
	itemsPerPage: number,
	pageNumber: number
}

type AssetListAdapter = EntityState<Asset>

type AssetListResponseEntity = {
	items: AssetListAdapter,
	totalItems: number,
	itemsPerPage: number,
	pageNumber: number
}



export const assetListAdapter = (sorting: {propertyPath: string, direction: string}) =>
	createEntityAdapter<Asset>({
		selectId: (asset) => asset.id,
		sortComparer: (a, b) => {
			const { propertyPath, direction } = sorting ?? {};
			let val = 0;

			if (get(a, propertyPath) > get(b, propertyPath)) {
				val = 1;
			}
			if (get(a, propertyPath) < get(b, propertyPath)) {
				val = -1;
			}
			if (direction === "desc") {
				val *= -1;
			}
			return val;
		},
	});

const assetListEndpoints = gatewayApi.injectEndpoints({
	endpoints: (builder) => ({
		getAssets: builder.mutation<AssetListResponse, AssetListPayload>({
            query: (term) => {
                return {
                    url: "api/Search/AssetList",
                    method: "POST",
                    body: term
                }
            },
		}),
        getAllAssets: builder.query<AssetListResponseEntity, AssetListPayload>({
			query: ({ ...searchProperties }) => {
				return {
					url: "api/Search/AssetList",
					method: "POST",
					body: {
						...searchProperties,
						filteringCriteria: [
							...searchProperties.filteringCriteria.map((item) => {
								return {
									...item,
									value: assetListStringify(item.value),
								};
							}),
						],
					},
				};
			},
			transformResponse: (res: AssetListResponse, _, { sortCriteria, ...args }) => {
				const { items, ...rest } = res;
				return {
					items: assetListAdapter(sortCriteria).upsertMany(
						assetListAdapter(sortCriteria).getInitialState(),
						items
					),
					...rest,
				};
			},
			serializeQueryArgs: ({ endpointName, queryArgs }) => {
				if (typeof queryArgs === "object") {
					const {
						sortCriteria: { direction, propertyPath },
						name,
						fuzzySearchCriteria,
					} = queryArgs;

					const key = name ? name : "filtered";
					const sort = getSortName(propertyPath);

					return fuzzySearchCriteria?.value &&
						fuzzySearchCriteria?.value?.length > 0
						? `${endpointName}(${key}-${direction}-${sort}-search)`
						: `${endpointName}(${key}-${direction}-${sort})`;
				} else {
					return queryArgs;
				}
			},
			merge: (currentCache, responseData, { arg }) => {
				const { sortCriteria } = arg;
				if (arg.paginationCriteria.pageNumber > 0) {
                    assetListAdapter(sortCriteria).upsertMany(
                        currentCache.items,
                        //@ts-ignore
                        Object.values(responseData.items.entities)
                    )
				} else {
					assetListAdapter(sortCriteria).setAll(
						currentCache.items,
                        //@ts-ignore
						Object.values(responseData.items.entities)
					);
				}
				currentCache.totalItems = responseData.totalItems;
				currentCache.pageNumber = responseData.pageNumber;
			},
			forceRefetch({ currentArg, previousArg }) {
                if (currentArg?.paginationCriteria?.pageNumber && previousArg?.paginationCriteria?.pageNumber) {
                    return (
                        currentArg?.paginationCriteria?.pageNumber >
                        previousArg?.paginationCriteria?.pageNumber
                    );
                }
                return false;
			},
		}),
	}),
	overrideExisting: true,
})

export const {
	useGetAssetsMutation,
    useGetAllAssetsQuery,
	useLazyGetAllAssetsQuery,
	endpoints: { getAssets, getAllAssets }
} = assetListEndpoints;

function getSortName(path: string) {
	return find(home, (fields) => fields.path === path)?.key;
}

export function getSortPath(name: string) {
	return find(home, (fields) => fields.key === name)?.path;
}

type AssetListCacheUpdate = {
	response: AssetListResponse,
	request: AssetListPayload,
}

const assetUpdate = createAction<AssetListCacheUpdate, 'cacheDidUpdate'>('cacheDidUpdate')

addAppListener({
	actionCreator: assetUpdate,
	effect: async (action, {dispatch}) => {
		dispatch(
			assetListEndpoints.util.updateQueryData(
				"getAllAssets",	
				action.payload.request,
				() => {
					const { items, ...rest } = action.payload.response;
					const { sortCriteria } = action.payload.request;
					return {
						items: assetListAdapter(sortCriteria).upsertMany(
							assetListAdapter(sortCriteria).getInitialState(),
							items
						),
						...rest,
					};
				}
			)
		);
	},
});