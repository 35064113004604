import React from "react";
import { AccountHeader } from "./components/AccountHeader";
import { AssetCard } from "./components/AssetCard";
import classes from "./Account.module.scss";
export const Account = ({ account, props }) => {
	return (
		<>
			<AccountHeader account={account} />
			<div className="slds-box slds-theme_default" style={{ marginTop: "5px" }}>
				{account.assets.length === 0 ? (
					<>
						<p style={{ fontSize: "20px" }}>No Assets found</p>
						<p>We found 0 assets with Account #{account.accountNumber}</p>
					</>
				) : (
					<>
						<p style={{ fontSize: "20px" }}>
							We found {account.assets.length} assets with the Account #
							{account.accountNumber}, click on an address to view more details
						</p>
						<div className={classes.Assets}>
							{account.assets.map((item) => {
								return <AssetCard key={item.id} asset={item} />;
							})}
						</div>
					</>
				)}
			</div>
		</>
	);
};
