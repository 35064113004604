import { useHomeDisposition } from "./hooks/useHomeDisposition";
import { Worksheet } from "./components/Worksheet";
import { Header } from "./components/Header";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { useSetupSale } from "./hooks/useSetupSale";
/*
    Tab to be called booking on asset page

    - Worksheet (formerly known as pre booking)
	- Source of Funds
	- Commision
	- Imaging
	- Buyer Information
	- New Loan Information
    - Checklist
	- 17 Prebuilt things
	- User has to complete items
    - Processing (book the deal - Phase 2 only)


    User Roles: 
    Everybody has access to put in information in the worksheet (exclude editing for audit misc group)
    Checklist - Coordinators only 
    Processing - Coordinators only
*/

export const Booking = ({ assetId }: { assetId: string }) => {
	/*
	this retrieves a disposition object off of the asset using asset id
    */
	const { disposition, isSuccess } = useHomeDisposition(assetId);
	const { trigger, isSettingUpSaleInfo } = useSetupSale(assetId);

	// if query is succesful, and sale id doesn't exist on the disposition, create a new sale.
	if (isSuccess && !isSettingUpSaleInfo && !disposition?.saleId) {
		trigger();
	}

	// if not succesful or disposition doesn't exist, wait for creation
	if (!isSuccess || !disposition) {
		return <Spinner />;
	}

	return (
		<>
			<Header saleId={disposition.saleId} />
			<Worksheet disposition={disposition} assetId={assetId} />
		</>
	);
};
