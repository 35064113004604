import { gatewayApi } from "@/ReduxToolkit";
import { packPropertyBag } from "@/ReduxToolkit/MediaProperties";
import dayjs from "dayjs";

/**
 * This function will allow uploads directly to the amazon s3 bucket, locally it proxies the request to /presignedUpload
 * to get around the https limitation for file uploads to S3.
 * @param {File} file
 * @param {string} url
 */
const awsUpload = async (file, url) => {
	let awsUrl = url;
	if (process.env.NODE_ENV === "development") {
		awsUrl = awsUrl.replace("https://s3.amazonaws.com", "/presignedUpload");
	}
	const response = await fetch(awsUrl, {
		method: "PUT",
		headers: {
			"Content-Type": "application/octet-stream",
		},
		credentials: "omit",
		body: file,
	});
	if (response.ok) {
		return true;
	} else {
		return false;
	}
};

export const presignedUploadMedia = {
	queryFn: async (
		{ assetId, file, mediaTypeId },
		{ getState },
		_extraOptions,
		baseQuery
	) => {
		const lookupMediaTypes = gatewayApi.endpoints.getMediaTypes.select()(
			getState()
		)?.data;

		if (!lookupMediaTypes) {
			throw new Error("media types are missing");
		}

		const types = Object.fromEntries(
			Object.entries(lookupMediaTypes ?? {}).map(([key, value]) => [value, key])
		);

		const photoType = types?.photo;
		const videoType = types?.video;

		/**
		 * @param {File} file
		 */
		const makeReservation = async (file) => {
			const fileTypeId =
				mediaTypeId ?? file?.type?.split("/")[0] === "video"
					? videoType
					: photoType;

			const properties = packPropertyBag({
				propertyBag: [],
			});

			const data = {
				createdBy: "055b1771-f2e1-4b05-9418-c5dc2d594c1a",
				groupingId: assetId,
				mediaTypeId: fileTypeId,
				dateFileCreated: dayjs().toISOString(),
				fileSize: file.size,
				name: file.name,
				propertyBag: JSON.stringify(properties),
			};

			const response = await baseQuery({
				url: `/api/Media/reservations`,
				method: "POST",
				body: data,
			});

			if (response.error) throw response.error;

			return response;
		};

		// attempt to upload the file, catch any errors and update the store accordingly
		try {
			const { data: reservation } = await makeReservation(file);
			if (!reservation?.path || !reservation?.id) {
				console.log("initial reservation error: ", reservation);
				const { data: retry } = await makeReservation(file);
				if (!retry?.path || !retry?.id) {
					console.log("reservation retry error: ", retry);
					throw new Error("missing reservation");
				}
			}

			const upload = await awsUpload(file, reservation.path);
			if (!upload) throw new Error("upload to S3 failed");
			const uploadId = reservation.id;
			const response = await baseQuery({
				url: `/api/Media/reservations/${uploadId}`,
				method: "POST",
			});
			if (response.error) {
				console.log(response.error);
				const retry = await baseQuery({
					url: `/api/Media/reservations/${uploadId}`,
					method: "POST",
				});
				if (retry.error) {
					console.log(response.error);
					throw new Error("reservation completion failed");
				}
			}
			return response;
		} catch (e) {
			console.log(e);

			return { error: e };
		}
	},
	invalidatesTags: (_result, _error, media) => [
		{ type: "AssetMedia", id: media.assetId },
	],
};
