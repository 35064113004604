import { PricingWorksheetEstimateType, PricingWorksheetType } from "../types";
import { APPROVAL_STATUS_OPTIONS, SELECTED_PLAN_OPTIONS } from "../constants";
import styles from "../PricingWorksheet.module.scss";
import { usePricingWorksheet } from "../usePricingWorksheet";
import { useGetUserQuery } from "@/features/user/api/endpoints";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";

export const WorksheetTitle = ({
	homeId,
	data,
	index,
}: {
	homeId: string;
	data: PricingWorksheetType;
	index: number;
}) => {
	const status = APPROVAL_STATUS_OPTIONS[data.approvalStatus];
	const { data: user, isSuccess } = useGetUserQuery(data.approverId as string);
	const selectedPlan = data.selectedPlan ?? 0;
	const type =
		data.selectedPlan !== null && SELECTED_PLAN_OPTIONS[data.selectedPlan];
	const isMisc = data.selectedPlan !== null && data.selectedPlan > 1;

	const column = ["retailEstimates", "wholesaleEstimates", "miscEstimates"][
		isMisc ? 2 : selectedPlan
	] as PricingWorksheetEstimateType;

	const display = [
		`Approved ${dayjs(data.approvedDate as string).format("MM/DD/YYYY")}`,
		`${isSuccess ? `${user.firstName} ${user.lastName}` : ""}`,
		`Base: ${data[column].basePrice}`,
		`Net Sale: ${data[column].netSale}`,
		`Recovery: ${data[column].recovery}%`,
		`Type: ${type}`,
	]
		.filter((x) => x)
		.join(" - ");

	const displayDate = status === "Approved" ? display : "Pricing Worksheet";

	const [params, setParams] = useSearchParams();

	const isHistoryPage = params.get("history");

	const onClickWorksheet = () => {
		setParams({
			tab: "marketingPlan",
			worksheet: index.toString(),
		});
	};

	return (
		<h2
			onClick={onClickWorksheet}
			className={styles.worksheetTitle}
			{...(isHistoryPage && {
				onClick: onClickWorksheet,
				style: { cursor: "pointer" },
			})}
		>
			<ApprovalStatus assetId={homeId} data={data} />
			<span style={{ fontSize: "1rem" }}>{displayDate}</span>
		</h2>
	);
};

export function ApprovalStatus({
	data,
	assetId,
}: {
	data: PricingWorksheetType | { approvalStatus: number };
	assetId: string;
}) {
	const { isHomeRepossessed } = usePricingWorksheet(assetId);

	const repossessionStatus = isHomeRepossessed ? 1 : 0;

	const colors = ["#5C5C5C", "#2E844A", "#2E844A", "#0176D3"];

	const approvalStatus =
		data.approvalStatus < 2 || (data.approvalStatus === 2 && !isHomeRepossessed)
			? repossessionStatus
			: data.approvalStatus;

	return (
		<p
			className={styles.status}
			style={{
				color: colors[approvalStatus],
				fontWeight: approvalStatus < 1 ? "inherit" : "bold",
				backgroundColor: "white",
			}}
		>
			{APPROVAL_STATUS_OPTIONS[approvalStatus]}
		</p>
	);
}
