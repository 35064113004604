import { get } from "lodash";

interface PatchObject {
	op: "add" | "remove" | "replace";
	path: string;
	value?: any;
}

export const createPatch = (
	obj1: Object,
	obj2: Object,
	shouldCheckConcurrency = true
) => {
	// const obj2 = merge(change, obj1);
	const flattenAndCompare = (
		obj1: Object,
		obj2: any,
		parentProperty = "",
		result: PatchObject[] = []
	) => {
		const isArray = Array.isArray(obj2);

		if (isArray) {
			const newItems = obj2.filter((item) => item.id === undefined);

			const removedItems = get(obj1, parentProperty)
				.map((item: { id: string; [key: string]: any }, index: number) => {
					const obj = obj2.find(({ id }) => id && id === item.id);
					return !obj && index;
				})
				.filter((item: number | false) => item);

			removedItems.forEach((index: number) => {
				result.push({
					op: "remove",
					path: `/${replacePath(parentProperty)}/${index}`,
				});
			});

			obj2
				.filter((item) => item.id)
				.forEach((item, index) => {
					flattenAndCompare(obj1, item, `${parentProperty}[${index}]`, result);
				});

			if (
				get(obj1, parentProperty).length >=
				obj2.filter((item) => item.id).length
			) {
				newItems.forEach((item) => {
					result.push({
						op: "add",
						path: `/${replacePath(parentProperty)}/-`,
						value: item,
					});
				});
			}
		} else {
			for (const [key, value] of Object.entries(obj2)) {
				const stringValue = isArray
					? `${parentProperty}[${key}]`
					: `${parentProperty}.${key}`;
				const property = parentProperty ? stringValue : key;

				if (value && typeof value === "object") {
					flattenAndCompare(obj1, value, property, result);
				} else {
					if (value !== get(obj1, property) && value !== undefined) {
						const concurrency = parentProperty
							? `${parentProperty}.concurrencyToken`
							: "concurrencyToken";

						const updateConcurrency =
							!result.find((res) => res.path === concurrency) &&
							shouldCheckConcurrency;

						if (get(obj1, concurrency) && updateConcurrency) {
							if (
								!result.find((res) => res.path === "concurrencyToken") &&
								parentProperty
							) {
								result.unshift({
									op: "add",
									path: `/concurrencyToken`,
									value: get(obj1, `concurrencyToken`),
								});
							}
							result.unshift({
								op: "add",
								path: `/${replacePath(concurrency)}`,
								value: get(obj1, concurrency),
							});
						}
						result.push({
							op: "replace",
							path: `/${replacePath(property)}`,
							value,
						});
					}
				}
			}
		}

		return result;
	};

	return flattenAndCompare(obj1, obj2, "", []);
};

function replacePath(path: string) {
	const re = /(\.|\[|\])+/gm;
	return path.replace(re, "/");
}
