import React, { useState, createContext, useContext, useEffect } from "react";
import { Button, Popover, Slider } from "@salesforce/design-system-react";
import classes from "./PhotoSizeSlider.module.scss";
import { useRef } from "react";
import { useIsMobile } from "@/Responsive";

const PhotoSliderStateContext = createContext();
const PhotoSliderStateProvider = PhotoSliderStateContext.Provider;

const PhotoSliderDispatchContext = createContext();
const PhotoSliderDispatchProvider = PhotoSliderDispatchContext.Provider;

export const PhotoSliderContext = ({ children }) => {
	const ref = useRef();

	const photoSliderWidth =
		Number(localStorage.getItem("homebaseCardWidth")) || 280;

	const [cardWidth, setCardWidth] = useState(photoSliderWidth);
	const [nCols, setNCols] = useState(null);
	const [width, setWidth] = useState(null);

	useEffect(() => {
		const w = ref.current?.clientWidth;
		const width = w > cardWidth ? w : cardWidth;
		const col = Math.round(width / cardWidth);
		setWidth(width);
		setNCols(col > 1 ? col : 1);
		setCardWidth(width / col);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const resize = () => {
			setWidth(ref.current?.clientWidth);
		};

		window.addEventListener("resize", resize);
		window.addEventListener("orientationchange", resize);
		return () => {
			window.removeEventListener("resize", resize);
			window.removeEventListener("orientationchange", resize);
		};
	}, []);

	return (
		<PhotoSliderStateProvider value={{ photoSlider: cardWidth, nCols, width }}>
			<PhotoSliderDispatchProvider
				value={{ setPhotoSlider: setCardWidth, setNCols, setWidth }}
			>
				<div ref={ref} className={classes.PhotoSizeSliderContainer}>
					{width && children}
				</div>
			</PhotoSliderDispatchProvider>
		</PhotoSliderStateProvider>
	);
};

export const usePhotoSlider = () => {
	const { photoSlider, nCols, width } = useContext(PhotoSliderStateContext);
	const { setPhotoSlider, setNCols } = useContext(PhotoSliderDispatchContext);
	return { photoSlider, nCols, width, setNCols, setPhotoSlider };
};

export const PhotoSizeSlider = ({ mobile }) => {
	const { photoSlider, setPhotoSlider, width, setNCols } = usePhotoSlider();
	const isMobile = useIsMobile();

	const handleChange = (event, { value }) => {
		const newPhotoWidth = Math.ceil((width - 6) / value);
		setPhotoSlider(newPhotoWidth);
		localStorage.setItem("homebaseCardWidth", newPhotoWidth);
		setNCols(value);
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "flex-end",
				position: "relative",
			}}
		>
			<Popover
				align="left"
				position="relative"
				style={{
					width: mobile ? "90%" : "40%",
					position: "absolute",
					bottom: "-1rem",
					right: mobile ? "2.2rem" : "3rem",
				}}
				ariaLabelledby="photoSlider"
				body={
					<Slider
						value={Math.round(width / photoSlider)}
						onChange={handleChange}
						id="photo-size-slider"
						label="Size of Cards"
						min={1}
						max={isMobile ? 2 : 8}
					/>
				}
			>
				<Button
					style={{ position: "absolute", bottom: ".25rem", right: ".25rem" }}
					variant="icon"
					iconVariant="container"
					iconCategory="utility"
					iconName="settings"
				/>
			</Popover>
		</div>
	);
};
