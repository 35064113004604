import { createAction, createSlice } from "@reduxjs/toolkit";

export const onOnline = createAction("__rtkq/online");
export const onOffline = createAction("__rtkq/offline");

export const onlineDetected = createAction("offlineUse/onlineDetected");
export const offlineDetected = createAction("offlineUse/offlineDetected");

export const offlineAssetSlice = createSlice({
	name: "offlineAssets",
	initialState: {
		isOnline: navigator.onLine,
		isUpdateAvailable: false,
		isSyncing: false,
		syncing: [],
		syncItemCount: 0,
	},
	reducers: {
		updateWaiting: (state) => {
			state.isUpdateAvailable = true;
		},
		updateServiceWorker: (state) => {
			state.isUpdateAvailable = false;
		},
		startSyncing: (state, action) => {
			state.isSyncing = true;
			state.syncItemCount = action.payload;
		},
		doneSyncing: (state) => {
			state.isSyncing = false;
		},
		readyToSync: (state, action) => {
			if (!state.syncing.includes(action.payload)) {
				state.syncing.push(action.payload);
			}
		},
		setOnline: (state) => {
			state.isOnline = true;
		},
		setOffline: (state) => {
			state.isOnline = false;
		},
	},
});

export const {
	updateWaiting,
	updateServiceWorker,
	startSyncing,
	doneSyncing,
	readyToSync,
	setOnline,
	setOffline,
} = offlineAssetSlice.actions;

export const syncAssetDetails = createAction("offlineSync/assetDetails");
export const syncAssetDetailsDone = createAction(
	"offlineSync/assetDetailsDone"
);

export const syncMedia = createAction("offlineSync/media");
export const syncMediaDone = createAction("offlineSync/mediaDone");

export const syncConditionReports = createAction(
	"offlineSync/conditionReports"
);
export const syncConditionReportsDone = createAction(
	"offlineSync/conditionReportsDone"
);

export const syncDocumentation = createAction("offlineSync/documentation");
export const syncDocumentationDone = createAction(
	"offlineSync/documentationDone"
);

export const cacheOfflineAssets = createAction("offlineUse/cacheOfflineAssets");

export const selectIsOnline = (state) => state.offlineAssets.isOnline;
export const selectReadyToSync = (state) => state.offlineAssets.syncing;
export const selectIsSyncing = (state) => state.offlineAssets.isSyncing;
export const selectSyncItemCount = (state) =>
	state.offlineAssets.syncItemCount.total;
