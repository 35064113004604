import React from "react";
import { SLDSIcon, SLDSPill } from "@salesforce/design-system-react";

const TagIcon = () => <SLDSIcon category="utility" name="quote" />;

export const Tag = (props) => {
	return (
		<SLDSPill
			assistiveText={{ remove: "remove tag" }}
			onRemove={props.onRemove}
			labels={{ label: props.value, title: props.value, removeTitle: "remove" }}
			icon={<TagIcon />}
			variant="option"
			{...props}
		/>
	);
};
