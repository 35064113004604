import React, { useState } from "react";
import {
	Button,
	Card,
	Radio,
	RadioGroup,
	Icon,
} from "@salesforce/design-system-react";
import PropTypes from "prop-types";
import "./InsuranceCard.scss";
import DayJS from "dayjs";
import { ReviewStatusBadge } from "./ReviewStatus";
import { useRequestInsuranceCancellationMutation } from "@/ReduxToolkit/GatewayApi";
import { Spinner } from "@salesforce/design-system-react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/features/user/api/selectors";

export const InsuranceCard = ({ account, ...props }) => {
	const radioValues = ["To Customer", "To VMF"];
	const radioLabels = { label: "Insurance Decision" };
	const [radio, setRadio] = useState("");
	const disabledSubmit =
		account.reviewStatus !== "needsReview" &&
		account.reviewStatus !== "autoRefund"
			? true
			: account.reviewStatus === "needsReview" && radio === "";

	const submitButtonValue =
		account.reviewStatus === "autoRefund" ? "Approve" : "Submit";

	// Use Mutation
	const [updateInsuranceCancellation, { isLoading }] =
		useRequestInsuranceCancellationMutation();
	const { email } = useSelector(selectCurrentUser);
	const submitValue = async () => {
		const recipientValue =
			account.reviewStatus === "autoRefund"
				? ""
				: radio === "To Customer"
				? "Customer"
				: "VMF";
		const submission = {
			cancellationQueueId: account.id,
			recipient: recipientValue,
			updatingUser: email,
		};
		await updateInsuranceCancellation(submission);
	};

	const disabledCheckboxes = account.reviewStatus !== "needsReview";

	const getDate = (date) => DayJS(date).format("MM/DD/YYYY");
	return (
		<Card
			hasNoHeader
			style={{ marginTop: ".5rem" }}
			bodyClassName={"insurance-card"}
		>
			{isLoading && <Spinner />}
			{!isLoading && (
				<>
					<div className={"insurance-info"}>
						<div className={"insurance-account"}>
							<div className="insurance-account-icon-and-number">
								<Icon
									assistiveText={{ label: "Account" }}
									category="standard"
									name="account"
									size="small"
									containerClassName="insurance-account-icon"
								/>
								<p className="insurance-account-number">{account.id}</p>
							</div>
							<ReviewStatusBadge reviewStatus={account.reviewStatus} />
						</div>
					</div>
					<ul className="insurance-status-1">
						<li>
							<label>Transfer #</label>
							<p>{account.xferNumber}</p>
						</li>
						<li>
							<label>Customer</label>
							<p>{account.customerName}</p>
						</li>
						<li>
							<label>Policy</label>
							<p>{account.policyType}</p>
						</li>
					</ul>
					<ul className="insurance-status-2">
						<li>
							<label>Cancellation</label>
							<p>{getDate(account.repossessionDate)}</p>
						</li>
						<li>
							<label>Added</label>
							<p>{getDate(account.addedDate)}</p>
						</li>
						<li>
							<label>Loan #</label>
							<p>{account.loanNumber}</p>
						</li>
					</ul>
					<div className="insurance-decision">
						<RadioGroup
							labels={radioLabels}
							disabled={disabledCheckboxes}
							onChange={(event) => setRadio({ checked: event.target.value })}
						>
							{radioValues.map((value) => (
								<Radio
									disabled={disabledCheckboxes}
									className="insurance-decision-radio"
									key={value}
									id={account.id + value}
									labels={{ label: value }}
									value={value}
									checked={radio.checked === value}
									variant="base"
								/>
							))}
						</RadioGroup>
						<Button
							style={{
								alignSelf: "flex-end",
								width: "fit-content",
								maxWidth: "fit-content",
								height: "fit-content",
							}}
							disabled={disabledSubmit}
							responsive
							variant="brand"
							onClick={() => submitValue()}
						>
							{submitButtonValue}
						</Button>
					</div>
				</>
			)}
		</Card>
	);
};

InsuranceCard.propTypes = {
	onDocumentsButtonClick: PropTypes.func,
};
