import { useSaveCopyMutation, useUpdateMediaMutation } from "../endpoints";

export const useSaveImage = () => {
	const [saveCopy, { isLoading: isLoadingSaveCopy }] = useSaveCopyMutation({
		fixedCacheKey: "save-copy",
	});
	const [updateMedia, { isLoading: isLoadingOverwrite }] =
		useUpdateMediaMutation({ fixedCacheKey: "overwrite" });

	const isUpdating = isLoadingOverwrite || isLoadingSaveCopy;

	return { saveCopy, updateMedia, isUpdating };
};
