import { get } from "lodash";

import { enforce, each, test } from "vest";

import {
	BATHROOMS,
	BEDROOMS,
	FOUNDATION_TYPE_ID,
	HUD_NUMBER_1,
	HUD_NUMBER_2,
	HVAC_STATUS,
	HVAC_TYPE,
	LENGTH,
	MANUFACTURER,
	MODEL_NAME,
	ROOF_LOAD,
	ROOF_TYPE_ID,
	SECTIONS,
	SERIAL_NUMBER,
	SIDING_TYPE_ID,
	STOCK_NUMBER,
	THERMAL_ZONE,
	UTILITY_SYSTEM,
	WASTE_SYSTEM,
	WATER_SYSTEM,
	WIDTH,
	WIND_ZONE,
	YEAR,
} from "@/features/asset/constants";

import dayjs from "dayjs";

/*
        Enums validation Group includes:
            Wind Zone
            Hvac Type
            Hvac Status
            Thermal Zone
            Utility System
            Waste System
            Water System
    */
import {
	windZone,
	hvacType,
	hvacStatus,
	thermalZone,
	utilitySystem,
	wasteSystem,
	waterSystem,
	roofLoad,
} from "@/Utilities/enums";

// For readability I've seperated all of the fields from constants into groups of similar type
export const homeValidation = (data = {}) => {
	/*
        Enums validation Group includes:
            Wind Zone
            Hvac Type
            Hvac Status
            Thermal Zone
            Utility System
            Waste System
            Water System
    */
	test(WIND_ZONE.path, "Invalid Wind Zone", () => {
		enforce(get(data, WIND_ZONE.path)).oneOf(
			enforce.isNumber().isBetween(0, windZone.length - 1),
			enforce.isNull()
		);
	});
	test(HVAC_TYPE.path, "Invalid Hvac Type", () => {
		enforce(get(data, HVAC_TYPE.path)).oneOf(
			enforce.isNumber().isBetween(0, hvacType.length - 1),
			enforce.isNull()
		);
	});
	test(HVAC_STATUS.path, "Invalid Hvac Status", () => {
		enforce(get(data, HVAC_STATUS.path)).oneOf(
			enforce.isNumber().isBetween(0, hvacStatus.length - 1),
			enforce.isNull()
		);
	});
	test(THERMAL_ZONE.path, "Invalid Thermal Zone", () => {
		enforce(get(data, THERMAL_ZONE.path)).oneOf(
			enforce.isNumber().isBetween(0, thermalZone.length - 1),
			enforce.isNull()
		);
	});
	test(UTILITY_SYSTEM.path, "Invalid Utility System", () => {
		enforce(get(data, UTILITY_SYSTEM.path)).oneOf(
			enforce.isNumber().isBetween(0, utilitySystem.length - 1),
			enforce.isNull()
		);
	});
	test(WASTE_SYSTEM.path, "Invalid Waste System", () => {
		enforce(get(data, WASTE_SYSTEM.path)).oneOf(
			enforce.isNumber().isBetween(0, wasteSystem.length - 1),
			enforce.isNull()
		);
	});
	test(WATER_SYSTEM.path, "Invalid Water System", () => {
		enforce(get(data, WATER_SYSTEM.path)).oneOf(
			enforce.isNumber().isBetween(0, waterSystem.length - 1),
			enforce.isNull()
		);
	});
	test(ROOF_LOAD.path, "Invalid Roof Load", () => {
		enforce(get(data, ROOF_LOAD.path)).oneOf(
			enforce.isNumber().isBetween(0, roofLoad.length - 1),
			enforce.isNull()
		);
	});

	/*
        API Lookup Group includes
            Foundation ID
            Siding ID
            Roof ID
    */
	each(
		[FOUNDATION_TYPE_ID.path, SIDING_TYPE_ID.path, ROOF_TYPE_ID.path],
		(field) => {
			test(field, "Invalid Type", () => {
				enforce(get(data, field)).oneOf(enforce.isUUID(), enforce.isNull());
			});
		}
	);

	/*
        Number Inputs Group includes
        Bedrooms
        Bathrooms
        Roof Load
        Sections
        Length
        Width

    */
	each([BEDROOMS.path, BATHROOMS.path, SECTIONS.path], (field) => {
		test(field, "Must be between 0 and 9", () => {
			enforce(get(data, field)).isNumber().isBetween(0, 9);
		});
	});
	each([LENGTH.path, WIDTH.path], (field) => {
		test(field, "Must be between 0 and 99", () => {
			enforce(get(data, field)).isNumber().isBetween(0, 99);
		});
	});
	/*
        String Inputs group includes
            Hud Number 1
            Hud Number 2
            Manufacturer
            Model Name
            Serial Number
            Stock Number
            Year
    */
	each(
		[MANUFACTURER.path, MODEL_NAME.path, SERIAL_NUMBER.path, STOCK_NUMBER.path],
		(field) => {
			test(field, "Must be a valid String", () => {
				enforce(get(data, field)).oneOf(enforce.isString(), enforce.isNull());
			});
		}
	);
	/*
        Special Business Logic Fields includes
            Hud Number 1 
            Hud Number 2
            Year
    */

	each([HUD_NUMBER_1.path, HUD_NUMBER_2.path], (field) => {
		test(field, "Must be 10 characters e.g. TEN 123456", () => {
			enforce(get(data, field)).anyOf(
				enforce.isEmpty(),
				enforce.isString().lengthEquals(10),
				enforce.isNull()
			);
		});
	});
	test(YEAR.path, "Must be a valid year (1900-Current)", () => {
		enforce(get(data, YEAR.path)).anyOf(
			enforce.isNumber().isBetween(1900, dayjs().year()),
			enforce.isNumber().equals(0),
			enforce.isNull()
		);
	});
};
