import { Button } from "@salesforce/design-system-react";
import { CreateWorksheetButton } from "./components/CreateWorksheetButton";
import { PricingWorksheetForm } from "./components/PricingWorksheetForm";
import styles from "./PricingWorksheet.module.scss";
import { usePricingWorksheet } from "./usePricingWorksheet";
import { WorksheetTitle } from "./components/WorksheetTitle";
import { useSearchParams } from "react-router-dom";

export const PricingWorksheet = ({ assetId }: { assetId: string }) => {
	const {
		pricingWorksheets,
		approvalStatus,
		currentWorksheet,
		isSuccess,
		currentIndex,
		onSubmit,
		isFetching,
		accountNumber,
		goToCurrentWorksheet,
	} = usePricingWorksheet(assetId);

	const [isOpenHistory, setIsOpenHistory] = usePricingWorksheetHistory();

	return (
		isSuccess && (
			<div className={styles.formContainer}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						padding: ".25rem",
					}}
				>
					{pricingWorksheets?.length > 1 && (
						<>
							<Button
								variant="base"
								onClick={setIsOpenHistory}
								label={isOpenHistory ? "Close" : "History"}
								iconName={isOpenHistory ? "close" : "skip_back"}
								iconCategory="utility"
								iconPosition="left"
							/>
						</>
					)}
				</div>
				{!isOpenHistory && (
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							paddingLeft: ".25rem",
						}}
					>
						<div>
							{approvalStatus === "Approved" && currentIndex === 0 && (
								<CreateWorksheetButton assetId={assetId} />
							)}
							{currentIndex !== 0 && (
								<Button
									variant="base"
									onClick={goToCurrentWorksheet}
									label={"Go to Current Worksheet"}
									iconName={"chevronleft"}
									iconCategory="utility"
									iconPosition="left"
								/>
							)}
						</div>

						<div>
							<>
								<a
									className="slds-button slds-button_outline-brand"
									href={`https://www.cmhlink.net/vmf/remarketing/fieldmgr/AccountManager.cfm?account=${accountNumber}&section=rw`}
									target="_blank"
									rel="noreferrer noopener"
								>
									Open in LINK
								</a>
								<a
									className="slds-button slds-button_brand"
									href="https://zillow.com"
									target="_blank"
									rel="noreferrer noopener"
								>
									Visit Zillow
								</a>
								<a
									className="slds-button slds-button_brand"
									href="https://realtor.com"
									target="_blank"
									rel="noreferrer noopener"
								>
									Visit Realtor.com
								</a>
								<a
									className="slds-button slds-button_brand"
									href="https://nada.org"
									target="_blank"
									rel="noreferrer noopener"
								>
									Visit NADA
								</a>
							</>
						</div>
					</div>
				)}
				{isOpenHistory ? (
					<>
						{pricingWorksheets.map((worksheet, index) => {
							return (
								<WorksheetTitle
									data={worksheet}
									homeId={assetId}
									index={index}
									key={`worksheet-${worksheet?.id ?? "new"}`}
								/>
							);
						})}
					</>
				) : (
					<PricingWorksheetForm
						data={currentWorksheet}
						homeId={assetId}
						onSubmit={onSubmit}
						isFetching={isFetching}
					/>
				)}
			</div>
		)
	);
};

function usePricingWorksheetHistory() {
	const [params, setParams] = useSearchParams();

	const isOpenHistory = !!params.get("history");

	function openHistory() {
		const newParams = {
			tab: "marketingPlan",
			...(!isOpenHistory && {
				history: "true",
			}),
		};
		setParams(newParams);
	}

	return [isOpenHistory, openHistory];
}
