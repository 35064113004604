import { gatewayApi } from "@/ReduxToolkit";
import { concatErrorCache } from "@/ReduxToolkit/GatewayApi";
import { getFormData } from "@/features/media-manager/api/endpoints/utils";
import { addImagingState, removeImagingState } from "./ImagingSlice";

const imagingEndpoints = (builder) => {
	return {
		getImagingByAccountNumber: builder.query({
			method: "GET",
			query: (accountNumber) =>
				`api/Imaging/documents/account/${accountNumber}`,
			providesTags: (result, error, id) => {
				if (error) return concatErrorCache([{ type: "Imaging", id }], error);
				return [{ type: "Imaging", id }];
			},
			transformResponse: (response) => {
				return response.sort((a, b) =>
					b.dateStored.localeCompare(a.dateStored)
				);
			},
			onCacheEntryAdded: async (arg, { dispatch, cacheEntryRemoved }) => {
				dispatch(addImagingState(arg));
				cacheEntryRemoved.then(() => {
					dispatch(removeImagingState(arg));
				});
			}
		}),
		uploadImagingDocument: builder.mutation({
			query: (body) => ({
				url: "api/Imaging/documents",
				method: "POST",
				body: getFormData(body)
			}),
			invalidatesTags: (_result, _err, body) => {
				return [{ type: "Imaging", id: body.accountNumber }];
			}
		}),
		getDocumentSubtypes: builder.query({
			method: "GET",
			query: () => "api/LookupData/DocumentSubtypes"
		})
	};
};

gatewayApi.enhanceEndpoints({
	addTagTypes: ["Imaging"]
});

gatewayApi.injectEndpoints({
	endpoints: imagingEndpoints,
	overrideExisting: true
});

export const {
	useGetImagingByAccountNumberQuery,
	useUploadImagingDocumentMutation,
	useGetDocumentSubtypesQuery,
	endpoints: { getImagingByAccountNumber }
} = gatewayApi;
