import dayjs from "dayjs";
import {
	ACCOUNT_NUMBER,
	ADDRESS,
	ASSET_MANAGER_ID,
	ASSIGNED_DATE,
	BALANCE,
	CITY,
	CUSTOMER_NAME,
	LENGTH,
	PRICING_WORKSHEET_APPROVAL_STATUS,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	STOCK_NUMBER,
	WIDTH,
	YEAR,
	ZIP,
} from "@/features/asset/constants";
import {
	AccountDataTableCell,
	AssetManager,
	MarketingPlanCell,
	StatusCell,
} from "../TableValues/TableValues";

export const columns = {
	[ACCOUNT_NUMBER.key]: {
		component: AccountDataTableCell,
		width: "100px",
		label: "Account",
		path: ACCOUNT_NUMBER.path,
		searchable: true,
	},
	[PRICING_WORKSHEET_APPROVAL_STATUS.key]: {
		component: MarketingPlanCell,
		width: "100px",
		label: "Marketing Plan",
		path: PRICING_WORKSHEET_APPROVAL_STATUS.path,
		searchable: false,
	},
	[CUSTOMER_NAME.key]: {
		label: "Last Name",
		width: "95px",
		path: CUSTOMER_NAME.path,
		searchable: true,
	},
	[REMARKETING_STATUS_TYPE_ID.key]: {
		label: "Status",
		sortable: false,
		component: StatusCell,
		width: "120px",
		path: REMARKETING_STATUS_TYPE_ID.path,
		props: {
			searchable: true,
		},
	},
	[BALANCE.key]: {
		label: "Balance",
		path: BALANCE.path,
		width: "80px",
		searchable: false,
	},
	[ASSIGNED_DATE.key]: {
		label: "Assigned",
		formatter: (value) => dayjs(value).format("MM/DD/YYYY"),
		width: "80px",
		path: ASSIGNED_DATE.path,
		searchable: false,
	},
	[ADDRESS.key]: {
		label: "Address",
		width: "150px",
		path: ADDRESS.path,
		searchable: true,
	},
	[CITY.key]: {
		label: "City",
		width: "80px",
		path: CITY.path,
		searchable: true,
	},
	[STATE.key]: {
		label: "State",
		path: STATE.path,
		title: "state",
	},
	[ZIP.key]: {
		label: "Zip",
		path: ZIP.path,
		width: "60px",
		searchable: true,
	},
	[STOCK_NUMBER.key]: {
		label: "Stock Number",
		width: "90px",
		path: STOCK_NUMBER.path,
		searchable: true,
	},
	[YEAR.key]: {
		label: "Year",
		path: YEAR.path,
		searchable: true,
	},
	[LENGTH.key]: {
		label: "Length",
		path: LENGTH.path,
		width: "60px",
		searchable: false,
	},
	[WIDTH.key]: {
		label: "Width",
		path: WIDTH.path,
		searchable: false,
	},
	[ASSET_MANAGER_ID.key]: {
		width: "120px",
		label: "Responsible AM",
		path: ASSET_MANAGER_ID.path,
		component: AssetManager,
		searchable: false,
	},
};
