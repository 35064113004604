import { useCallback, useReducer } from "react";

function sortReducer(state, action) {
	return { ...state, ...action };
}

export function useSort({ column, direction }, reducer = sortReducer) {
	const [state, dispatch] = useReducer(reducer, {
		column: column || "accountNumber",
		direction: direction || "desc",
	});

	const sort = useCallback(
		(data) => {
			const sorted = [...data].sort((a, b) => {
				let val = 0;

				if (a[state.column] > b[state.column]) {
					val = 1;
				}
				if (a[state.column] < b[state.column]) {
					val = -1;
				}

				if (state.direction === "desc") {
					val *= -1;
				}

				return val;
			});
			return sorted;
		},
		[state.column, state.direction]
	);

	return { sort, state, dispatch };
}
