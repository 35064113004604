import { createSelector } from "@reduxjs/toolkit";
import { getNotes, getVantageNotes } from "./endpoints";
import { convertOperation } from "@/Utilities/filtering";
import dayjs from "dayjs";
import { RootState } from "@/ReduxToolkit/store";

const selectDocumentationById = (id: string) => (state: RootState) => state.documentation[id]

// Function to select result from the query
const selectDocumentationResult = (accountId: string) => getNotes.select(accountId);

const selectVantageResult = (homeId: string) => getVantageNotes.select(homeId);


// Create a selector from the selectResult
const selectDocumentationFromResult = (accountId: string, homeId: string) =>
	createSelector(
		selectDocumentationResult(accountId),
		selectVantageResult(homeId),
		(documentation, vantageDocumentation) => {
			const notes = (documentation?.data ?? []).concat(vantageDocumentation?.data ?? [])
			return notes
		}
	);

// Selector used in the page header to get the value of the source
export const selectSource = (id: string) =>
	createSelector(
		selectDocumentationById(id),
		(result) => result?.sourceId ?? []
	);

export const selectSearch = (id: string) =>
	createSelector(selectDocumentationById(id), (result) => result?.search ?? "");

export const selectFilters = (id: string) =>
	createSelector(
		selectDocumentationById(id),
		(result) => result?.filters ?? []
	);

export const selectDocumentation = (accountId: string, homeId: string) =>
	createSelector(
		selectDocumentationFromResult(accountId, homeId),
		selectSearch(accountId),
		selectSource(accountId),
		selectFilters(accountId),
		(notes, search, sources, filters) =>
			notes
				.filter(
					(item) =>
						item.createdBy?.toLowerCase().includes(search.toLowerCase()) ||
						item.content?.toLowerCase().includes(search.toLowerCase())
				)
				.filter((item) => {
					if (sources.length === 0) {
						return true;
					} else {
						return sources.includes(item.sourceId);
					}
				})
				.filter((item) => {
					let fitsCriteria = true;
					filters.forEach((filter) => {
						const op = convertOperation(filter.operation);
						// Convert to dayjs date (no time), filter.value is using ISO string, so add 1 day after conversion
						if (
							op && !op(
									dayjs(item.createdDate).startOf("day").toISOString(),
									dayjs(filter.value).startOf("day").add(1, "day").toISOString()
								)
						) {
							fitsCriteria = false;
						}
					});
					return fitsCriteria;
				})
				.sort(
					(first, second) => {
						const firstDate = dayjs(first.createdDate);
						const secondDate = dayjs(second.createdDate);
						return secondDate.isBefore(firstDate) ? -1 : 1
					}
						
				)
	);
