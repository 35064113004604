import React, { useState } from "react";
import { DocumentationHeader } from "./components/documentation-header/DocumentationHeader";
import classes from "./Documentation.module.scss";
import { DocumentationForm } from "./components/documentation-form/DocumentationForm";
import { useDocumentation } from "./api/useDocumentation";
import { DocumentationCard } from "./components/documentation-card/DocumentationCard";
import { useTabs } from "@/hooks/useTabs";
import { useIsMobile } from "@/Responsive";
import { ErrorContainer } from "../errors/ErrorContainer";
import Spinner from "@/Components/UI/Spinner/Spinner";
import useVirtual from "react-cool-virtual";
import { FilterPanelContext } from "../media-manager/hooks/useFilterPanel";
import { FilterPanelLayout } from "../media-manager/components/layouts";
import { DocumentationPanelContainer } from "./components/documentation-filter-panel/FilterPanel";
import { Note } from "./types";
import { useAssetId } from "../asset/components/AssetIdProvider";

export const Documentation = ({ close = () => {} }: { close: Function }) => {
	const { accountId, assetId } = useAssetId();
	const { isSuccess, isError, notes, total } = useDocumentation(
		accountId,
		assetId
	);
	const [addingNote, setAddingNote] = useState(false);
	const { currentTab } = useTabs();
	const isMobile = useIsMobile();
	const sideView = currentTab !== "notes" && !isMobile;

	return (
		<FilterPanelContext>
			<FilterPanelLayout
				panel={<DocumentationPanelContainer accountId={accountId} />}
			>
				<div className={classes.Container}>
					<DocumentationHeader
						close={close}
						accountId={accountId}
						info={total ? `Showing ${notes.length} / ${total}` : ""}
						onAddNote={() => setAddingNote(!addingNote)}
					/>
					{addingNote && (
						<DocumentationForm
							id={accountId}
							onCancel={() => setAddingNote(false)}
						/>
					)}
					{isError && <ErrorContainer error={null} />}
					{!isSuccess && !isError && <Spinner />}
					{isSuccess && <VirtualList notes={notes} sideView={sideView} />}
				</div>
			</FilterPanelLayout>
		</FilterPanelContext>
	);
};

const VirtualList = ({
	notes,
	sideView,
}: {
	notes: Note[];
	sideView: boolean;
}) => {
	const { outerRef, innerRef, items } = useVirtual({
		itemCount: notes.length,
		itemSize: 100,
	});

	if (notes.length > 0) {
		return (
			<div
				className={sideView ? classes.cardsSideView : classes.cards}
				style={{ overflow: "auto" }}
				// @ts-ignore
				ref={outerRef}
			>
				<div
					//@ts-ignore
					ref={innerRef}
				>
					{items.map(({ index, measureRef }) => (
						<div key={`documentation-card-${index}`} ref={measureRef}>
							<DocumentationCard
								key={`documentation-card-index`}
								sideView={sideView}
								note={notes[index]}
							/>
						</div>
					))}
				</div>
			</div>
		);
	}
	return <div></div>;
};
