// sections
export const GENERAL = "general";
export const OVERVIEW = "overview";
export const LIVING_ROOM = "livingRoom";
export const EXTERIOR = "exterior";
export const SERVICES = "services";
export const KITCHEN = "kitchen";
export const BATHROOM = "bathrooms";
export const BEDROOM = "bedrooms";
export const INTERIOR = "interior";
// Interior Constants
export const ONE_INCH_BLINDS = "oneInchBlinds";
export const BATH_FAUCET = "bathFaucet";
export const BATHROOM_SINK = "bathroomSink";
export const CARPET = "carpetEntireHome";
export const CARPET_ROOM = "carpetRoom";
export const CARPET_PER_YARD = "carpetPerYard";
export const CEILING_FAN = "ceilingFan";
export const DISHWASHER = "dishwasher";
export const EXTERMINATE = "exterminate";
export const FLOOR_REGISTER = "floorRegister";
export const FREE_FORM_DOCUMENTATION = "freeFormDocumentation";
export const HOT_WATER_HEATER = "hotWaterHeater";
export const INTERIOR_DOOR = "interiorDoor";
export const INTERIOR_TRASH_OUT = "interiorTrashOut";
export const INTERIOR_TRIM_WORK = "interiorTrimWork";
export const KEY_LOCATION = "keyLocation";
export const KILZ_FLOOR_PER_ROOM = "kilzFloorPerRoom";
export const KILZ_FLOOR_WHOLE_HOUSE = "kilsFloorWholeHouse";
export const KITCHEN_CABINET_REPAIR = "kitchenCabinetRepair";
export const KITCHEN_FAUCET = "kitchenFaucet";
export const KITCHEN_SINK = "kitchenSink";
export const LAMINATE_YARDS = "lvpEntireHome";
export const LAMP_AND_TIMER = "lampAndTimer";
export const LOCK_BOX_CODE = "lockBoxCode";
export const LIGHT_FIXTURE = "lightFixture";
export const MIRROR = "mirror";
export const OUTLET_LIGHT_REPAIR = "outletLightRepair";
export const OVER_STOVE_MICROWAVE = "overStoveMicrowave";
export const PAINT_ENTIRE_HOME = "paintEntireHome";
export const PAINT_HOME_MODIFIER = "paintHomeModifier";
export const PAINT_WALLS = "paintWalls";
export const PAINT_DOOR = "paintDoor";
export const PAINT_CEILING = "paintCeiling";
export const RANGE_HOOD = "rangeHood";
export const REFRIGERATOR = "refrigerator";
export const REPLACE_FLOOR_DECKING = "replaceFloorDecking";
export const REPLACE_FURNACE = "replaceFurnace";
export const REPLACE_TUB_OR_SHOWER = "replaceTubOrShower";
export const REPLACE_WALL_BOARD = "replaceWallBoard";
export const SALES_CLEAN = "salesClean";
export const SALES_CLEAN_BASE_PRICE = "salesCleanBasePrice";
export const SALES_CLEAN_MODIFIER = "salesCleanModifier";
export const SHAMPOO_CARPET_ONLY = "shampooCarpetOnly";
export const SHOWER_HEAD = "showerHead";
export const SMOKE_ALARM = "smokeAlarm";
export const STOVE_RANGE = "stoveRange";
export const THERMOSTAT = "thermostat";
export const TOILET = "toilet";
export const TOILET_SEAT = "toiletSeat";
export const TOWEL_HOLDER = "towelHolder";
export const TUB_PAINT_OR_EPOXY = "tubPaintOrEpoxy";
export const VINYL_YARDS = "linoleumVinyl";
export const WINDOW = "window";
export const WINTERIZATION = "winterization";

// Exterior Constants
export const AIR_CONDITIONER = "airConditioner";
export const HEAT_PUMP = "heatPump";
export const BUSHHOG = "bushHog";
export const DECK_TEN_BY_TEN = "deckTenByTen";
export const DECK_FIVE_BY_FIVE = "deckFiveByFive";
export const DECK_REPAIR = "deckRepair";
export const DOORS_AND_SCREEN_DOOR = "doorsAndScreenDoor";
export const ELECTRIC_METER = "electricMeter";
export const EXTERIOR_LIGHTS = "exteriorLights";
export const EXTERIOR_TRASH_OUT = "exteriorTrashOut";
export const GRADING_GRAVEL_WORK = "gradingGravelWork";
export const GRAVEL = "gravel";
export const HITCH_UNDER_UNIT = "isHitchUnderUnit";
export const LANDSCAPE = "landscape";
export const LOCK_CHANGE = "lockChange";
export const MOW_AND_TRIM_LESS_ONE_ACRE = "mowAndTrimLessOneAcre";
export const MOW_AND_TRIM_MORE_ONE_ACRE = "mowAndTrimMoreOneAcre";
export const MOW_AND_TRIM_INITIAL = "mowAndTrimInitial";
export const PAINT_EXTERIOR = "paintExterior";
export const PAINT_EXTERIOR_MODIFIER = "paintExteriorModifier";
export const PAINT_SHUTTERS = "paintShutters";
export const PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR =
	"paintTarRoofVentsMinorRoofRepair";
export const ROOF_SHINGLE = "roofShingle";
export const ROOF_SHINGLE_MODIFIER = "roofShingleModifier";
export const SAND_STAIN_DECK = "sandStainDeck";
export const SERVICE_HEAT_AND_COOLING_UNIT = "serviceHeatAndCoolingUnit";
export const SKIRTING = "skirting";
export const SKIRTING_MODIFIER = "skirtingModifier";
export const STEPPING_STONES = "steppingStones";
export const WINDOW_SCREEN = "windowScreen";
export const FHA_READY = "fhaReady";
// Services
export const PRESSURE_WASH = "pressureWash";
export const PRESSURE_WASH_BASE_PRICE = "pressureWashBasePrice";
export const PRESSURE_WASH_MODIFIER = "pressureWashModifier";
export const SERIAL_NUMBER_VERIFIED = "serialNumberVerified";
export const HUD_NUMBER_VERIFIED = "hudNumberVerified";
export const DOOR_STRAP = "hasDoorStrap";
export const LOCK_TYPE = "lockType";
export const SKIP_REASON = "skipReason";
export const SKIP_CONDITION_REPORT = "skipConditionReport";

export const pricingIndex = {
	//interior
	[ONE_INCH_BLINDS]: 15,
	[BATH_FAUCET]: 30,
	[BATHROOM_SINK]: 65,
	[CARPET_PER_YARD]: 14,
	[CARPET_ROOM]: 175,
	[CEILING_FAN]: 125,
	[DISHWASHER]: 400,
	[EXTERMINATE]: 250,
	[FLOOR_REGISTER]: 12,
	[HOT_WATER_HEATER]: 750,
	[INTERIOR_DOOR]: 90,
	[INTERIOR_TRASH_OUT]: 500,
	[INTERIOR_TRIM_WORK]: 100,
	[KILZ_FLOOR_PER_ROOM]: 75,
	[KILZ_FLOOR_WHOLE_HOUSE]: 300,
	[KITCHEN_CABINET_REPAIR]: 75,
	[KITCHEN_FAUCET]: 90,
	[KITCHEN_SINK]: 175,
	[LAMINATE_YARDS]: 20,
	[LAMP_AND_TIMER]: 50,
	[LIGHT_FIXTURE]: 30,
	[MIRROR]: 65,
	[OUTLET_LIGHT_REPAIR]: 10,
	[OVER_STOVE_MICROWAVE]: 300,
	[PAINT_HOME_MODIFIER]: 1.65,
	[PAINT_WALLS]: 200,
	[PAINT_DOOR]: 25,
	[PAINT_CEILING]: 100,
	[RANGE_HOOD]: 105,
	[REFRIGERATOR]: 650,
	[REPLACE_FLOOR_DECKING]: 80,
	[REPLACE_FURNACE]: 750,
	[REPLACE_TUB_OR_SHOWER]: 700,
	[REPLACE_WALL_BOARD]: 30,
	// [SALES_CLEAN]: 250 + min([max([width - 18, 0]), 1]) * 100,
	[SALES_CLEAN_BASE_PRICE]: 250,
	[SALES_CLEAN_MODIFIER]: 100,
	[SHAMPOO_CARPET_ONLY]: 150,
	[SHOWER_HEAD]: 25,
	[SMOKE_ALARM]: 30,
	[STOVE_RANGE]: 500,
	[THERMOSTAT]: 100,
	[TOILET]: 250,
	[TOILET_SEAT]: 30,
	[TOWEL_HOLDER]: 20,
	[TUB_PAINT_OR_EPOXY]: 400,
	[VINYL_YARDS]: 14,
	[WINDOW]: 250,
	[WINTERIZATION]: 125,

	// Exterior
	// Air Conditioner has special pricing
	// airConditioner: 2225,
	[BUSHHOG]: 150,
	[DECK_TEN_BY_TEN]: 1200,
	[DECK_FIVE_BY_FIVE]: 600,
	[DECK_REPAIR]: 100,
	[DOORS_AND_SCREEN_DOOR]: 675,
	[ELECTRIC_METER]: 1,
	[EXTERIOR_LIGHTS]: 60,
	[EXTERIOR_TRASH_OUT]: 250,
	[GRADING_GRAVEL_WORK]: 500,
	[GRAVEL]: 400,
	[HITCH_UNDER_UNIT]: 1,
	[LANDSCAPE]: 10,
	[LOCK_CHANGE]: 50,
	[MOW_AND_TRIM_LESS_ONE_ACRE]: 60,
	[MOW_AND_TRIM_MORE_ONE_ACRE]: 75,
	[MOW_AND_TRIM_INITIAL]: 150,
	//[PAINT_EXTERIOR]: Math.ceil(width * length * 1.15),
	[PAINT_EXTERIOR_MODIFIER]: 1.15,
	[PAINT_SHUTTERS]: 30,
	[PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR]: 20,
	// [ROOF_SHINGLE]: Math.ceil((width * length * 1.11 * 1.1) / 100) * 180,
	[ROOF_SHINGLE_MODIFIER]: 180,
	[SAND_STAIN_DECK]: 400,
	[SERVICE_HEAT_AND_COOLING_UNIT]: 250,
	//[SKIRTING]: (width + width + length + length) * 7,
	[SKIRTING_MODIFIER]: 7,
	[STEPPING_STONES]: 7,
	[WINDOW_SCREEN]: 20,
	//services
	//[PRESSURE_WASH]: 250 + min([max([width - 18, 0]), 1]) * 100,
	[PRESSURE_WASH_BASE_PRICE]: 300,
	[PRESSURE_WASH_MODIFIER]: 100,
};

export const labelsIndex = {
	//interior
	[ONE_INCH_BLINDS]: "1 Inch Mini Blinds",
	[BATH_FAUCET]: "Bath Faucet",
	[BATHROOM_SINK]: "Bathroom Sink",
	[CARPET]: "Carpet - Entire Home",
	[CARPET_ROOM]: "Carpet - Room",
	[CEILING_FAN]: "Ceiling Fan",
	[DISHWASHER]: "Dishwasher",
	[EXTERMINATE]: "Exterminate",
	[FLOOR_REGISTER]: "Floor Register",
	[HOT_WATER_HEATER]: "Hot Water Heater",
	[INTERIOR_DOOR]: "Interior Door",
	[INTERIOR_TRASH_OUT]: "Interior Trash Out - Entire Home",
	[INTERIOR_TRIM_WORK]: "Interior Trim Work",
	[KILZ_FLOOR_PER_ROOM]: "Kilz Room",
	[KILZ_FLOOR_WHOLE_HOUSE]: "Kilz - Entire Home",
	[KITCHEN_CABINET_REPAIR]: "Kitchen Cabinet Repair",
	[KITCHEN_FAUCET]: "Kitchen Faucet",
	[KITCHEN_SINK]: "Kitchen Sink",
	[LAMINATE_YARDS]: "LVP - Entire Home",
	[LAMP_AND_TIMER]: "Lamp and Timer",
	[LIGHT_FIXTURE]: "Light Fixture",
	[MIRROR]: "Mirror",
	[OUTLET_LIGHT_REPAIR]: "Outlet Light Repair",
	[OVER_STOVE_MICROWAVE]: "Over the Stove Microwave",
	[PAINT_ENTIRE_HOME]: "Paint - Entire Home",
	[PAINT_WALLS]: "Paint Walls - Room",
	[PAINT_DOOR]: "Paint Door",
	[PAINT_CEILING]: "Paint Ceiling - Room",
	[RANGE_HOOD]: "Range Hood",
	[REFRIGERATOR]: "Refrigerator",
	[REPLACE_FLOOR_DECKING]: "Replace Floor Decking - Room",
	[REPLACE_FURNACE]: "Replace Furnace",
	[REPLACE_TUB_OR_SHOWER]: "Replace Tub or Shower",
	[REPLACE_WALL_BOARD]: "Replace Wall Boards",
	[SALES_CLEAN]: "Sales Clean - Entire Home",
	[SHAMPOO_CARPET_ONLY]: "Shampoo Carpet - Entire Home",
	[SHOWER_HEAD]: "Shower Heads",
	[SMOKE_ALARM]: "Smoke Alarms",
	[STOVE_RANGE]: "Stove Range",
	[THERMOSTAT]: "Thermostat",
	[TOILET]: "Toilet",
	[TOILET_SEAT]: "Toilet Seat",
	[TOWEL_HOLDER]: "Towel Holder",
	[TUB_PAINT_OR_EPOXY]: "Tub (Paint or Epoxy)",
	[VINYL_YARDS]: "Linoleum/Vinyl - Entire Home",
	[WINDOW]: "Window",
	[WINTERIZATION]: "Winterization",

	// Exterior
	// Air Conditioner has special pricing
	// airConditioner: 2225,
	[AIR_CONDITIONER]: "Air Conditioner",
	[HEAT_PUMP]: "Heat Pump",
	[BUSHHOG]: "Bush Hog",
	[DECK_TEN_BY_TEN]: "Deck 10x10",
	[DECK_FIVE_BY_FIVE]: "Deck 5x5",
	[DECK_REPAIR]: "Deck Repair",
	[DOORS_AND_SCREEN_DOOR]: "Doors and Screen Door",
	[ELECTRIC_METER]: "Electric Meter",
	[EXTERIOR_LIGHTS]: "Exterior Lights",
	[EXTERIOR_TRASH_OUT]: "Exterior Trash Out",
	[GRADING_GRAVEL_WORK]: "Grading Gravel Work",
	[GRAVEL]: "Gravel",
	[HITCH_UNDER_UNIT]: "Hitch Under Unit",
	[LANDSCAPE]: "Landscape",
	[LOCK_CHANGE]: "Lock Change",
	[MOW_AND_TRIM_LESS_ONE_ACRE]: "Mow and Trim less than 1 Acre",
	[MOW_AND_TRIM_MORE_ONE_ACRE]: "Mow and Trim more than 1 Acre",
	[MOW_AND_TRIM_INITIAL]: "Initial Mow and Trim",
	[PAINT_EXTERIOR]: "Paint Exterior - Entire Home",
	[PAINT_SHUTTERS]: "Paint Shutters",
	[PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR]:
		"Paint/Tar Roof Vents and Minor Roof Repair",
	[ROOF_SHINGLE]: "Roof, Shingle",
	[SAND_STAIN_DECK]: "Sand and Stain Deck",
	[SERVICE_HEAT_AND_COOLING_UNIT]: "Service Heat and Cooling Unit",
	[SKIRTING]: "Skirting",
	[STEPPING_STONES]: "Stepping Stones",
	[WINDOW_SCREEN]: "Window Screen",
	[FHA_READY]: "FHA Ready",
	//services
	[PRESSURE_WASH]: "Pressure Wash - Entire Home",
	[SERIAL_NUMBER_VERIFIED]: "Serial Number Verified",
	[HUD_NUMBER_VERIFIED]: "HUD Number Verified",

	[DOOR_STRAP]: "Door Strap",
	[LOCK_TYPE]: "Locks",
	[KEY_LOCATION]: "Key Location",
	[LOCK_BOX_CODE]: "Lock Box Code",
	[FREE_FORM_DOCUMENTATION]: "Free Form Documentation",
	[SKIP_REASON]: "Skip Reason",
	[SKIP_CONDITION_REPORT]: "Skip Condition Report",
};

export const NumberFields = [
	ONE_INCH_BLINDS,
	BATH_FAUCET,
	BATHROOM_SINK,
	CARPET_PER_YARD,
	DECK_REPAIR,
	DOORS_AND_SCREEN_DOOR,
	EXTERIOR_LIGHTS,
	FLOOR_REGISTER,
	GRAVEL,
	INTERIOR_DOOR,
	KITCHEN_CABINET_REPAIR,
	KILZ_FLOOR_PER_ROOM,
	LANDSCAPE,
	LIGHT_FIXTURE,
	LOCK_CHANGE,
	MOW_AND_TRIM_LESS_ONE_ACRE,
	MOW_AND_TRIM_MORE_ONE_ACRE,
	OUTLET_LIGHT_REPAIR,
	PAINT_SHUTTERS,
	PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR,
	PAINT_DOOR,
	REPLACE_FLOOR_DECKING,
	REPLACE_WALL_BOARD,
	SMOKE_ALARM,
	STEPPING_STONES,
	TOWEL_HOLDER,
	WINDOW,
	WINDOW_SCREEN,
];
