import { ServiceWorkerToast } from "@/features/offline-use/components/ServiceWorkerToast/ServiceWorkerToast";
import { useDispatch, useSelector } from "react-redux";
import { updateServiceWorker } from "@/ReduxToolkit/offlineAssetSlice";

export const ServiceWorkerProvider = ({ children }) => {
	const isUpdateAvailable = useSelector(
		(state) => state.offlineAssets.isUpdateAvailable
	);
	const dispatch = useDispatch();
	const closeToast = () => {
		dispatch(updateServiceWorker());
		window.location.reload();
	};

	return (
		<>
			{isUpdateAvailable && <ServiceWorkerToast closeToast={closeToast} />}
			{children}
		</>
	);
};
