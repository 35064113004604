import React from "react";
import { useTripPlanning } from "../../api/useTripPlanning";
import { columns } from "../columns";

import { DataTable, DataTableColumn } from "@salesforce/design-system-react";
import { Cell } from "@/features/asset-list/components/TableValues/TableValues";
import { get } from "lodash";
import { usePageAssetList } from "@/features/asset-list/api/usePageAssetList";
import { useSortAssetList } from "@/features/asset-list/api/useSortAssetList";
import { LATITUDE, LONGITUDE } from "@/features/asset/constants";

export const LocationsTable = () => {
	const { data } = useTripPlanning();
	const { pagination, trigger: triggerPage, status } = usePageAssetList();
	const { direction, property, trigger: triggerSort } = useSortAssetList();
	const totalAssets = pagination?.totalItems;

	const assetList = data?.map((item, index) => {
		return {
			id: item.id,
			accountId: item.accountId,
			latitude: get(item, LATITUDE.path),
			longitude: get(item, LONGITUDE.path),
			index: index,
			...Object.fromEntries(
				Object.keys(columns).map((key) => {
					const title = key;
					const value = columns[key].formatter
						? columns[key].formatter(get(item, columns[key].path))
						: get(item, columns[key].path);

					return [title, value];
				})
			),
		};
	});

	const handleSort = (sortColumn) => {
		const { property: sortProperty, sortDirection } = sortColumn;
		triggerSort({
			property: sortProperty,
			direction: sortDirection,
		});
	};

	const handleLoadMore = () => {
		if (status !== "pending") {
			const currentPage = pagination.pageNumber;
			triggerPage(currentPage + 1);
		}
	};

	const configCols = Object.keys(columns);
	return (
		<div style={{ height: "60vh", maxHeight: "60vh" }}>
			<DataTable
				assistiveText={{
					columnSort: "sort this column",
					columnSortedAscending: "asc",
					columnSortedDescending: "desc",
					selectAllRows: "Select all rows",
					selectRow: "Select this row",
				}}
				style={{
					fontSize: ".8em",
					overflow: "auto",
				}}
				items={assetList}
				id="DataTableExample-FixedHeaders"
				onSort={handleSort}
				fixedHeader
				fixedLayout
				columnBordered
				onLoadMore={() => handleLoadMore()}
				hasMore={data.length < totalAssets}
			>
				{configCols.map((column) => {
					return (
						<DataTableColumn
							key={column}
							width={columns[column]?.width ? columns[column].width : "50px"}
							label={columns[column]?.label ? columns[column].label : column}
							property={column}
							sortable={columns[column]?.sortable ?? true}
							isSorted={property === column}
							sortDirection={direction}
						>
							{columns[column]?.component ? (
								React.createElement(columns[column].component, {
									...{ anchor: column.id },
									...columns[column].props,
									title: column,
								})
							) : (
								<Cell
									title={columns[column]?.label}
									searchable={columns[column].searchable}
								/>
							)}
						</DataTableColumn>
					);
				})}
			</DataTable>
		</div>
	);
};
