import React from "react";
import { Input } from "@salesforce/design-system-react";
import { useDispatch, useSelector } from "react-redux";
import {
	setFilterValues,
	selectInsuranceQueueFilters,
} from "../store/insuranceQueueSlice";
export const InsuranceFilters = () => {
	const dispatch = useDispatch();
	const filterChangedHandler = (attribute, value) => {
		dispatch(setFilterValues({ attribute, value }));
	};
	const filterValues = useSelector(selectInsuranceQueueFilters);
	return (
		<div className="slds-grid slds-wrap slds-grid_vertical-align-center">
			<div className="slds-col_padded">
				<Input
					id="loanNumber"
					label="Loan Number"
					placeholder="Loan Number"
					value={filterValues?.loanNumber || ""}
					onChange={(e) => filterChangedHandler("loanNumber", e.target.value)}
				/>
			</div>
			<div className="slds-col_padded">
				<Input
					id="xferNumber"
					label="Transfer Number"
					placeholder="Transfer Number"
					value={filterValues?.xferNumber || ""}
					onChange={(e) => filterChangedHandler("xferNumber", e.target.value)}
				/>
			</div>
			<div className="slds-col_padded">
				<Input
					id="policyType"
					label="Policy Type"
					placeholder="Policy Type"
					value={filterValues?.policyType || ""}
					onChange={(e) => filterChangedHandler("policyType", e.target.value)}
				/>
			</div>
		</div>
	);
};
