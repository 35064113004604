import {
	useState,
	createContext,
	useContext,
	FC,
	ReactNode,
	Dispatch,
	SetStateAction,
} from "react";
import { Desktop, Mobile } from "../../../Responsive";
import { SLDSModal } from "@salesforce/design-system-react";
import { FilterPanel } from "../components/filters/FilterPanel";

type FilterPanelContextType = {
	isOpenFilterPanel: boolean;
	setIsOpenFilterPanel: Dispatch<SetStateAction<boolean>>;
};

const filterPanelStateContext = createContext<FilterPanelContextType>({
	isOpenFilterPanel: false,
	setIsOpenFilterPanel: () => {},
});
const FilterPanelStateProvider = filterPanelStateContext.Provider;

export const FilterPanelContext: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [isOpenFilterPanel, setIsOpenFilterPanel] = useState<boolean>(false);

	return (
		<FilterPanelStateProvider
			value={{ isOpenFilterPanel, setIsOpenFilterPanel }}
		>
			{children}
		</FilterPanelStateProvider>
	);
};

export const useFilterPanel = () => {
	const { isOpenFilterPanel, setIsOpenFilterPanel } = useContext(
		filterPanelStateContext
	);
	return { isOpenFilterPanel, setIsOpenFilterPanel };
};

export const MediaFilterPanelContainer = ({
	container,
}: {
	container: string | null | undefined;
}) => {
	const { isOpenFilterPanel, setIsOpenFilterPanel } = useFilterPanel();
	return (
		<>
			<Desktop>
				{isOpenFilterPanel && (
					<div>
						<FilterPanel
							title="WIP"
							label="All Media"
							closeFilterPanel={() => setIsOpenFilterPanel(false)}
							// @ts-ignore
							container={container}
						/>
					</div>
				)}
			</Desktop>
			<Mobile>
				<SLDSModal
					onRequestClose={() => setIsOpenFilterPanel(false)}
					isOpen={isOpenFilterPanel}
					size="large"
				>
					<FilterPanel
						title="WIP"
						label="All Media"
						closeFilterPanel={() => setIsOpenFilterPanel(false)}
					/>
				</SLDSModal>
			</Mobile>
		</>
	);
};
