import classes from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import { useFilterPanel } from "../../hooks/useFilterPanel";

export function FilterPanelLayout({ children, panel }) {
	const { isOpenFilterPanel } = useFilterPanel();

	return (
		<div
			className={`${classes.FilterPanelWrapper} ${
				isOpenFilterPanel && classes.FilterPanelWrapperOpen
			}`}
		>
			{children}
			{panel}
		</div>
	);
}
