import { PageHeader, Icon } from "@salesforce/design-system-react";
import React from "react";
import classes from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import Proptypes from "prop-types";
import { useMediaQuery } from "react-responsive";

export function MediaContainerHeader({
	mobileSelection,
	info,
	sticky = false,
	label,
	media,
	controls,
	container = null,
	isOrdered = false,
}) {
	const isMobile = useMediaQuery({ maxWidth: 767 });

	const top = {
		page: classes.pageTopFix,
		web: classes.web,
	};

	return (
		<PageHeader
			className={`${classes.PageHeader} ${sticky ? classes.sticky : ""} ${
				container ? top[container] : ""
			}`}
			icon={
				<Icon
					assistiveText={{
						label: "custom40",
					}}
					category="custom"
					name="custom40"
					title="custom40"
					style={{
						backgroundColor: "#97d700",
						fill: "#ffffff",
					}}
				/>
			}
			title={label}
			trail={[
				<p>
					{media?.length} images{isOrdered ? ", ordered" : ""}
				</p>,
			]}
			variant="object-home"
			onRenderControls={isMobile ? mobileSelection : null}
			onRenderActions={controls}
			info={info}
		/>
	);
}

MediaContainerHeader.propTypes = {
	label: Proptypes.string,
	media: Proptypes.array,
	controls: Proptypes.func,
};
