import React, { useRef } from "react";
import { Button, Icon, Tooltip } from "@salesforce/design-system-react";
import classes from "./ImagingCard.module.scss";
import { startCase } from "lodash";
import { useDownloadDocument } from "../api/useDownloadDocument";

export const ImagingCard = ({ document, accountNumber }) => {
	const linkRef = useRef();
	const relevantKeywords = document?.keywords
		?.filter(
			(item) =>
				item.key.toLowerCase().includes("doc subtype") ||
				item.key.toLowerCase().includes("doc type")
		)
		.map((item) => startCase(item.value.toLowerCase()));
	const title =
		relevantKeywords.length > 0
			? relevantKeywords.join(" - ")
			: document?.docName;

	const date = document?.dateStored?.slice(0, 10);
	const { fetchDoc, progress, isLoading } = useDownloadDocument(
		document?.documentId,
		accountNumber
	);

	const initiateDownload = async () => {
		const { data, error } = await fetchDoc();
		if (!error) {
			linkRef.current.href = data;
			linkRef.current.click();
		}
	};

	return (
		<div className={classes.container}>
			<div style={{ display: "flex", alignItems: "center", maxWidth: "100%" }}>
				<Icon
					assistiveText={{ label: "Document Type" }}
					category="doctype"
					name="pdf"
					onClick={() => initiateDownload()}
					size="small"
				/>
				<div style={{ paddingLeft: ".25rem" }}>
					{isLoading ? (
						<p>Downloaded {Number((progress ?? 0) / 1048576).toFixed(2)} MB</p>
					) : (
						<Tooltip content={document?.docName}>
							<Button
								style={{
									padding: "0px",
									paddingTop: "0px",
								}}
								className={classes.buttonContainer}
								onClick={() => initiateDownload()}
								variant="base"
							>
								<span className={classes.buttonContent}>{title}</span>
							</Button>
						</Tooltip>
					)}
					<a
						href="/"
						target="_blank"
						title={`${document?.docName}.pdf`}
						style={{ display: "none" }}
						ref={linkRef}
					>
						{document?.documentId}
					</a>
				</div>
			</div>
			<p className={classes.dateCreated}>{date?.slice(0, 10)}</p>
		</div>
	);
};
