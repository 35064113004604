import React from "react";
import { PricingWorksheetType } from "../types";
import { Button, Icon, Tooltip } from "@salesforce/design-system-react";
import { useState } from "react";
import styles from "../PricingWorksheet.module.scss";
import { usePricingWorksheet } from "../usePricingWorksheet";
import { useGetAllUsersQuery } from "@/features/user/api/endpoints";
import dayjs from "dayjs";

interface HistoryType {
	approvedDate: string | null;
	approver: string | null;
	value: number | null;
}

export function HoverHistoryTooltip({
	assetId,
	name,
	label,
}: {
	assetId: string;
	name: keyof PricingWorksheetType;
	label: string;
}) {
	const [isOpen, setIsOpen] = useState<boolean | undefined>(undefined);
	const { pricingWorksheets } = usePricingWorksheet(assetId);
	const { data } = useGetAllUsersQuery(undefined);
	const getManager = (id: string | null) =>
		id ? data?.find((user) => user.id === id) : null;

	const history = pricingWorksheets.map(
		(worksheet: PricingWorksheetType): HistoryType => {
			const manager = getManager(worksheet.approverId);
			return {
				approvedDate: worksheet.approvedDate,
				approver: manager ? `${manager.firstName} ${manager.lastName}` : null,
				value: worksheet[name] as number,
			};
		}
	);

	const [page, setPage] = useState(0);
	const pageLength = 5;
	const numPages = Math.ceil(history.length / pageLength);

	const paginatedHistory = isOpen
		? history.slice(page * pageLength, pageLength * (page + 1))
		: history.slice(0, 5);

	const prevPage = () => {
		setPage(page - 1);
	};

	const nextPage = () => {
		setPage(page + 1);
	};

	return (
		<Tooltip
			id={`history-tooltip-${name}`}
			dialogClassName={styles.toolTipHover}
			content={
				<div
					onClick={(e: React.MouseEvent<HTMLDivElement>) => {
						e.stopPropagation();
					}}
				>
					<div className={styles.closeButton}>
						{isOpen && (
							<Button
								variant="base"
								onClick={(e: MouseEvent) => {
									e.stopPropagation();
									setIsOpen(undefined);
								}}
							>
								<Icon name="close" category="utility" size="x-small" inverse />
							</Button>
						)}
					</div>
					<table>
						<thead>
							<tr>
								<td
									colSpan={3}
									className={styles.title}
								>{`${label} History`}</td>
							</tr>
							<tr>
								<th>Date</th>
								<th>Approved By</th>
								<th className={styles.alignRight}>Amount</th>
							</tr>
						</thead>
						<tbody>
							{paginatedHistory.map((worksheet, index) => (
								<tr key={`history-table-row-${index}`}>
									<td>
										{dayjs(worksheet.approvedDate as string).isValid() &&
											dayjs(worksheet.approvedDate as string).format(
												"MM/DD/YYYY"
											)}
									</td>
									<td>{worksheet.approver}</td>
									<td className={styles.alignRight}>${worksheet.value}</td>
								</tr>
							))}
						</tbody>
					</table>
					<div className={styles.historyWrapper}>
						{isOpen ? (
							<>
								<Button
									variant="base"
									disabled={page === 0}
									onClick={(e: MouseEvent) => {
										e.stopPropagation();
										prevPage();
									}}
								>
									<Icon
										inverse
										name="chevronup"
										category="utility"
										size="x-small"
									/>
								</Button>
								<Button
									variant="base"
									disabled={page === numPages - 1}
									onClick={(e: MouseEvent) => {
										e.stopPropagation();
										nextPage();
									}}
								>
									<Icon
										inverse
										name="chevrondown"
										category="utility"
										size="x-small"
									/>
								</Button>
							</>
						) : (
							<>
								Click
								<Icon
									inverse
									name="info"
									category="utility"
									size="x-small"
									containerStyle={{ margin: "0 .25rem" }}
								/>
								to show more.
							</>
						)}
					</div>
				</div>
			}
			onClickTrigger={(e: MouseEvent) => {
				e.stopPropagation();
				setPage(0);
				setIsOpen(isOpen ? undefined : true);
			}}
			isOpen={isOpen}
		/>
	);
}
