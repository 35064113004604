import React, { useState } from "react";
import classes from "./ConditionReportHeader.module.scss";
import {
	Icon,
	PageHeader,
	Button,
	PageHeaderControl,
	Dropdown,
} from "@salesforce/design-system-react";
import { completedDetails, incompleteDetails } from "./headerDetails";
import { useConditionReport } from "../../api/useConditionReport";
import { useAccessConditionReport } from "@/providers/roles/AccessHooks";
export const ConditionReportHeader = ({
	setEditing,
	isEditing,
	reportType,
	onSelectReportType,
}) => {
	const { conditionReport, asset, bedrooms, bathrooms, isSuccess } =
		useConditionReport();
	const [showDetails, setShowDetails] = useState(true);
	const chevron = showDetails ? "chevronup" : "chevrondown";
	const { canWrite } = useAccessConditionReport();

	// Action bar for ConditionReport Header
	const actions = () => (
		<>
			<PageHeaderControl>
				<Dropdown
					buttonClassName={classes.createNew}
					className={classes.dropDown}
					assistiveText={{ icon: "More Options" }}
					checkmark
					iconCategory="utility"
					iconName="add"
					disabled={!canWrite || isEditing}
					iconVariant="bare"
					onSelect={(item) => onSelectReportType(item.value)}
					nubbinPosition="top right"
					options={[
						{ label: "Condensed Report", value: "condensed" },
						{ label: "Room by Room", value: "full" },
					]}
					value={reportType}
				/>

				<Button
					iconCategory="utility"
					iconName={chevron}
					iconSize="small"
					iconVariant="bare"
					variant="icon"
					onClick={() => setShowDetails(!showDetails)}
				/>
			</PageHeaderControl>
		</>
	);

	return (
		<>
			{isSuccess && (
				<div className={classes.conditionReportHeader}>
					<PageHeader
						details={
							isEditing || !conditionReport?.isCompleted
								? incompleteDetails(asset, bedrooms, bathrooms)
								: completedDetails(conditionReport, asset)
						}
						icon={
							<Icon
								assistiveText={{ label: "Home" }}
								category="standard"
								name="approval"
								style={{
									fill: "#ffffff",
								}}
							/>
						}
						onRenderActions={actions}
						title={"Condition Report"}
						label={asset?.locations?.[0]?.address}
						variant={showDetails ? "record-home" : "object-home"}
					/>
				</div>
			)}
		</>
	);
};

export default ConditionReportHeader;
