import { createSelector } from "@reduxjs/toolkit";
import { getAssetMedia, mediaAdaptor } from "../endpoints";

const selectAssetMediaResult = (assetId) => getAssetMedia.select(assetId);

const selectAssetMedia = (assetId) =>
	createSelector(
		selectAssetMediaResult(assetId),
		(result) => result.data ?? mediaAdaptor.getInitialState()
	);

export const selectAllAssetMedia = (assetId) => {
	const { selectAll: selectAllMedia } = mediaAdaptor.getSelectors(
		selectAssetMedia(assetId)
	);

	return selectAllMedia;
};

export const selectMediaById = (assetId) => {
	const { selectById } = mediaAdaptor.getSelectors(selectAssetMedia(assetId));

	return selectById;
};

export const selectAssetMediaState = (assetId) => (state) =>
	state.media[assetId];
