import { ChangeEvent, useEffect, useState } from "react";
import { AssetListPayload } from "@/features/asset-list/api/endpoints";
import {
	Combobox,
	GlobalHeaderSearch,
	Icon,
} from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import {
	ACCOUNT_NUMBER,
	ADDRESS,
	CITY,
	CUSTOMER_NAME,
	STATE,
	ZIP,
} from "@/features/asset/constants";
import { useGetAssetsMutation } from "@/features/asset-list/api/endpoints";
import { Asset } from "@/features/asset/types";

const defaultPaths = [
	ACCOUNT_NUMBER.path,
	ZIP.path,
	ADDRESS.path,
	CITY.path,
	STATE.path,
	CUSTOMER_NAME.path,
];

type option = {
	label: string;
	id: string;
	subTitle: string;
	type: string;
	link: string;
	icon: JSX.Element;
};

export const GlobalSearch = () => {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState<AssetListPayload>({
		filteringCriteria: [],
		paginationCriteria: { itemsPerPage: 10, pageNumber: 0 },
		fuzzySearchCriteria: { propertyPath: defaultPaths, value: "" },
		sortCriteria: { direction: "desc", propertyPath: "account.assignedDate" },
		name: "global-search",
	});

	const [trigger, { data }] = useGetAssetsMutation({
		selectFromResult: (result) => {
			return { data: result.data?.items ?? [] };
		},
	});

	const options = data.map((item: Asset) => {
		return {
			label: `${item.account.accountNumber} ${item.account.customerName}`,
			id: item.id,
			subTitle: `${item.physicalLocation.address}, ${item.physicalLocation.city} ${item.physicalLocation.state}, ${item.physicalLocation.zip}`,
			type: "account",
			link: `/account/${item.account.id}/asset/${item.id}?tab=details`,
			icon: (
				<Icon
					assistiveText={{ label: "Home" }}
					category="standard"
					name="home"
					style={{
						backgroundColor: "rgb(13, 105, 13)",
						fill: "#ffffff",
					}}
				/>
			),
		};
	});

	const onChangeSearch = (val: string) => {
		const newSearch = {
			...searchValue,
			fuzzySearchCriteria: { ...searchValue.fuzzySearchCriteria, value: val },
		};
		trigger(newSearch);
		setSearchValue(newSearch);
	};

	useEffect(() => {
		trigger(searchValue);
		//eslint-disable-next-line
	}, []);
	return (
		<GlobalHeaderSearch
			combobox={
				<Combobox
					assistiveText={{ label: "Search Homebase" }}
					events={{
						onChange: (
							e: ChangeEvent<HTMLInputElement>,
							{ value }: { value: string }
						) => {
							onChangeSearch(value);
						},
						onSelect: (
							e: ChangeEvent<HTMLSelectElement>,
							{ selection }: { selection: option[] | null }
						) => {
							if (selection) {
								navigate(selection[0].link);
							}
						},
					}}
					id="global-search-homebase"
					value={searchValue.fuzzySearchCriteria.value}
					labels={{ placeholder: "Search Homebase" }}
					options={options}
				/>
			}
		/>
	);
};
