import { useAssetDetails } from "@/features/asset-details-tab/api/useAssetDetails";
import { assetDetailValidation } from "@/features/asset-details-tab/assetDetailValidation";
import { vestResolver } from "@hookform/resolvers/vest";
import { nanoid } from "@reduxjs/toolkit";
import { useEffect, useMemo, useState } from "react";
import { useForm, FormProvider as HookFormProvider } from "react-hook-form";

export const Fields = ({ config }) => (
	<>
		{config.map(({ component: Component, props }) => {
			return <Component key={props.name} {...props} />;
		})}
	</>
);

export const Form = ({
	options: { isStatic: isStaticOption = true, isReadOnly, ...options } = {},
	children,
	assetId,
	onUpdate = () => {},
}) => {
	const {
		data,
		isSuccess: isLoaded,
		update,
		isConcurrencyError,
		concurrencyDiff,
		reset: concurrencyReset,
	} = useAssetDetails(assetId);

	const onSubmit = (data) => {
		update(data, onUpdate);
	};

	const formData = useMemo(() => {
		return isLoaded ? data : null;
	}, [data, isLoaded]);

	const [isStatic, setIsStatic] = useState(isStaticOption);
	const [activeSection, setActiveSection] = useState(null);

	const { context, reset, setFocus, control, clearErrors, ...form } = useForm({
		resolver: async (data, { activeSection }, options) => {
			return vestResolver(() =>
				assetDetailValidation(
					data,
					activeSection,
					concurrencyDiff,
					isConcurrencyError
				)
			)(data, context, options);
		},
		mode: "onBlur",
		...options,
		context: {
			activeSection,
		},
		defaultValues: data,
	});

	const [focusField, setFocusField] = useState(null);

	const editField = (name) => {
		setIsStatic(false);
		setFocusField(name);
	};

	const resetForm = () => {
		reset(formData);
		setIsStatic(isStaticOption);
		setFocusField(null);
		assetDetailValidation.reset();
	};

	useEffect(() => {
		if (data && !form.formState.isDirty) {
			reset(data);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		isConcurrencyError && setIsStatic(false);
	}, [isConcurrencyError]);

	return (
		<form id={nanoid()} name="form" onSubmit={form.handleSubmit(onSubmit)}>
			<HookFormProvider
				{...{
					isLoaded,
					reset: resetForm,
					setFocus,
					control,
					onClickEdit: editField,
					isStatic,
					setIsStatic,
					focusField,
					setFocusField,
					isReadOnly,
					isConcurrencyError,
					concurrencyDiff,
					concurrencyReset,
					activeSection,
					setActiveSection,
					clearErrors,
				}}
				{...form}
			>
				{isLoaded && children}
			</HookFormProvider>
		</form>
	);
};
