import { DataTableCell } from "@salesforce/design-system-react";
import { ReactNode } from "react";
import { PricingWorksheetActuals } from "../types";
import { useWatch } from "react-hook-form";
import { RECOVERY, SALE_PRICE, SELECTED_PLAN } from "../constants";
import { subCategories } from "./PricingWorksheetForm";
import styles from "../PricingWorksheet.module.scss";
import { StaticInput } from "./StaticInput";

interface ActualsCellProps {
	children?: ReactNode;
	data?: PricingWorksheetActuals;
	item?: {
		id: string;
	};
}

export const ActualsCell = ({ children, data }: ActualsCellProps) => {
	const value =
		children === "refurbishing" || children === "landImprovements"
			? subCategories[children as string]?.reduce(
					(acc: number, c: string) =>
						data?.[c as keyof PricingWorksheetActuals] !== undefined
							? acc + data[c as keyof PricingWorksheetActuals]
							: acc,
					0
			  )
			: data?.[children as keyof PricingWorksheetActuals];

	return (
		<DataTableCell
			title="Actual"
			{...(children === RECOVERY.key && {
				className: styles.recoveryRow,
			})}
		>
			<StaticInput
				containerStyle={{
					...(children !== RECOVERY.key && { paddingRight: ".75rem" }),
				}}
			>
				{value && value !== 0 && Number(value).toFixed(2)}
				{children === RECOVERY.key && "%"}
			</StaticInput>
		</DataTableCell>
	);
};

ActualsCell.displayName = DataTableCell.displayName;

export const VarianceCell = ({ children, data }: ActualsCellProps) => {
	const actual =
		children === "refurbishing" || children === "landImprovements"
			? subCategories[children as string]?.reduce(
					(acc: number, c: string) =>
						data?.[c as keyof PricingWorksheetActuals] !== undefined
							? acc + data[c as keyof PricingWorksheetActuals]
							: acc,
					0
			  )
			: data?.[children as keyof PricingWorksheetActuals];

	const plans = ["retailEstimates", "wholesaleEstimates"];
	const selectedPlan = useWatch({ name: SELECTED_PLAN.key });
	const column = selectedPlan > 1 ? "miscEstimates" : plans[selectedPlan];

	const name =
		children === "refurbishing" || children === "landImprovements"
			? subCategories[children as string]?.map((item) => `${column}.${item}`)
			: [`${column}.${children}`];

	const values = useWatch({ name });

	const estimate = values.reduce(
		(acc: number, c: number) => (c ? acc + c : acc),
		0
	);
	return children === SALE_PRICE.key ? (
		<DataTableCell></DataTableCell>
	) : (
		<DataTableCell
			title="Variance"
			{...(children === RECOVERY.key && {
				className: styles.recoveryRow,
			})}
		>
			<StaticInput>
				{actual !== undefined &&
					selectedPlan !== undefined &&
					selectedPlan !== null &&
					(estimate - actual).toFixed(2)}
			</StaticInput>
		</DataTableCell>
	);
};

VarianceCell.displayName = DataTableCell.displayName;
