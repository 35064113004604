import { ReactNode, createContext, useCallback, useState } from "react";
import { UseFormReset, useFormContext } from "react-hook-form";
import { PricingWorksheetType } from "../types";

interface FormResetContextType {
	reset: UseFormReset<PricingWorksheetType>;
	addCallback: ({ id, callback }: { id: string; callback: () => void }) => void;
	removeCallback: (id: string) => void;
}

export const FormResetContext = createContext({} as FormResetContextType);

interface FormResetProviderProps {
	children: ReactNode;
}

interface FormResetCallbacks {
	[key: string]: () => void;
}
export const FormResetProvider = ({ children }: FormResetProviderProps) => {
	const { reset: resetForm } = useFormContext();

	const [callbacks, setCallbacks] = useState({} as FormResetCallbacks);

	const addCallback = ({
		id,
		callback,
	}: {
		id: string;
		callback: () => void;
	}) => {
		setCallbacks((callbacks) => {
			return { ...callbacks, [id]: callback };
		});
	};

	const removeCallback = (id: string) => {
		const { [id]: remove, ...rest } = callbacks;
		setCallbacks(rest);
	};

	const reset = useCallback(() => {
		resetForm();
		Object.values(callbacks).forEach(function (callback) {
			callback();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [callbacks]);

	return (
		<FormResetContext.Provider value={{ reset, addCallback, removeCallback }}>
			{children}
		</FormResetContext.Provider>
	);
};
