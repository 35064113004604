import { enforce, test, create } from "vest";

/**
 * Document type is required
 */
export const documentationValidation = create((data = {}) => {
	test("sourceId", "Must select a Source", () => {
		enforce(data.sourceId).greaterThan(0);
	});
	test("reasonId", "Must select a Reason", () => {
		if (data.sourceId === 1) {
			enforce(data.reasonId).isNotEmpty();
		}
	});
	test("content", "Content must not be empty", () => {
		enforce(data.content).isNotEmpty();
	});
});
