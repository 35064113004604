import { nanoid } from "@reduxjs/toolkit";
import React, { useRef, useLayoutEffect } from "react";
import { useTripPlanningProvider } from "../TripPlanningProvider";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { Marker } from "./Marker";
import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import { toggleLocation } from "../../store/tripPlanningSlice";
import { useTripPlanning } from "../../api/useTripPlanning";
import { useRoute } from "../../api/useRoute";
import { get } from "lodash";
import {
	ACCOUNT_NUMBER,
	ADDRESS,
	CITY,
	LATITUDE,
	LONGITUDE,
	STATE,
	ZIP,
} from "@/features/asset/constants";

export const Points = () => {
	const { mapRef } = useTripPlanningProvider();
	const { data } = useTripPlanning();
	const dispatch = useDispatch();
	const { updateRoute } = useRoute();
	/*
		function to update route when point marker is clicked
	*/
	const onClickPoint = (item) => {
		dispatch(toggleLocation(item));
		updateRoute();
	};
	return (
		<>
			{mapRef.current &&
				data.map((point, index) => (
					<Point
						key={nanoid()}
						index={index}
						mapRef={mapRef}
						onClickPoint={onClickPoint}
						pointData={{
							id: point.id,
							latitude: get(point, LATITUDE.path),
							longitude: get(point, LONGITUDE.path),
							accountNumber: get(point, ACCOUNT_NUMBER.path),
							address: get(point, ADDRESS.path),
							state: get(point, STATE.path),
							city: get(point, CITY.path),
							zip: get(point, ZIP.path),
						}}
					/>
				))}
		</>
	);
};

const Point = ({ pointData, index, mapRef, onClickPoint }) => {
	// This ref holds the TrimbleMaps.Marker() to call remove() for cleanup
	const pointRef = useRef(null);

	// This ref holds the Marker Div
	const svgRef = useRef(document.createElement("div"));

	useLayoutEffect(() => {
		// generate points logic
		let newMarker = new TrimbleMaps.Marker({
			element: svgRef.current,
		})
			.setLngLat([pointData.longitude, pointData.latitude])
			.addTo(mapRef?.current);
		// return remove logic
		pointRef.current = newMarker;
		return () => {
			if (pointRef?.current) {
				pointRef?.current?.remove();
				pointRef.current = null;
			}
		};
		//eslint-disable-next-line
	}, []);
	return (
		<>
			{createPortal(
				<Marker index={index} item={pointData} onClick={onClickPoint} />,
				svgRef.current
			)}
		</>
	);
};
