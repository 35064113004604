import {
	Dispatch,
	ReactNode,
	SetStateAction,
	createContext,
	useState,
} from "react";
import { useFormReset } from "../hooks/useFormReset";
import { useFormFocus } from "../hooks/useFormFocus";

interface FormSectionContextType {
	sections: {
		[key: string]: boolean;
	};
	setSections: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	activateSection: (section: string) => void;
	deactivateSection: (section: string) => void;
	isEditable: boolean;
	isFetching: boolean;
}

export const FormSectionContext = createContext({} as FormSectionContextType);

interface FormSectionProviderProps {
	children: ReactNode;
	isEditable: boolean;
	isFetching: boolean;
	onActivateSection?: (arg: {
		sections?: { [key: string]: boolean };
		section: string;
		setSections: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
	}) => void;
}

export const FormSectionProvider = ({
	children,
	onActivateSection,
	isEditable,
	isFetching,
}: FormSectionProviderProps) => {
	const [sections, setSections] = useState({});
	const { setFocusField } = useFormFocus();

	const activateSection = (section: string) => {
		if (onActivateSection) {
			onActivateSection({ sections, section, setSections });
		} else {
			setSections((sections) => ({
				...Object.fromEntries(Object.keys(sections).map((key) => [key, false])),
				[section]: true,
			}));
		}
	};

	const deactivateSection = (section: string) => {
		setSections((sections) => ({ ...sections, [section]: false }));
	};

	const deactivateAllSections = () => {
		setSections((sections) =>
			Object.fromEntries(Object.keys(sections).map((key) => [key, false]))
		);
	};

	const reset = () => {
		deactivateAllSections();
		setFocusField(null);
	};

	useFormReset(reset);

	return (
		<FormSectionContext.Provider
			value={{
				sections,
				setSections,
				activateSection,
				deactivateSection,
				isEditable,
				isFetching,
			}}
		>
			{children}
		</FormSectionContext.Provider>
	);
};
