import { ControlledSelect } from "@/Components/Form";
import {
	thermalZone,
	dispositionOfTitle,
	hvacStatus,
	hvacType,
	loanOrigination,
	utilitySystem,
	wasteSystem,
	waterSystem,
	windZone,
	contactPersonType,
	months,
	AssetType,
	roofLoad,
	states,
	skipReasonType,
	lockTypes,
} from "@/Utilities/enums";

const createLookupSelect = (lookup) => (props) => {
	const options = Object.entries(lookup).map(([id, description]) => ({
		label: description,
		value: id,
	}));

	return <ControlledSelect options={options} {...props} />;
};

export const LockTypeSelect = createLookupSelect(lockTypes);
export const WindZoneSelect = createLookupSelect(windZone);
export const DispositionOfTitleSelect = createLookupSelect(dispositionOfTitle);
export const LoanOriginationSelect = createLookupSelect(loanOrigination);
export const HvacTypeSelect = createLookupSelect(hvacType);
export const HvacStatusSelect = createLookupSelect(hvacStatus);
export const ThermalZoneSelect = createLookupSelect(thermalZone);
export const UtilitySystemSelect = createLookupSelect(utilitySystem);
export const WasteSystemSelect = createLookupSelect(wasteSystem);
export const WaterSystemSelect = createLookupSelect(waterSystem);
export const ContactPersonTypeSelect = createLookupSelect(contactPersonType);
export const MonthSelect = createLookupSelect(months);
export const AssetTypeSelect = createLookupSelect(AssetType);
export const RoofLoadSelect = createLookupSelect(roofLoad);
export const StateSelect = createLookupSelect(states);
export const SkipReasonSelect = createLookupSelect(skipReasonType);
