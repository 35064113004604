import { Icon, Tooltip } from "@salesforce/design-system-react";
import { useSelector } from "react-redux";
import { selectRoute } from "../store/tripPlanningSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useMemo, useRef } from "react";
import classes from "./GPXFile.module.scss";

/**
 * Takes in an array of stops Returns a string formatted to create a gpx document
 * @param stops Array -> stops along a route for GPX generation
 */

const createXmlString = (stops) => {
	let result = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>
    <gpx xmlns="http://www.topografix.com/GPX/1/1"
        xmlns:gpxx="http://www.garmin.com/xmlschemas/GpxExtensions/v3"
        xmlns:gpxtpx="http://www.garmin.com/xmlschemas/TrackPointExtension/v2"
        xmlns:trp="http://www.garmin.com/xmlschemas/TripExtensions/v1"
        xmlns:vs="http://www.garmin.com/xmlschemas/VehicleSourceExtensions/v1" creator="Garmin DriveAssist 51" version="1.1"
        xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd http://www.garmin.com/xmlschemas/GpxExtensions/v3 http://www.garmin.com/xmlschemas/GpxExtensionsv3.xsd http://www.garmin.com/xmlschemas/TrackPointExtension/v2 http://www.garmin.com/xmlschemas/TrackPointExtensionv2.xsd http://www.garmin.com/xmlschemas/TripExtensions/v1 http://www.garmin.com/xmlschemas/TripExtensionsv1.xsd http://www.garmin.com/xmlschemas/VehicleSourceExtensions/v1 http://www.garmin.com/xmlschemas/VehicleSourceExtensionsv1.xsd">
    `;
	stops.forEach((item) => {
		const { address, city, state, zip, latitude, longitude, accountNumber } =
			item;
		const streetAddress = `${address}, ${city}, ${state} ${zip}`;
		const wptExtension = address
			? `
        <desc>${streetAddress}</desc>
        <extensions>
            <gpxx:WaypointExtension>
            <gpxx:Address>
                <gpxx:StreetAddress>${address}</gpxx:StreetAddress>
                <gpxx:City>${city}</gpxx:City>
                <gpxx:State>${state}</gpxx:State>
                <gpxx:Country>USA</gpxx:Country>
                <gpxx:PostalCode>${zip}</gpxx:PostalCode>
            </gpxx:Address>
            </gpxx:WaypointExtension>
        </extensions>
    `
			: "";
		const wayPoint = `
        <wpt lat="${latitude}" lon="${longitude}">
            <name>${accountNumber}</name>
            ${wptExtension}
        </wpt>
        `;
		result += wayPoint;
	});
	result += "</gpx>";
	return result;
};

export const GPXFile = () => {
	const anchorRef = useRef();
	const route = useSelector(selectRoute);
	const link = useMemo(() => {
		return "data:text/json;charset=utf-8," + createXmlString(route);
	}, [route]);
	return (
		<>
			<Tooltip content="Download Garmin Directions">
				<a
					className="slds-button slds-button_icon-border-filled"
					ref={anchorRef}
					style={
						route.length < 1
							? {
									pointerEvents: "none",
							  }
							: {}
					}
					download={`${nanoid()}.gpx`}
					href={link}
				>
					<Icon
						category="utility"
						className={route.length < 1 && classes.disabledIcon}
						variant="border-filled"
						name="download"
						size="x-small"
					/>
				</a>
			</Tooltip>
		</>
	);
};
