import { Button, Tooltip } from "@salesforce/design-system-react";
import { useFilterPanel } from "@/features/media-manager/hooks/useFilterPanel";

export function FilterPanelButton() {
	const { isOpenFilterPanel, setIsOpenFilterPanel } = useFilterPanel();

	const handleToggleFilterPanel = () =>
		setIsOpenFilterPanel(!isOpenFilterPanel);

	return (
		<Tooltip content="Filter Panel" align="bottom">
			<Button
				assistiveText={{ icon: "Filters" }}
				iconCategory="utility"
				iconName="filterList"
				iconVariant="border-filled"
				variant="icon"
				onClick={handleToggleFilterPanel}
			/>
		</Tooltip>
	);
}
