import { getConditionReport } from "@/features/condition-report/api/endpoints";
import {
	getAssetMedia,
	getMedia,
} from "@/features/media-manager/api/endpoints";
import { gatewayApi } from "@/ReduxToolkit";
import { offlineAssetDB } from "@/storage";
import { offlineAssetAdapter } from "./getOfflineAssets";
import { ASSIGNED_DATE } from "@/features/asset/constants";
import { getAllAssets } from "@/features/asset-list/api/endpoints";

const removeAsset = async (assetId, api) => {
	const offlineAsset = await offlineAssetDB.getItem(assetId);

	await offlineAssetDB.removeItem(assetId);

	const offlineAssetKeys = await offlineAssetDB.keys();

	const assetListProps = {
		name: "offlineAssets",
		paginationCriteria: {
			itemsPerPage: 20,
			pageNumber: 0,
		},
		filteringCriteria: [
			{ propertyPath: "id", operation: "==", value: offlineAssetKeys },
		],
		sortCriteria: { direction: "desc", propertyPath: ASSIGNED_DATE.path },
	};

	const assetList = api.dispatch(
		getAllAssets.initiate(assetListProps, {
			forceRefetch: true,
		})
	);

	const asset = api.dispatch(
		gatewayApi.endpoints.getAsset.initiate(assetId, {
			forceRefetch: true,
		})
	);

	const assetMedia = api.dispatch(
		getAssetMedia.initiate(assetId, { forceRefetch: true })
	);

	const media = offlineAsset.media.map((media) =>
		api.dispatch(getMedia.initiate(media.id, { forceRefetch: true }))
	);

	const conditionReport = api.dispatch(
		getConditionReport.initiate(assetId, { forceRefetch: true })
	);

	await Promise.allSettled([
		assetList.unwrap(),
		asset.unwrap(),
		assetMedia.unwrap(),
		Promise.allSettled(media.map(async (media) => await media.unwrap())),
		Promise.allSettled(
			offlineAsset.media.map(
				async (media) =>
					await fetch(`${media.path}&offline&w=1000&h=1000`, {
						mode: "cors",
					})
			)
		),
		conditionReport.unwrap(),
	]);

	assetList.unsubscribe();
	asset.unsubscribe();
	assetMedia.unsubscribe();
	media.forEach((media) => media.unsubscribe());
	conditionReport.unsubscribe();

	return { data: null };
};

export const removeOfflineAssetEndpoint = {
	queryFn: async (assetId, api, extraOptions, baseQuery) => {
		const live = api.dispatch(
			gatewayApi.endpoints.live.initiate({ forceReftech: true })
		);

		try {
			await live.unwrap();
		} catch (error) {
			return { error };
		}

		await removeAsset(assetId, api);

		live.unsubscribe();

		return { data: null };
	},
	onQueryStarted: async (assetId, api) => {
		const patch = await api.dispatch(
			gatewayApi.util.updateQueryData(
				"getOfflineAssets",
				undefined,
				(offlineAssets) => offlineAssetAdapter.removeOne(offlineAssets, assetId)
			)
		);

		try {
			await api.queryFulfilled;
		} catch (error) {
			patch.undo();
		}
	},
	invalidatesTags: ["OfflineAsset"],
};

export const removeAllOfflineAssetsEndpoint = {
	onQueryStarted: async (_, api) => {
		const patch = await api.dispatch(
			gatewayApi.util.updateQueryData(
				"getOfflineAssets",
				undefined,
				(offlineAssets) => offlineAssetAdapter.removeAll(offlineAssets)
			)
		);

		try {
			await api.queryFulfilled;
		} catch (error) {
			patch.undo();
		}
	},
	queryFn: async (_, api) => {
		const live = api.dispatch(
			gatewayApi.endpoints.live.initiate({ forceReftech: true })
		);

		try {
			await live.unwrap();
		} catch (error) {
			return { error };
		}
		const assetIds = await offlineAssetDB.keys();

		await Promise.all(
			assetIds.map(async (id) => {
				await removeAsset(id, api);
			})
		);

		const assetListProps = {
			name: "offlineAssets",
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: [
				{
					propertyPath: "id",
					operation: "==",
					value: [],
				},
			],
			sortCriteria: { direction: "desc", propertyPath: "year" },
		};

		const assetList = api.dispatch(
			getAllAssets.initiate(assetListProps, {
				forceRefetch: true,
			})
		);

		await assetList.unwrap();

		live.unsubscribe();
		assetList.unsubscribe();

		return { data: null };
	},
	invalidatesTags: ["OfflineAsset", { type: "AssetList" }],
};
