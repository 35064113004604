import { nanoid } from "@reduxjs/toolkit";
import { useContext, useEffect } from "react";
import { FormResetContext } from "../providers/FormResetProvider";

export const useFormReset = (callback?: () => void) => {
	const id = nanoid();
	const { reset, addCallback, removeCallback } = useContext(FormResetContext);

	useEffect(() => {
		if (callback && typeof callback === "function") {
			addCallback({ id, callback });
			return () => {
				removeCallback(id);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return reset;
};
