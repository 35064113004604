import { enforce, test, create } from "vest";
import { BuyerFormType } from "./NewBuyer";

const phoneNumberRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const ssnRegex = /^\d{3}-?\d{2}-?\d{4}$/;

export const buyerValidation = create((data: BuyerFormType) => {
	test("firstName", "First Name is Required", () => {
		if (!data.institutionName) {
			enforce(data.firstName).isNotBlank();
		}
	});

	test("lastName", "Last Name is Required", () => {
		if (!data.institutionName) {
			enforce(data.lastName).isNotBlank();
		}
	});

	test("phoneNumber1", "must be a valid phone number", () => {
		enforce(data.phoneNumber1).matches(phoneNumberRegex);
	});

	test("phoneNumber2", "must be a valid phone number", () => {
		if (data.phoneNumber2) {
			enforce(data.phoneNumber2).matches(phoneNumberRegex);
		}
	});

	test(
		"middleName",
		"Middle Name must be empty if First/Last Name are empty",
		() => {
			if (!data.firstName && !data.lastName) {
				enforce(data.middleName).isBlank();
			}
		}
	);

	test("address", "Address is Required", () => {
		enforce(data.address1).isNotBlank();
	});

	test("city", "City is Required", () => {
		enforce(data.city).isNotBlank();
	});

	test("county", "County is Required", () => {
		enforce(data.county).isNotBlank();
	});

	test("state", "State is Required", () => {
		enforce(data.state).isNotBlank();
	});

	test("zip", "Zip is Required", () => {
		enforce(data.zip).isNotBlank();
	});

	test("zip", "must match ##### format", () => {
		enforce(data.zip).matches(/^\d{5}$/);
	});

	test("ssein", "must match ###-##-#### format", () => {
		if (data.ssein) {
			enforce(data.ssein).matches(ssnRegex);
		}
	});

	test("driversLicense.expiration", "must match MM/DD/YYYY format", () => {
		if (data.driversLicense?.expiration) {
			enforce(data.driversLicense.expiration).isISODate();
		}
	});

	test("ofacPullDate", "must match MM/DD/YYYY format", () => {
		if (data.ofacPullDate) {
			enforce(data.ofacPullDate).isISODate();
		}
	});
});
