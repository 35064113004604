import { Button } from "@salesforce/design-system-react";
import { useFormContext } from "react-hook-form";
import { usePricingWorksheet } from "../usePricingWorksheet";
import spinner from "./spinner.module.scss";
import { useState } from "react";
import dayjs from "dayjs";
import { useGetCurrentUserQuery } from "@/features/user/api/endpoints";
import { useIsManager } from "@/providers/roles/AccessHooks";
import { useFormSection } from "../hooks/useFormSection";

export const ApprovalButton = ({ assetId }: { assetId: string }) => {
	const { approvalStatus, isSuccess, onSubmit, isHomeRepossessed } =
		usePricingWorksheet(assetId);
	const { isFetching } = useFormSection();

	const { data: user } = useGetCurrentUserQuery();
	const isManager = useIsManager();

	const {
		setValue,
		formState: { isSubmitting: isSubmittingForm },
		handleSubmit,
	} = useFormContext();

	const isSubmitting = isSubmittingForm || isFetching;

	const [buttonClicked, setButtonClicked] = useState<string | null>(null);
	const spinnerProps = {
		iconClassName: spinner.spinner,
		iconName: "spinner",
		iconPosition: "left",
		iconCategory: "utility",
	};

	const submitForApproval = () => {
		setValue("approvalStatus", 2, { shouldDirty: false });
		setButtonClicked("submitForApproval");
		handleSubmit(onSubmit)();
	};

	const setToInProgress = () => {
		setValue("approvalStatus", 1, { shouldDirty: false });
		setButtonClicked("setToInProgress");
		handleSubmit(onSubmit)();
	};

	const approve = () => {
		setValue("approvalStatus", 3, { shouldDirty: false });
		setValue("approverId", user?.id, { shouldDirty: false });
		setValue("approvedDate", dayjs().toISOString(), { shouldDirty: false });
		setButtonClicked("approve");
		handleSubmit(onSubmit)();
	};

	return (
		isSuccess && (
			<div style={{ display: "flex" }}>
				{approvalStatus === "In Progress" && isHomeRepossessed && (
					<>
						<Button
							onClick={submitForApproval}
							disabled={isSubmitting}
							{...(isSubmitting &&
								buttonClicked === "submitForApproval" &&
								spinnerProps)}
						>
							Submit for Approval
						</Button>
					</>
				)}
				{approvalStatus === "Pending Approval" && isManager && (
					<>
						<Button
							onClick={setToInProgress}
							{...(isSubmitting &&
								buttonClicked === "setToInProgress" &&
								spinnerProps)}
							disabled={isSubmitting}
						>
							Set to in Progress
						</Button>
						<Button
							onClick={approve}
							{...(isSubmitting && buttonClicked === "approve" && spinnerProps)}
							disabled={isSubmitting}
						>
							Approve
						</Button>
					</>
				)}
			</div>
		)
	);
};
