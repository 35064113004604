import { createContext, useContext } from "react";
import { useParams } from "react-router-dom";

const AssetIdContext = createContext({});

export const AssetIdProvider = ({ accountId, assetId, children }) => {
	return (
		<AssetIdContext.Provider value={{ assetId, accountId }}>
			{children}
		</AssetIdContext.Provider>
	);
};

export const useAssetId = () => {
	const { accountId: accountIdFromContext, assetId: assetIdFromContext } =
		useContext(AssetIdContext);
	const { accountId: accountIdFromParams, assetId: assetIdFromParams } =
		useParams();

	return {
		accountId: accountIdFromContext || accountIdFromParams,
		assetId: assetIdFromContext || assetIdFromParams,
	};
};
