import { DatePicker } from "@salesforce/design-system-react";
import dayjs from "dayjs";
import { useEffect } from "react";
import { timePredicates } from "@/Utilities/filtering";

export function DateSelect({
	predicate,
	setPredicate,
	comparison,
	setComparison,
	...props
}) {
	const isRange = Array.isArray(predicate);
	const [start, end] = isRange && predicate.length === 2 ? predicate : Array(2);

	useEffect(() => {
		if (isRange && !start && !end) {
			setPredicate([dayjs().toISOString(), dayjs().toISOString()]);
		}

		if (!predicate) {
			setPredicate(dayjs().toISOString());
		}
	}, [end, isRange, predicate, setPredicate, start]);

	return (
		<>
			{isRange ? (
				<>
					<DatePicker
						value={parser(start)}
						parser={parser}
						formatter={formatter}
						onChange={(e, data) => {
							setPredicate([data.date.toISOString(), end]);
						}}
						menuPosition="relative"
					/>
					<DatePicker
						value={parser(end)}
						parser={parser}
						formatter={formatter}
						onChange={(e, data) => {
							setPredicate([start, data.date.toISOString()]);
						}}
						menuPosition="relative"
					/>
				</>
			) : (
				<DatePicker
					value={dayjs(predicate).toDate()}
					defaultValue={dayjs(predicate).toDate()}
					onChange={(e, data) => {
						setPredicate(data.date.toISOString());
					}}
					menuPosition="relative"
				/>
			)}
		</>
	);
}

function parser(date) {
	const dateObj = dayjs(timePredicates[date] ?? date);

	return dateObj.isValid() ? dateObj.toDate() : void 0;
}

function formatter(date) {
	return dayjs(date).format("MM/DD/YYYY");
}
