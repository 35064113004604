import { configureStore } from "@reduxjs/toolkit";
import { gatewayApi } from "./GatewayApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { offlineAssetSlice } from "./offlineAssetSlice";
import { tripPlanningSlice } from "@/features/trip-planning/store/tripPlanningSlice";
import { insuranceQueueSlice } from "@/features/insurance-cancellation-queue/store/insuranceQueueSlice";
import { toastSlice } from "./ToastsSlice";
import { rtkQueryErrorLogger } from "./rtkQueryErrorLogger";
import { mediaSlice } from "./MediaSlice";
import { documentationSlice } from "@/ReduxToolkit/DocumentationSlice";
import { conditionReportSlice } from "./ConditionReportSlice";
import { listenerMiddleware } from "./listenerMiddleware";
import { messageRecieverMiddleware } from "./messageReceiverMiddleWare";
import { imagingSlice } from "@/features/imaging/api/ImagingSlice";


export const makeStore = (initialState = {}) =>
	configureStore({
		reducer: {
			media: mediaSlice.reducer,
			documentation: documentationSlice.reducer,
			conditionReport: conditionReportSlice.reducer,
			insuranceQueue: insuranceQueueSlice.reducer,
			offlineAssets: offlineAssetSlice.reducer,
			toasts: toastSlice.reducer,
			tripPlanning: tripPlanningSlice.reducer,
			imaging: imagingSlice.reducer,
			[gatewayApi.reducerPath]: gatewayApi.reducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware()
				.prepend(listenerMiddleware.middleware, messageRecieverMiddleware)
				.concat(gatewayApi.middleware, rtkQueryErrorLogger),
		preloadedState: initialState,
	});

export const store = makeStore();

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
