import { split } from "../Utilities/split";

const initialProperties = {
	tags: {
		persist: true,
		default: [],
		shared: false,
	},
	webReady: {
		persist: true,
		default: false,
		shared: true,
	},
	order: {
		persist: true,
		default: null,
		shared: true,
	},
	title: {
		persist: true,
		default: "",
		shared: false,
	},
	isPrimary: {
		persist: true,
		default: false,
		shared: true,
	},
	updating: {
		persist: false,
		default: "idle",
	},
};

export const stringifyPropertyBag = (media) => ({
	...media,
	propertyBag: JSON.stringify(media.propertyBag),
});

export const parsePropertyBag = (media) => ({
	...media,
	propertyBag: JSON.parse(media.propertyBag),
});

export function unpackPropertyBag(mediaObject) {
	const bag = mediaObject.propertyBag;
	const isHomebaseProperty = (properties) => {
		const key = properties["Key"] || properties["key"];

		return key === "application:vmfhomes" || key === "application:homebase";
	};

	const defaults = Object.fromEntries(
		Object.entries(initialProperties).map(([key, value]) => [
			key,
			value.default,
		])
	);

	const [homebaseProperties, propertyBag] = split(bag, isHomebaseProperty);

	let properties = homebaseProperties.reduce((acc, entry) => {
		const value = entry["value"] || entry["Value"];
		return {
			...acc,
			...JSON.parse(value),
		};
	}, {});

	return {
		...defaults,
		...withLowerCamelKeys(properties),
		propertyBag,
	};
}

export function packPropertyBag(mediaObject) {
	const propertyBag = mediaObject.propertyBag;
	const persistedProperties = Object.entries(mediaObject).filter(
		([key, _]) => initialProperties[key]?.persist
	);

	const [vmfhomes, homebase] = split(
		persistedProperties,
		([key, _]) => initialProperties[key].shared
	);

	const upperCamel = vmfhomes.map(([key, value]) => [
		convertToUpperCamel(key),
		value,
	]);

	return [
		...propertyBag,
		{
			Key: "application:vmfhomes",
			Value: JSON.stringify(Object.fromEntries(upperCamel)),
		},
		{
			Key: "application:homebase",
			Value: JSON.stringify(Object.fromEntries(homebase)),
		},
	];
}

function convertToLowerCamel(value) {
	return value.charAt(0).toLowerCase() + value.slice(1);
}

function withLowerCamelKeys(obj) {
	return Object.fromEntries(
		Object.entries(obj).map(([key, value]) => {
			return [convertToLowerCamel(key), value];
		})
	);
}

function convertToUpperCamel(value) {
	return value.charAt(0).toUpperCase() + value.slice(1);
}
