import { useGetSaleByIdQuery } from "../api/endpoints";

export const useSales = (saleId: string | undefined) => {
	const { data, isSuccess, ...rest } = useGetSaleByIdQuery(saleId ?? "", {
		skip: !saleId,
	});

	return {
		data,
		isSuccess,
		...rest,
	};
};
