import React from "react";
import {
	DataTable,
	DataTableCell,
	DataTableColumn,
	Tooltip,
} from "@salesforce/design-system-react";
import { Buyer } from "../../types";
import dayjs from "dayjs";
import {
	useGetSaleByIdQuery,
	useUpdateSaleMutation,
} from "../../api/endpoints";
import { BiPencil, BiTrash } from "react-icons/bi";
import { useSaleId } from "../SaleContext";
import {
	TbCircleLetterA,
	TbCircleLetterC,
	TbCircleLetterL,
} from "react-icons/tb";
import { useEditBuyerModal } from "./EditBuyerModal";
const DriversLicenseCell = ({ item }: { item?: Buyer }) => {
	return (
		<DataTableCell
			title={`${item?.driversLicense?.state} ${item?.driversLicense?.number} ${item?.driversLicense?.expiration}`}
		>
			<p style={{ display: "flex", justifyContent: "space-between" }}>
				<span style={{ paddingRight: "4px", fontWeight: "bold" }}>
					{item?.driversLicense?.state}
				</span>
				{item?.driversLicense?.number}
				<span style={{ paddingLeft: "4px", fontWeight: "bold" }}>
					{item?.driversLicense?.expiration
						? dayjs(item?.driversLicense.expiration).format("MM/DD/YY")
						: ""}
				</span>
			</p>
		</DataTableCell>
	);
};
DriversLicenseCell.displayName = DataTableCell.displayName;

const NameCell = ({ item }: { item?: Buyer }) => {
	return (
		<DataTableCell title={`${item?.firstName} ${item?.lastName}`}>
			<p>{`${item?.firstName} ${item?.lastName}`}</p>
		</DataTableCell>
	);
};
NameCell.displayName = DataTableCell.displayName;

const AddressCell = ({ item }: { item?: Buyer }) => {
	return (
		<DataTableCell title={`${item?.address1} ${item?.address2}`}>
			<p>
				{item?.address1} {item?.address2}
			</p>
		</DataTableCell>
	);
};
AddressCell.displayName = DataTableCell.displayName;

const ActiveCell = ({ item }: { item?: Buyer }) => {
	const { saleId } = useSaleId();
	const { data: sale } = useGetSaleByIdQuery(saleId);
	const [trigger] = useUpdateSaleMutation();
	const modal = useEditBuyerModal();

	const onRemoveBuyer = () => {
		if (sale) {
			let buyers = [...sale.buyerInterests];
			const buyerIndex = buyers.findIndex((buyer) => buyer.id === item?.id);
			buyers.splice(buyerIndex, 1);
			trigger({
				...sale,
				buyerInterests: buyers,
			});
		}
	};
	return (
		<DataTableCell>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				{item?.isLienHolder && (
					<Tooltip content="Lienholder" align="right">
						<TbCircleLetterL
							title="Lienholder"
							style={{ width: 22, height: 22, color: "orange" }}
						/>
					</Tooltip>
				)}
				{item?.isActive && (
					<Tooltip content="Active" align="right">
						<TbCircleLetterA
							title="Active Buyer"
							style={{ width: 22, height: 22, color: "#0070d2" }}
						/>
					</Tooltip>
				)}
				{item?.isInCareOf && (
					<Tooltip content="In Care Of" align="right">
						<TbCircleLetterC
							title="In Care Of"
							style={{ width: 22, height: 22, color: "indigo" }}
						/>
					</Tooltip>
				)}
				<Tooltip content="Remove Buyer from Sale" align="right">
					<BiTrash
						title="Remove Buyer"
						style={{ height: 20, width: 20, cursor: "pointer" }}
						onClick={() => onRemoveBuyer()}
					/>
				</Tooltip>
				<BiPencil
					title="Edit Buyer"
					style={{ height: 20, width: 20, cursor: "pointer" }}
					onClick={() => modal.show({ saleId, buyerId: item?.id })}
				/>
			</div>
		</DataTableCell>
	);
};
ActiveCell.displayName = DataTableCell.displayName;

export const buyerColumnConfig = {
	name: {
		component: NameCell,
		label: "Buyer Name",
		width: "140px",
		property: "firstName",
	},
	address: {
		component: AddressCell,
		label: "Address",
		width: "200px",
		property: "address",
	},
	city: {
		label: "City",
		width: "100px",
		property: "city",
	},
	state: {
		label: "State",
		width: "50px",
		property: "state",
	},
	zip: {
		label: "Zip",
		width: "50px",
		property: "zip",
	},
	phone: {
		label: "Phone #",
		width: "100px",
		property: "phoneNumber1",
	},
	emailAddress: {
		label: "Email",
		width: "200px",
		property: "emailAddress",
	},
	driversLicense: {
		label: "Drivers License",
		width: "150px",
		property: "driversLicense",
		component: DriversLicenseCell,
	},
	dealerLicenseNumber: {
		label: "Dealer #",
		width: "120px",
		property: "dealerLicenseNumber",
	},
	ssein: {
		label: "SSEIN",
		width: "130px",
		property: "ssein",
	},
	institution: {
		label: "Institution",
		width: "120px",
		property: "institutionName",
	},
};

export const BuyerTable = () => {
	const { saleId } = useSaleId();
	const { data, isSuccess } = useGetSaleByIdQuery(saleId);
	if (!isSuccess) {
		return <></>;
	}
	const items = data?.buyerInterests;
	const cols = Object.values(buyerColumnConfig);
	return (
		<div style={{ padding: "0 0rem 0.5rem 0rem" }}>
			<div style={{ overflow: "auto" }}>
				<DataTable
					style={{ fontSize: ".8em", overflow: "auto", minWidth: "800px" }}
					items={items}
					id="BuyerTable"
					fixedLayout
					columnBordered
				>
					<DataTableColumn label="" width="100px" property="isActive">
						<ActiveCell />
					</DataTableColumn>
					{cols.map((column) => {
						return (
							<DataTableColumn
								key={column.property}
								width={column?.width ? column.width : "50px"}
								label={column?.label ? column.label : ""}
								property={column.property}
							>
								{"component" in column ? (
									React.createElement(column.component)
								) : (
									<></>
								)}
							</DataTableColumn>
						);
					})}
				</DataTable>
			</div>
		</div>
	);
};
