import { createSelector } from "@reduxjs/toolkit";
import { selectAllAssetMedia, selectAssetMediaState } from "./assetMedia";

export const selectWebReadyMediaState = (assetId) =>
	createSelector(selectAssetMediaState(assetId), (media) => media?.webReady);

export const selectWebReadyMedia = (assetId) =>
	createSelector(selectAllAssetMedia(assetId), (media) =>
		media?.filter((media) => media?.webReady).sort((a, b) => a.order - b.order)
	);

export const selectWebReadyMediaIds = (assetId) =>
	createSelector(selectWebReadyMedia(assetId), (media) =>
		media.map((media) => media.id)
	);
