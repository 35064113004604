import { DataTableCell } from "@salesforce/design-system-react";
import {
	ACCOUNT_BALANCE,
	CLOSING_COSTS,
	COMMISSION,
	LAND_IMPROVEMENTS,
	NET_SALE,
	RECOVERY,
	REFURBISHING,
	SALE_PRICE,
	TOTAL_EXPENSES,
} from "../constants";
import { AccountBalanceCell } from "./AccountBalanceCell";
import { BaseInputCell } from "./BaseInputCell";
import { NetSaleCell } from "./NetSaleCell";
import { PercentCell } from "./PercentCell";
import { RecoveryCell } from "./RecoveryCell";
import { SubtotalCell } from "./SubtotalCell";
import { TotalExpensesCell } from "./TotalExpensesCell";
import { WorksheetCellProps } from "../types";

const WorksheetCell = ({ children, ...props }: WorksheetCellProps) => {
	switch (props.item?.id) {
		case ACCOUNT_BALANCE.key:
			return <AccountBalanceCell {...props}>{children}</AccountBalanceCell>;
		case CLOSING_COSTS.key:
			return <PercentCell {...props}>{children}</PercentCell>;
		case COMMISSION.key:
			return <PercentCell {...props}>{children}</PercentCell>;
		case TOTAL_EXPENSES.key:
			return <TotalExpensesCell {...props}>{children}</TotalExpensesCell>;
		case NET_SALE.key:
			return <NetSaleCell {...props}>{children}</NetSaleCell>;
		case RECOVERY.key:
			return <RecoveryCell {...props}>{children}</RecoveryCell>;
		case REFURBISHING.key:
			return <SubtotalCell {...props}>{children}</SubtotalCell>;
		case LAND_IMPROVEMENTS.key:
			return <SubtotalCell {...props}>{children}</SubtotalCell>;
		case SALE_PRICE.key:
			return <DataTableCell></DataTableCell>;
		case "header":
			return (
				<DataTableCell>
					<span style={{ fontWeight: "bold" }}>{children}</span>
				</DataTableCell>
			);
		default:
			return <BaseInputCell {...props}>{children}</BaseInputCell>;
	}
};

WorksheetCell.displayName = DataTableCell.displayName;
export { WorksheetCell };
