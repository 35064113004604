import { SLDSInput } from "@salesforce/design-system-react";
import React, { useEffect, useState } from "react";
import { StaticInput } from "../StaticInput";
import { useController, useFormContext } from "react-hook-form";
import { ControlledConcurrency } from "../ControlledConcurrency/ControlledConcurrency";
import dayjs from "dayjs";
import { useFormSection } from "../ControlledForm/FormSection";
import { useIsMobile } from "@/Responsive";

const DateInput = ({ ...props }) => {
	const {
		field: { ref, value, onChange, ...rest },
		fieldState: { isDirty, error },
	} = useController({
		name: props.name,
	});

	const { focusField, setFocus } = useFormContext();
	const { isStatic, onClickEdit, isReadOnly } = useFormSection();

	const isISODate = dayjs(value).isValid() && !/^\d{4}-\d\d-\d\d$/.test(value);

	useEffect(() => {
		if (focusField === props.name) {
			setFocus(props.name);
		}
	}, [focusField, props.name, setFocus]);

	const convertValue = () =>
		isISODate ? dayjs(value).utc(false).format("YYYY-MM-DD") : value ?? "";

	const staticValue = value
		? dayjs(value).utc(false).format("MM/DD/YYYY")
		: value ?? "";
	const [inputValue, setInputValue] = useState(convertValue(value));
	const onChangeHandler = (e, { value }) => {
		setInputValue(value);
		if (dayjs(value).isValid()) {
			onChange(dayjs(value).utc(true).toISOString());
		} else {
			onChange(null);
		}
	};
	const isMobile = useIsMobile();
	const column = isMobile ? (props.span ? props.span : 2) : 2;
	useEffect(() => {
		if (!isDirty) {
			setInputValue(convertValue(value));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);
	return isStatic || isReadOnly ? (
		<StaticInput
			name={props.name}
			readOnly={isReadOnly || props.readOnly}
			key={`field-${props.name}`}
			label={props.label || props.labels.label}
			value={staticValue}
			onClickEdit={() => onClickEdit(props.name)}
			style={{ gridColumn: `span ${props.span ?? 2}` }}
		/>
	) : (
		<span style={{ gridColumn: `span ${column}` }}>
			<SLDSInput
				inputRef={ref}
				errorText={error?.message}
				value={inputValue}
				{...props}
				{...rest}
				onChange={onChangeHandler}
				maxValue="2100-12-31"
				minValue="1900-01-01"
			/>
		</span>
	);
};

export const ControlledDateInput = ({ ...props }) => {
	const {
		field: { value },
	} = useController({
		name: props.name,
	});
	const isMobile = useIsMobile();
	const column = isMobile ? (props.span ? props.span : 2) : 2;
	const convertValue = () =>
		isISODate ? dayjs(value).utc(false).format("YYYY-MM-DD") : value ?? "";
	const isISODate = dayjs(value).isValid() && !/^\d{4}-\d\d-\d\d$/.test(value);
	const { isConcurrencyError, concurrencyDiff } = useFormContext();
	return !isConcurrencyError ? (
		<DateInput {...props} />
	) : (
		<span style={{ gridColumn: `span ${column}` }}>
			{concurrencyDiff[props.name] ? (
				<ControlledConcurrency
					{...props}
					options={dateOptions(concurrencyDiff[props.name])}
				/>
			) : (
				<SLDSInput
					name={props.name}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					value={convertValue(value)}
					readOnly={true}
					{...props}
				/>
			)}
		</span>
	);
};

const dateOptions = (dates) => {
	const dateOptions = dates.map((date) => {
		return {
			label: date
				? dayjs(date).utc(false).format("MM/DD/YYYY")
				: "-- Leave Blank --",
			value: date,
		};
	});
	return dateOptions;
};
