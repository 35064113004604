import { DataTableCell } from "@salesforce/design-system-react";
import styles from "../PricingWorksheet.module.scss";
import { useController } from "react-hook-form";
import { MISC_ESTIMATES_TYPE, MISC_ESTIMATES_TYPE_OPTIONS } from "../constants";
import { WorksheetCellProps } from "../types";

export const WorksheetHeader = ({ item }: WorksheetCellProps) => {
	return item?.id === "headers" ? (
		<tr
			id="headers"
			key="headers"
			className={`slds-line-height_reset ${styles.columnHeaders}`}
		>
			<th id="category" key="category-header" colSpan={1} scope="col">
				<p role="columnheader" style={{ fontWeight: "bold" }}>
					{item.category}
				</p>
			</th>
			<th id="retail" key="retail-header" colSpan={1} scope="col">
				<p role="columnheader">{item.retail}</p>
			</th>
			<th id="wholesale" key="wholesale-header" colSpan={1} scope="col">
				<p role="columnheader">{item.wholesale}</p>
			</th>
			<th id="miscellaneous" key="miscellaneous-header" colSpan={1} scope="col">
				<SelectMiscColumn />
			</th>
			<th id="actual" key="actual-header" colSpan={1} scope="col">
				<p role="columnheader">{item.actual}</p>
			</th>
			<th id="retailVar" key="retailVar-header" colSpan={1} scope="col">
				<p role="columnheader">{item?.retailVar}</p>
			</th>
		</tr>
	) : (
		<tr key="stripe-fix"></tr>
	);
};

WorksheetHeader.displayName = DataTableCell.displayName;

function SelectMiscColumn() {
	const {
		field: { value, onChange },
	} = useController({ name: MISC_ESTIMATES_TYPE.key });

	return (
		<select
			role="columnheader"
			className={`${styles.miscEstTitle} ${styles.pointer}`}
			onChange={(e) => onChange(e.target.value)}
			value={value}
		>
			{MISC_ESTIMATES_TYPE_OPTIONS.map((option, index) => {
				return (
					<option
						key={`${MISC_ESTIMATES_TYPE.key}-option-${index}`}
						value={index}
					>
						{option}
					</option>
				);
			})}
		</select>
	);
}
