export const PRICING_WORKSHEETS_PATH = "marketingInfo.pricingWorksheets";

export const HOME_INVOICE_PRICE = {
	key: "homeInvoicePrice",
	label: "Home Invoice Price",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const ORIGINAL_LAND_APPRAISAL = {
	key: "originalLandAppraisal",
	label: "Original Land Appraisal",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const ORIGINAL_SALES_PRICE = {
	key: "originalSalesPrice",
	label: "Original Sales Price",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const CASH_EQUITY = {
	key: "cashEquity",
	label: "Cash Equity",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const PRICE = {
	key: "price",
	label: "Web Price",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const PURCHASE_OPTION = {
	key: "purchaseOption",
	label: "Purchase Option",
	props: {
		type: "enum",
		span: 4,
		section: "worksheet",
		options: ["Cash Only", "Financing Available", "Unknown"],
		history: false,
	},
};

export const RECOVERY_LAND_CREDIT = {
	key: "recoveryLandCredit",
	label: "Recovery Land Credit",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const HOME_VALUE = {
	key: "homeValue",
	label: "Home Value",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const LAND_VALUE = {
	key: "landValue",
	label: "Land Value",
	props: {
		span: 4,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const F_H_A_APPRAISED_VALUE_WHOLESALE = {
	key: "fhaAppraisedValueWholesale",
	label: "FHA Appraised Value Wholesale",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
	},
};

export const F_H_A_APPRAISED_VALUE_RETAIL = {
	key: "fhaAppraisedValueRetail",
	label: "FHA Appraised Value Retail",
	props: {
		span: 3,
		fixedTextLeft: "$",
		section: "worksheet",
		type: "number",
		step: 100,
	},
};

export const NADA_BASE = {
	key: "nadaBase",
	label: "NADA Base",
	props: {
		span: 4,
		section: "worksheet",
		type: "number",
		step: 100,
		history: true,
	},
};

export const NADA_ADVANCE = {
	key: "nadaAdvance",
	label: "NADA Advance",
	props: {
		section: "worksheet",
		span: 6,
		type: "number",
		step: 100,
	},
};

export const APPROVER_ID = {
	key: "approverId",
	label: "Approver Id",
};

export const APPROVED_DATE = {
	key: "approvedDate",
	label: "Approved Date",
};

export const APPROVAL_STATUS = {
	key: "approvalStatus",
	label: "Approval Status",
};

export const SELECTED_PLAN = {
	key: "selectedPlan",
	label: "Selected Plan",
};

export const ACCOUNT_BALANCE = {
	label: "Account Balance",
	key: "accountBalance",
};

export const BASE_PRICE = {
	label: "Base Price",
	key: "basePrice",
};

export const CREDITS = {
	label: "Credits",
	key: "credits",
};

export const LOT_RENT = {
	label: "Lot Rent",
	key: "lotRent",
};

export const TRANSPORT_TO_HOME_CENTER = {
	label: "Transport To Home Center",
	key: "transportToHomeCenter",
};

export const REFURBISHING = {
	label: "Refurbishing",
	key: "refurbishing",
};

export const APPLIANCES = {
	label: "Appliances",
	key: "appliances",
};

export const HVAC = {
	label: "HVAC",
	key: "hvac",
};

export const DELIVERY = {
	label: "Delivery",
	key: "delivery",
};

export const MOWING = {
	label: "Mowing",
	key: "mowing",
};

export const UTILITIES = {
	label: "Utilities",
	key: "utilities",
};

export const LAND_IMPROVEMENTS = {
	label: "Land Improvements",
	key: "landImprovements",
};

export const CLOSING_COSTS_PERCENT = {
	label: "Closing Cost Percent",
	key: "closingCostsPercent",
};

export const CLOSING_COSTS = {
	label: "Closing Costs",
	key: "closingCosts",
};

export const SURVEY = {
	label: "Survey",
	key: "survey",
};

export const OTHER_EXPENSES = {
	label: "Other Expenses",
	key: "otherExpenses",
};

export const COMMISSION_PERCENT = {
	label: "Commission Percent",
	key: "commissionPercent",
};

export const COMMISSION = {
	label: "Commission",
	key: "commission",
};

export const F_H_A_PREP = {
	label: "FHA Prep",
	key: "fhaPrep",
};

export const TOTAL_EXPENSES = {
	label: "Total Expenses",
	key: "totalExpenses",
};

export const SALE_PRICE = {
	label: "Final Sales Price",
	key: "salePrice",
};

export const NET_SALE = {
	label: "Net Sale",
	key: "netSale",
};

export const RECOVERY = {
	label: "Recovery",
	key: "recovery",
};

export const COMMENTS = {
	label: "Comments",
	key: "comments",
};

export const APPROVAL_STATUS_VALUES = [
	{ label: "Home Not Repossessed", value: "HomeNotRepossessed" },
	{ label: "In Progress", value: "InProgress" },
	{ label: "Pending Approval", value: "PendingApproval" },
	{ label: "Approved", value: "Approved" },
];

export const SELECTED_PLAN_OPTIONS = [
	"Retail",
	"Wholesale",
	"Setting up in Park",
	"Moving to Land",
	"Buying Land Under Home",
];

export const PURCHASE_OPTION_VALUES = [
	"Cash Only",
	"Financing Available",
	"Unknown",
];

export const GENERAL_REFURB = {
	key: "generalRefurb",
	label: "General Refurb",
};
export const TRASH_OUT = {
	key: "trashOut",
	label: "Trash Out",
};
export const DECK = {
	key: "deck",
	label: "Deck",
};
//
export const LAND_PURCHASE_PRICE = {
	key: "landPurchasePrice",
	label: "Land Purchase Price",
};
export const SEPTIC = {
	key: "septic",
	label: "Septic",
};
export const SEPTIC_PERMIT = {
	key: "septicPermit",
	label: "Septic Permit",
};
export const SEWER_LINE = {
	key: "sewerLine",
	label: "Sewer Line",
};
export const WATER_LINE = {
	key: "waterLine",
	label: "Water Line",
};
export const WELL_PUMP = {
	key: "wellPump",
	label: "Well Pump",
};
export const BUILDING_PERMIT = {
	key: "buildingPermit",
	label: "Building Permit",
};
export const LAND_CLEARING = {
	key: "landClearing",
	label: "Land Clearing",
};
export const DRIVEWAY = {
	key: "driveway",
	label: "Driveway",
};
export const GRAVEL = {
	key: "gravel",
	label: "Gravel",
};
export const FOOTERS = {
	key: "footers",
	label: "Footers",
};
export const FRENCH_DRAIN = {
	key: "frenchDrain",
	label: "French Drain",
};
export const ELECTRIC = {
	key: "electric",
	label: "Electric",
};

export const MISC_ESTIMATES_TYPE = {
	key: "miscEstimatesType",
	label: "Miscellanous Estimates Type",
};

export const MISC_ESTIMATES_TYPE_OPTIONS = [
	"Miscellaneous",
	"Setting up in Park",
	"Moving to Land",
	"Buying Land Under Home",
];

export const EMPTY_PRICING_WORKSHEET = {
	homeInvoicePrice: 0,
	originalLandAppraisal: 0,
	originalSalesPrice: 0,
	cashEquity: 0,
	price: 0,
	purchaseOption: null,
	recoveryLandCredit: 0,
	homeValue: 0,
	landValue: 0,
	fhaAppraisedValueWholesale: 0,
	fhaAppraisedValueRetail: 0,
	nadaBase: 0,
	nadaAdvance: 0,
	approverId: null,
	approvedDate: null,
	approvalStatus: 1, //"InProgress",
	selectedPlan: null,
	wholesaleEstimates: {
		accountBalance: 0,
		basePrice: 0,
		credits: 0,
		lotRent: 0,
		transportToHomeCenter: 0,
		appliances: 0,
		hvac: 0,
		delivery: 0,
		mowing: 0,
		utilities: 0,
		closingCostsPercent: 0,
		closingCosts: 0,
		survey: 0,
		otherExpenses: 0,
		otherLandImprovements: 0,
		otherRefurbishing: 0,
		commissionPercent: 0,
		commission: 0,
		fhaPrep: 0,
		totalExpenses: 0,
		salePrice: 0,
		netSale: 0,
		recovery: 0,
		generalRefurb: 0,
		trashOut: 0,
		deck: 0,
		landPurchasePrice: 0,
		septic: 0,
		septicPermit: 0,
		sewerLine: 0,
		waterLine: 0,
		wellPump: 0,
		buildingPermit: 0,
		landClearing: 0,
		driveway: 0,
		gravel: 0,
		footers: 0,
		frenchDrain: 0,
		electric: 0,
	},
	retailEstimates: {
		accountBalance: 0,
		basePrice: 0,
		credits: 0,
		lotRent: 0,
		transportToHomeCenter: 0,
		appliances: 0,
		hvac: 0,
		delivery: 0,
		mowing: 0,
		utilities: 0,
		closingCostsPercent: 0,
		closingCosts: 0,
		survey: 0,
		otherExpenses: 0,
		otherLandImprovements: 0,
		otherRefurbishing: 0,
		commissionPercent: 0,
		commission: 0,
		fhaPrep: 0,
		totalExpenses: 0,
		salePrice: 0,
		netSale: 0,
		recovery: 0,
		generalRefurb: 0,
		trashOut: 0,
		deck: 0,
		landPurchasePrice: 0,
		septic: 0,
		septicPermit: 0,
		sewerLine: 0,
		waterLine: 0,
		wellPump: 0,
		buildingPermit: 0,
		landClearing: 0,
		driveway: 0,
		gravel: 0,
		footers: 0,
		frenchDrain: 0,
		electric: 0,
	},
	miscEstimates: {
		accountBalance: 0,
		basePrice: 0,
		credits: 0,
		lotRent: 0,
		transportToHomeCenter: 0,
		appliances: 0,
		hvac: 0,
		delivery: 0,
		mowing: 0,
		utilities: 0,
		closingCostsPercent: 0,
		closingCosts: 0,
		survey: 0,
		otherExpenses: 0,
		otherLandImprovements: 0,
		otherRefurbishing: 0,
		commissionPercent: 0,
		commission: 0,
		fhaPrep: 0,
		totalExpenses: 0,
		salePrice: 0,
		netSale: 0,
		recovery: 0,
		generalRefurb: 0,
		trashOut: 0,
		deck: 0,
		landPurchasePrice: 0,
		septic: 0,
		septicPermit: 0,
		sewerLine: 0,
		waterLine: 0,
		wellPump: 0,
		buildingPermit: 0,
		landClearing: 0,
		driveway: 0,
		gravel: 0,
		footers: 0,
		frenchDrain: 0,
		electric: 0,
	},
	comments: "",
	concurrencyToken: "",
};

export const APPROVAL_STATUS_OPTIONS = [
	"Home Not Repossessed",
	"In Progress",
	"Pending Approval",
	"Approved",
];

export const OTHER_LAND_IMPROVEMENTS = {
	key: "otherLandImprovements",
	label: "Other Land Improvements Expenses",
};

export const OTHER_REFURBISHING_EXEPENSES = {
	key: "otherRefurbishing",
	label: "Other Refurbishing Expenses",
};
