import React, { useMemo, useState } from "react";
import { Button, Pill } from "@salesforce/design-system-react";
import PropTypes from "prop-types";
import "./AssetCard.scss";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { OfflineAssetButton } from "@/features/offline-use/components/OfflineAssetButton/OfflineAssetButton";
import { useSelectRemarketingStatuses } from "@/features/lookups";
import { useTabs } from "@/hooks/useTabs";
import { FcHighPriority } from "react-icons/fc";
import { AiFillWarning } from "react-icons/ai";
import { useAssetListSplitView } from "../../hooks/useAssetListSplitView";
import { useSplitViewAsset } from "../../hooks/useSplitViewAsset";
import { MdOutlinePending } from "react-icons/md";
import { useAllPendingSyncItems } from "@/features/offline-use/api/hooks/usePendingSyncItems";
import { useIsOnline } from "@/features/offline-use";
import {
	ACCOUNT_ID,
	ACCOUNT_NUMBER,
	ADDRESS,
	ASSET_MANAGER,
	ASSIGNED_DATE,
	CITY,
	CUSTOMER_NAME,
	LAST_INSPECTION,
	REMARKETING_STATUS_TYPE_ID,
	STATE,
	ZIP,
} from "@/features/asset/constants";
import { get } from "lodash";
import { Match } from "../TableValues/TableValues";

const PhotoButton = ({ ...props }) => {
	return (
		<Button
			iconCategory="utility"
			iconName="photo"
			iconSize="small"
			iconVariant="container"
			assistiveText={{ icon: "Media button" }}
			variant="icon"
			onClick={(event) => {
				event.stopPropagation();
				props.onPhotoButtonClick();
			}}
			{...props}
		/>
	);
};

const ExpandButton = ({ ...props }) => {
	return (
		<Button
			iconCategory="utility"
			iconName="expand"
			iconSize="small"
			iconVariant="container"
			assistiveText={{ icon: "Media button" }}
			variant="icon"
			onClick={(event) => {
				event.stopPropagation();
				props.onClick();
			}}
			{...props}
		/>
	);
};

const SplitViewActions = ({ asset, options }) => {
	const navigate = useNavigate();
	const { currentTab } = useTabs("tab");
	return (
		<>
			<ExpandButton
				onClick={(e) => {
					e.stopPropagation();
					navigate(
						"/account/" +
							get(asset, ACCOUNT_ID.path) +
							"/asset/" +
							asset.id +
							`?tab=${currentTab}`
					);
				}}
			/>
		</>
	);
};

export const AssetCard = ({ asset, ...props }) => {
	const navigate = useNavigate();
	const statuses = useSelectRemarketingStatuses();
	const { assetId, setAssetId } = useSplitViewAsset();
	const { isSplitView } = useAssetListSplitView();
	const [showDetails, setShowDetails] = useState(isSplitView);
	const isOnline = useIsOnline();
	const syncItems = useAllPendingSyncItems();
	const hasPendingItems = useMemo(
		() => syncItems.includes(asset?.id),
		[asset.id, syncItems]
	);

	const root =
		"/account/" + get(asset, ACCOUNT_ID.path) + "/asset/" + asset?.id;
	const daysSinceLastInspection = dayjs().diff(
		dayjs(get(asset, LAST_INSPECTION.path)),
		"d"
	);
	const lastInspection = asset?.lastInspection
		? `${daysSinceLastInspection} days ago`
		: "Never";
	const lastInspectionPriorityIcon =
		daysSinceLastInspection >= 60 ? (
			<FcHighPriority />
		) : (
			<AiFillWarning className={"mobile-table-warningSign"} />
		);
	const options = [
		{
			label: "Open Asset Details",
			action: () => navigate(root + "?tab=details"),
		},
		{
			label: "Open Media Manager",
			action: () => navigate(root + "?tab=media"),
		},
	];
	const controls = isSplitView ? (
		<SplitViewActions asset={asset} options={options} />
	) : (
		<>
			<PhotoButton onPhotoButtonClick={props.onPhotoButtonClick} />
		</>
	);
	return (
		<article
			className={"mobile-table-card"}
			style={assetId === asset.id ? { border: "1px solid blue" } : {}}
			onClick={() => {
				if (!isSplitView) {
					setShowDetails(!showDetails);
				} else {
					setAssetId(asset.id);
				}
			}}
		>
			<div className={"mobile-table-info"}>
				<p className={"mobile-table-account"}>
					{isSplitView ? (
						<span style={{ marginLeft: "0px" }}>
							<Match>{get(asset, ACCOUNT_NUMBER.path)}</Match>
						</span>
					) : (
						<a
							href={root + "?tab=details"}
							onClick={(event) => {
								event.preventDefault();
								event.stopPropagation();
								navigate(root + "?tab=details");
							}}
						>
							<Match>
								{get(asset, ACCOUNT_NUMBER.path) ?? (
									<span className="" style={{ color: "grey" }}>
										######
									</span>
								)}
							</Match>
						</a>
					)}
					<span className={"mobile-table-name"}>
						<Match>{get(asset, CUSTOMER_NAME.path) ?? <span>...</span>}</Match>
					</span>
				</p>
				<ul className={"mobile-table-address"}>
					<li>
						<Match>{get(asset, ADDRESS.path)}</Match>
					</li>
					<li>
						<span>
							<Match>
								{get(asset, CITY.path)
									? `${get(asset, CITY.path)},`
									: ".........."}
							</Match>
						</span>{" "}
						<span>
							<Match>{get(asset, STATE.path)}</Match>
						</span>{" "}
						<span>
							<Match>{get(asset, ZIP.path)}</Match>
						</span>
					</li>
				</ul>
			</div>

			<div className="mobile-table-actions">{controls}</div>

			<ul className="mobile-table-status">
				<li>
					<label>Status</label>
					<p>
						<Match>
							{statuses[get(asset, REMARKETING_STATUS_TYPE_ID.path)]}
						</Match>
					</p>
				</li>
			</ul>

			{(!showDetails || !isOnline) && hasPendingItems && (
				<ul>
					<Pill
						variant="option"
						style={{
							position: "absolute",
							bottom: ".25rem",
							right: ".75rem",
							color: "#706e6b",
						}}
					>
						<MdOutlinePending size={"1rem"} />
						Pending Items
					</Pill>
				</ul>
			)}
			{showDetails && (
				<>
					<ul className="mobile-table-dates">
						<li>
							<label>Assigned</label>
							<p>
								{get(asset, ASSET_MANAGER.path) && (
									<span className={"mobile-table-res"}>
										{get(asset, ASSET_MANAGER.path)}
									</span>
								)}
								<span>
									{dayjs(get(asset, ASSIGNED_DATE.path)).format("MM/DD/YYYY")}
								</span>
							</p>
						</li>
					</ul>
					<ul className="mobile-table-lastInspection">
						<li>
							<label>Inspection</label>
							{daysSinceLastInspection >= 45 ? (
								<p>
									{lastInspectionPriorityIcon}
									<span> {lastInspection}</span>
								</p>
							) : (
								<p>{lastInspection}</p>
							)}
						</li>
					</ul>
					{!isSplitView && (
						<OfflineAssetButton
							accountId={get(asset, ACCOUNT_ID.path)}
							assetId={asset?.id}
							variant="card"
						/>
					)}
				</>
			)}
		</article>
	);
};

AssetCard.propTypes = {
	onPhotoButtonClick: PropTypes.func,
};
