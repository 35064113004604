import { useRoles } from "@/features/user/hooks/useRoles";
import {
	ACCOUNTS_PAYABLE,
	ADMIN,
	ASSET_MANAGEMENT,
	AUDIT_MISC,
	BOOKING,
	MANAGEMENT,
	SALES_MARKETING,
	TITLING,
	UAT,
} from "./constants";

const excludingAuditMisc = [
	ASSET_MANAGEMENT,
	BOOKING,
	ACCOUNTS_PAYABLE,
	TITLING,
	MANAGEMENT,
	SALES_MARKETING,
	ADMIN,
	UAT,
];

/**
 *  @param {Array} roles
 */
const useIsRole = (roles) => {
	const { roles: userRoles, isSuccess } = useRoles();
	const intersection = isSuccess ? roles?.filter((el) => userRoles[el]) : [];
	return intersection.length > 0;
};

/*
    Asset List View 
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessAssetList = () => {
	const canWrite = useIsRole(excludingAuditMisc);
	return { canWrite, canRead: true };
};

/*
    Media Manager
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessMediaManager = () => {
	const canWrite = useIsRole(excludingAuditMisc);
	return { canWrite, canRead: true };
};

/*
    Notes
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessNotes = () => {
	const canWrite = useIsRole(excludingAuditMisc);
	return { canWrite, canRead: true };
};

/*
    Asset Detail
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessAssetDetail = () => {
	const canWrite = useIsRole([UAT, ASSET_MANAGEMENT, MANAGEMENT]);
	return { canWrite, canRead: true };
};

/*
    Insurance Queue 
    Write Access determined by the AUDIT_MISC role
    Read Access allowed for every role
*/
export const useAccessInsuranceQueue = () => {
	const canWrite = useIsRole([AUDIT_MISC]);
	return { canWrite, canRead: true };
};

/*
    Imaging 
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessImaging = () => {
	const canWrite = useIsRole(excludingAuditMisc);
	return { canWrite, canRead: true };
};

/*
    Pre Booking 
    Editing write access allowed for every role excluding AUDIT_MISC
    Read Access allowed for every role
*/
export const useAccessPreBooking = () => {
	const canWrite = useIsRole(excludingAuditMisc);
	return { canWrite, canRead: true };
};

/*
    Coordinator Checklist
    Editing write access allowed for Booking and Admin roles
    Read Access allowed for every role
*/
export const useAccessCoordinatorChecklist = () => {
	const canWrite = useIsRole([BOOKING, ADMIN]);
	return { canWrite, canRead: true };
};

/*
    RM Audits
    Editing write access allowed for Management and Admin roles
    Read Access allowed for Asset Management, Management, Admin, Audit_Misc
*/
export const useAccessRMAudits = () => {
	const canWrite = useIsRole([MANAGEMENT, ADMIN]);
	const canRead = useIsRole([ASSET_MANAGEMENT, MANAGEMENT, ADMIN, AUDIT_MISC]);
	return { canWrite, canRead };
};

/*
    Condition Report
    Editing write access allowed for Asset_Management, Management, and Admin roles
	UAT ONLY AT THE MOMENT
    Read Access allowed for every role
*/
export const useAccessConditionReport = () => {
	const canWrite = useIsRole([UAT, ASSET_MANAGEMENT, MANAGEMENT]);
	return { canWrite, canRead: true };
};

/*
    Sales Tax
    Editing write access allowed for Asset_Management, Booking, Sales_Marketing, Admin roles
    Read Access allowed for every role
*/
export const useAccessSalesTax = () => {
	const canWrite = useIsRole([
		ASSET_MANAGEMENT,
		BOOKING,
		SALES_MARKETING,
		ADMIN,
	]);
	return { canWrite, canRead: true };
};

/*
    Admin Page
    Editing write access allowed for Admin role
    Read Access allowed for Admin, Audit_Misc
*/
export const useAccessAdmin = () => {
	const canWrite = useIsRole([ADMIN]);
	const canRead = useIsRole([ADMIN, AUDIT_MISC]);
	return { canWrite, canRead };
};

export const useAccessDocumentation = () => {
	const canWrite = useIsRole([UAT, ASSET_MANAGEMENT, MANAGEMENT]);
	const canRead = useIsRole([UAT, ASSET_MANAGEMENT, MANAGEMENT]);
	return { canWrite, canRead };
};

export const useAccessTripPlanning = () => {
	const canRead = useIsRole([UAT, ASSET_MANAGEMENT, MANAGEMENT]);
	return { canRead };
};

export const useIsManager = () => {
	return useIsRole([MANAGEMENT]);
};

export const useIsUat = () => {
	return useIsRole([UAT]);
};
