import { useCallback, useEffect, useState } from "react";

import { load } from "./config";

export const ConfigProvider = ({ children }) => {
	const [loaded, setLoaded] = useState(false);

	const getConfig = useCallback(async () => {
		await load();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (!loaded) {
			getConfig();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return loaded ? children : null;
};
