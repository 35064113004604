import { Button } from "@salesforce/design-system-react";
import React from "react";
import Backdrop from "./Backdrop";
import classes from "./SideDrawer.module.scss";
import { useUser } from "@/features/user/hooks/useUser";
import { useNavigate } from "react-router-dom";
import config from "@/providers/config/config";
import { MdOutlinePending } from "react-icons/md";
import { useAllPendingSyncItems } from "@/features/offline-use/api/hooks/usePendingSyncItems";
import { isProd } from "@/providers/config/isProduction";
import { useGetAssetDetailsConcurrencyErrorsQuery } from "@/features/offline-use/api/endpoints/syncAssetDetails";
import { AiOutlineWarning } from "react-icons/ai";
import { pca, useIsAuth } from "@/auth";

const NavButton = ({ children, ...props }) => {
	const navigate = useNavigate();
	const handleClick = () => {
		props.onClick();
		navigate(props.to);
	};

	return (
		<Button
			variant="nav"
			style={{
				color: "white",
				margin: 0,
				padding: "1rem",
				borderBottom: "1px solid white",
				marginBottom: "1px",
				marginLeft: "1rem"
			}}
			onClick={handleClick}
		>
			{children}
		</Button>
	);
};

export const SideDrawer = (props) => {
	const { data: user, isSuccess } = useUser();
	const name = `${user?.firstName} ${user?.lastName}`;
	const pendingItems = useAllPendingSyncItems();
	const isAuth = useIsAuth();

	const { data: concurrencyErrors } = useGetAssetDetailsConcurrencyErrorsQuery(
		undefined,
		{
			selectFromResult: ({ data }) => {
				return { data: data ?? {} };
			}
		}
	);

	return (
		<div>
			<Backdrop show={props.open} clicked={props.closed} />
			<div
				className={`${classes.SideDrawerRight} ${
					props.open ? classes.OpenRight : classes.CloseRight
				}`}
			>
				<h2 className={classes.header}>HomeBase</h2>

				<nav
					style={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					<NavButton to="/" onClick={props.closed}>
						<div className={classes.navButton}>Asset List</div>
					</NavButton>

					<NavButton to="/?view=field-use" onClick={props.closed}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<div className={classes.navButton}>Field Use</div>
							<div>
								{pendingItems.length > 0 && (
									<p
										className={classes.pill}
										labels={{ label: "Pending items" }}
									>
										<MdOutlinePending
											size="1rem"
											style={{
												margin: "0 .25rem"
											}}
										/>
										Pending Items
									</p>
								)}
							</div>
						</div>
					</NavButton>

					<NavButton to="/notification" onClick={props.closed}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%"
							}}
						>
							<div className={classes.navButton}>Notifications</div>
							{Object.keys(concurrencyErrors).length > 0 && (
								<p className={classes.pill} labels={{ label: "Pending items" }}>
									<AiOutlineWarning
										size="1rem"
										style={{
											margin: "0 .25rem"
										}}
									/>
									Concurrency
								</p>
							)}
						</div>
					</NavButton>
				</nav>

				<Button
					className={classes.closeBtnRight}
					onClick={props.closed}
					variant="icon"
					iconName="close"
					iconCategory="utility"
					inverse
					iconSize="large"
				></Button>

				{!isProd() && (
					<Button
						className={classes.settings}
						onClick={props.closed}
						variant="icon"
						iconName="settings"
						iconCategory="utility"
						inverse
						iconSize="large"
					></Button>
				)}

				<div className={classes.versionNumber}>
					{isSuccess && (
						<div style={{ marginBottom: "0.5rem" }}>
							<p style={{ fontSize: "1rem" }}>{name}</p>
							<p style={{ fontSize: "1rem" }}>{user?.email}</p>
						</div>
					)}

					{isAuth ? (
						<Button onClick={() => pca.logoutRedirect()} inverse>
							Log Out
						</Button>
					) : (
						<Button onClick={() => pca.loginRedirect()} inverse>
							Login
						</Button>
					)}

					<p>Version {config.VERSION_NUMBER}</p>
				</div>
			</div>
		</div>
	);
};
