import React from "react";
import { createContext, useContext } from "react";

type SaleContextType = {
	saleId: string;
};
const SaleContext = createContext<SaleContextType | null>(null);

export const SaleIdProvider = ({
	saleId,
	children,
}: {
	saleId: string;
	children: React.ReactNode;
}) => {
	return (
		<SaleContext.Provider value={{ saleId }}>{children}</SaleContext.Provider>
	);
};

export const useSaleId = () => {
	const sale = useContext(SaleContext);
	const saleId = sale?.saleId ?? "";

	return {
		saleId,
	};
};
