import {
	createAsyncThunk,
	createSelector,
	createSlice,
	PayloadAction,
	nanoid,
} from "@reduxjs/toolkit";
import { RootState } from "./store";

const openExpiringToast = createAsyncThunk(
	"toasts/expiringToast",
	async (changes: WithTimeout, thunkAPI) => {
		const expiringToast: string = await new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve(changes.id);
			}, changes.timeout);
		});
		return expiringToast;
	}
);

type ToastInfo = {
	id: string,
	labels: { heading: string, details: string},
	timeout?: number,
	variant: string
}
interface WithTimeout extends ToastInfo {
	timeout: number,
}

type ToastState = {
	[id: string]: ToastInfo
}

const initial: ToastState = {}

export const toastSlice = createSlice({
	name: "toasts",
	initialState: initial,
	reducers: {
		openToast: (state, action: PayloadAction<ToastInfo>) => {
			const id = action.payload.id || nanoid();
			state[id] = { ...action.payload };
		},
		closeToast: (state, action: PayloadAction<string>) => {
			delete state[action.payload];
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(openExpiringToast.fulfilled, (state, action: PayloadAction<string>) => {
				delete state[action.payload];
			})
			.addCase(openExpiringToast.pending, (state, action) => {
				const id = action.meta.arg.id || nanoid();
				state[id] = { ...action.meta.arg };
			});
	},
});

const { openToast: openNonExpiringToast, closeToast } = toastSlice.actions;

export const openToast = (args: ToastInfo) => {
	return args.timeout ? openExpiringToast({...args, timeout: args.timeout ?? 3000}) : openNonExpiringToast(args);
};
export { closeToast };

export const selectToasts = createSelector(
	(state: RootState) => state.toasts,
	(toasts) => Object.values(toasts)
);