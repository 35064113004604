import { Button, Combobox } from "@salesforce/design-system-react";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { timePredicates } from "@/Utilities/filtering";
import { BooleanSelect } from "./BooleanSelect";
import { DateSelect } from "./DateSelect";
import { MediaTypeSelect, MediaTypePredicate } from "./MediaTypeSelect";
import { TagsSelect } from "./TagsSelect";

export const filterProperties = {
	tags: {
		label: "Tags",
		value: "tags",
		comparisons: [
			{ label: "include", value: "includes" },
			{ label: "do not include", value: "doesNotInclude" },
			{ label: "includes any", value: "includesAny" },
			{ label: "includes all", value: "includesAll" },
		],
		valueSelect: (props) => <TagsSelect {...props} />,
		formatPredicate: (predicate) => {
			return Array.isArray(predicate) ? predicate.join(" ") : predicate;
		},
	},
	webReady: {
		label: "Web Ready",
		value: "webReady",
		comparisons: [
			{
				label: "is equal to",
				value: "isEqual",
			},
		],
		valueSelect: BooleanSelect,
		formatPredicate: (predicate) => {
			return predicate ? "true" : "false";
		},
	},
	mediaTypeId: {
		label: "Media Type",
		value: "mediaTypeId",
		comparisons: [
			{
				label: "is equal to",
				value: "isEqual",
			},
		],
		valueSelect: (props) => <MediaTypeSelect {...props} />,
		formatPredicate: (predicate) => (
			<MediaTypePredicate predicate={predicate} />
		),
	},
	dateFileCreated: {
		label: "Date Created",
		value: "dateFileCreated",
		comparisons: [
			{
				label: "Date before",
				value: "dateBefore",
			},
			{
				label: "Date after",
				value: "dateAfter",
			},
			{
				label: "Year to date",
				value: "dateBetween",
				range: ["beginningOfYear", "today"],
			},
			{
				label: "Month to date",
				value: "dateBetween",
				range: ["beginningOfMonth", "today"],
			},
			{
				label: "Week to date",
				value: "dateBetween",
				range: ["beginningOfWeek", "today"],
			},
			{
				label: "Custom Range",
				value: "dateBetween",
				range: [],
			},
		],
		valueSelect: (props) => <DateSelect {...props} />,
		formatPredicate: (predicate) => {
			const isArray = Array.isArray(predicate);
			const formatted = isArray
				? predicate.map((date) =>
						dayjs(timePredicates[date] ?? date).format("MM/DD/YYYY")
				  )
				: dayjs(timePredicates[predicate] ?? predicate).format("MM/DD/YYYY");

			return isArray
				? `between ${formatted[0]} and ${formatted[1]}`
				: formatted;
		},
	},
};

function formatProperty(property) {
	const formattedProperty = filterProperties[property].label;

	return formattedProperty;
}

function formattedComparison(property, comparison) {
	const formattedComparison = filterProperties[property].comparisons.find(
		(c) => c.value === comparison
	).label;
	return comparison === "dateBetween" ? "Date" : formattedComparison;
}

export function Filter({
	onUpdateFilter,
	onAddOrUpdateFilter,
	onRemoveFilter,
	isNewFilter,
	...props
}) {
	const [id, setId] = useState(null);

	useEffect(() => {
		if (!!!props.id) {
			const newId = Date.now();
			setId(newId);
		}
		if (props.id) {
			setId(props.id);
		}
	}, [isNewFilter, props.id]);

	const propertyOptions = Object.keys(filterProperties).map((property) => ({
		id: `option-${property}`,
		label: filterProperties[property].label,
		value: property,
	}));

	const [property, setProperty] = useState(props.property || "");
	const [comparison, setComparison] = useState(props.comparison || "");
	const [predicate, setPredicate] = useState(props.predicate || null);

	const propertySelection = propertyOptions.filter(
		(option) => option.value === property
	);

	const comparisonOptions = filterProperties[property]?.comparisons.map(
		(comparison) => ({
			id: `option-${property}-${comparison.label.replace(" ", "-")}`,
			...comparison,
		})
	);

	const matchComparison = comparisonOptions?.filter((option) => {
		const isDate = property === "dateFileCreated";
		const range = Array.isArray(predicate);
		const optionRange = Array.isArray(option.range);
		if (isDate && range && optionRange) {
			return option.range.join("") === predicate.join("");
		}
		return option.value === comparison;
	});

	const customRange = comparisonOptions?.filter(
		(option) => Array.isArray(predicate) && option.label === "Custom Range"
	);

	const comparisonSelection =
		matchComparison?.length > 0 ? matchComparison : customRange;

	const comparisonSelectionDisabled = !property;

	const [isEditing, setIsEditing] = useState(props.isEditing);

	const predicateIsSet = predicate !== null && predicate !== undefined;

	return (
		<>
			{isEditing ? (
				<div className="slds-filters__item">
					<form
						style={{ display: "flex" }}
						onSubmit={(e) => {
							e.preventDefault();
							if (property && comparison && predicateIsSet) {
								onAddOrUpdateFilter({
									id,
									property,
									comparison,
									predicate,
								});
								setIsEditing(false);
							}
						}}
					>
						<div style={{ display: "flex", flexWrap: "wrap" }}>
							<Combobox
								labels={{
									placeholderReadOnly: "Property",
								}}
								variant="readonly"
								value={property}
								selection={propertySelection}
								deselectOption={propertySelection}
								hadDeselect
								options={propertyOptions}
								events={{
									onSelect: (e, { selection: [option] }) => {
										if (comparison) setComparison("");
										if (predicate) setPredicate(null);
										setProperty(option?.value ?? "");
									},
								}}
								menuPosition="relative"
							/>
							<Combobox
								labels={{
									placeholderReadOnly: "Comparison",
								}}
								variant="readonly"
								value={comparison}
								selection={comparisonSelection}
								deselectOption={comparisonSelection}
								hadDeselect
								options={comparisonOptions}
								events={{
									onSelect: (e, { selection: [option] }) => {
										if (property === "dateFileCreated") {
											setPredicate(option?.range || "");
										}
										setComparison(option?.value ?? "");
									},
								}}
								singleInputDisabled={comparisonSelectionDisabled}
								menuPosition="relative"
							/>
							{property && comparison ? (
								filterProperties[property].valueSelect({
									predicate,
									setPredicate,
									...((comparison === "includesAny" ||
										comparison === "includesAll") && { multiple: true }),
								})
							) : (
								<Combobox
									variant="readonly"
									labels={{ placeholderReadOnly: "Value" }}
									singleInputDisabled
								/>
							)}
						</div>
						<div>
							<Button
								type="submit"
								disabled={!(property && comparison && predicateIsSet)}
							>
								Apply
							</Button>
						</div>
					</form>
				</div>
			) : (
				<div
					style={{
						padding: "1rem",
						display: "flex",
						justifyContent: "space-between",
					}}
					className="slds-filters__item"
				>
					<div style={{ width: "60%" }}>
						<h2>{formatProperty(property)}</h2>
						<span style={{ marginRight: ".25rem" }}>
							{formattedComparison(property, comparison)}
						</span>
						<span>{filterProperties[property].formatPredicate(predicate)}</span>
					</div>
					<div
						style={{
							display: "grid",
							justifyContent: "right",
							alignContent: "center",
						}}
					>
						<div>
							<Button
								iconCategory="utility"
								iconName="edit"
								iconVariant="bare"
								variant="icon"
								onClick={() => setIsEditing(true)}
							/>
							<Button
								iconCategory="utility"
								iconName="delete"
								iconVariant="bare"
								variant="icon"
								onClick={() => {
									onRemoveFilter(id);
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
