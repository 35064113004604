export const loanOrigination = [
	"Unknown",
	"Acquired",
	"Originated",
	"Serviced",
];

export const windZone = ["Unknown", "Zone One", "Zone Two", "Zone Three"];
export const dispositionOfTitle = [
	"Unknown",
	"Home Only",
	"Land in Lieu",
	"Land/Home",
];

export const hvacStatus = [
	"Missing",
	"In Place",
	"Needs To Be Replaced",
	"Good Condition",
	"In Storage",
	"Already Replaced",
];
export const hvacType = ["Unknown", "AC", "Heat Pump"];

export const thermalZone = ["Unknown", "Zone One", "Zone Two", "Zone Three"];
export const utilitySystem = ["Unknown", "Gas", "Electric", "Electric And Gas"];
export const wasteSystem = ["Unknown", "Septic", "Sewer"];
export const waterSystem = ["Unknown", "City Water", "Well", "Cistern"];

export const contactPersonType = [
	"LandOwner",
	"Management Company",
	"Park Owner",
	"Sales Center Manager",
	"Asset Manager",
];

export const AssetType = ["Unknown", "Home", "Land", "Home and Land"];

export const roofLoad = ["Unknown", "North", "Middle", "South"];
export const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

export const states = {
	AL: "Alabama",
	AK: "Alaska",
	AS: "American Samoa",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District Of Columbia",
	FM: "Federated States Of Micronesia",
	FL: "Florida",
	GA: "Georgia",
	GU: "Guam",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MH: "Marshall Islands",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	MP: "Northern Mariana Islands",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	PW: "Palau",
	PA: "Pennsylvania",
	PR: "Puerto Rico",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VI: "Virgin Islands",
	VA: "Virginia",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
};

export const lockTypes = [
	"Unknown",
	"Unchanged",
	"Changed This Visit",
	"Changed Previous Visit",
];

export const winterizedType = ["Unknown", "None", "Partial", "Full"];

export const skipReasonType = [
	"None",
	"Age of Home",
	"Community Agreement",
	"Foreclosure Sale",
	"Refurb Exceeds Value of Home",
	"Settlement",
	"Total Loss",
	"Other",
];

const getEnum = (title) => (value) => title[value];

// Export the enum getter functions
export const getSkipReasonType = getEnum(skipReasonType);
export const getWinterizedType = getEnum(winterizedType);
export const getLockType = getEnum(lockTypes);
export const getWindZone = getEnum(windZone);
export const getDispositionOfTitle = getEnum(dispositionOfTitle);
export const getLoanOrigination = getEnum(loanOrigination);
export const getHvacType = getEnum(hvacType);
export const getHvacStatus = getEnum(hvacStatus);
export const getThermalZone = getEnum(thermalZone);
export const getUtilitySystem = getEnum(utilitySystem);
export const getWasteSystem = getEnum(wasteSystem);
export const getWaterSystem = getEnum(waterSystem);
export const getContactPersonType = getEnum(contactPersonType);
export const getMonth = getEnum(months);
export const getAssetType = getEnum(AssetType);
export const getRoofLoad = getEnum(roofLoad);
export const getState = getEnum(states);
