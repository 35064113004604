import React from "react";
import { HomeInformationConfig } from "./HomeInformationConfig";
import classes from "@/Components/ControlledForm.module.scss";
import { Fields } from "@/Components/Form";
import { FormSection } from "@/Components/Form/ControlledForm/FormSection";
import { HOME_INFO_SECTION_TITLE } from "./constants";

const HomeInformationFields = () => {
	return (
		<>
			<div className={classes.formGrid}>
				<Fields config={HomeInformationConfig} />
			</div>
		</>
	);
};

export const HomeInformation = ({ assetId, ...props }) => {
	return (
		<>
			<FormSection title={HOME_INFO_SECTION_TITLE} {...props}>
				<HomeInformationFields />
			</FormSection>
		</>
	);
};
