import { get } from "lodash";
import { each, enforce, test, omitWhen } from "vest";
import "./constants";

import {
	ASSET_TYPE,
	BACKOUT_DATE,
	FHA_TITLE_II_FINANCING,
	FIRST_INSPECTION,
	FOLLOW_UP_DATE,
	INSPECTION_ORDERED,
	LAST_INSPECTION,
	LOAN_TYPE_ID,
	MARKET_READY_DATE,
	PROJECTED_RECOVERY_PERCENT,
	PROJECTED_SALE_MONTH,
	REMARKETING_STATUS_TYPE_ID,
	REPOSSESSION_DATE,
	RETAIL_ASKING_PRICE,
	SAFE_TO_SHOW,
	VMF_FACILITATION,
	WINTERIZED_DATE,
} from "@/features/asset/constants";
import { SPECIAL_INSTRUCTIONS } from "./constants";

export const bookingValidation = (data = {}) => {
	each(
		[FHA_TITLE_II_FINANCING.path, SAFE_TO_SHOW.path, VMF_FACILITATION.path],
		(field) =>
			test(field, "Must be true or false", () => {
				enforce(get(data, field)).oneOf(enforce.isBoolean(), enforce.isNull());
			})
	);

	each(
		[
			BACKOUT_DATE.path,
			FIRST_INSPECTION.path,
			LAST_INSPECTION.path,
			INSPECTION_ORDERED.path,
			FOLLOW_UP_DATE.path,
			REPOSSESSION_DATE.path,
			MARKET_READY_DATE.path,
			WINTERIZED_DATE.path,
		],
		(field) => {
			test(field, "Must be a valid date", () => {
				enforce(get(data, field)).oneOf(enforce.isISODate(), enforce.isNull());
			});
		}
	);

	test(ASSET_TYPE.path, "Invalid Asset Type", () => {
		enforce(get(data, ASSET_TYPE.path)).oneOf(
			enforce.isNumber().isBetween(0, 4),
			enforce.isNull()
		);
	});

	test(RETAIL_ASKING_PRICE.path, "Must be between 0 and $1M", () => {
		enforce(get(data, RETAIL_ASKING_PRICE.path))
			.isNumber()
			.isBetween(0, 1000000);
	});

	test(PROJECTED_SALE_MONTH.path, "Invalid Month", () => {
		enforce(get(data, PROJECTED_SALE_MONTH.path)).oneOf(
			enforce.isNumber().isBetween(0, 11),
			enforce.isNull()
		);
	});

	test(PROJECTED_RECOVERY_PERCENT.path, "Must be a number", () => {
		enforce(get(data, PROJECTED_RECOVERY_PERCENT.path)).oneOf(
			enforce.isNumber(),
			enforce.isNull()
		);
	});

	each([REMARKETING_STATUS_TYPE_ID.path, LOAN_TYPE_ID.path], (field) => {
		test(field, "Invalid Type", () => {
			enforce(get(data, field)).oneOf(enforce.isUUID(), enforce.isNull());
		});
	});

	test(SPECIAL_INSTRUCTIONS, "Notes must be a string", () => {
		enforce(get(data, SPECIAL_INSTRUCTIONS)).isArrayOf(
			enforce.loose({
				note: enforce.oneOf(enforce.isString(), enforce.isNull()),
			})
		);
	});

	omitWhen(
		!Boolean(
			get(data, LAST_INSPECTION.path) && get(data, FIRST_INSPECTION.path)
		),
		() => {
			test(
				LAST_INSPECTION.path,
				"Must be the same or after First Inspection",
				() => {
					enforce(get(data, LAST_INSPECTION.path)).condition((value) => {
						return value >= get(data, FIRST_INSPECTION.path);
					});
				}
			);
		}
	);

	omitWhen(
		!Boolean(
			get(data, MARKET_READY_DATE.path) && get(data, REPOSSESSION_DATE.path)
		),
		() => {
			test(MARKET_READY_DATE.path, "Must be after Repo Date", () => {
				enforce(get(data, MARKET_READY_DATE.path)).condition((value) => {
					return value >= get(data, REPOSSESSION_DATE.path);
				});
			});
		}
	);
};
