import React, { useState } from "react";
import { InsuranceTableHeader } from "./InsuranceTableHeader";
import { ExpandableSection } from "@salesforce/design-system-react";
import { InsuranceCancellationTable } from "../components/InsuranceCancellationTable";
import { Desktop, Mobile } from "@/Responsive";
import { InsuranceCard } from "../components/InsuranceCard";
import { InsuranceFilters } from "../components/InsuranceFilters";
import { useInsuranceQueue } from "../hooks/useInsuranceQueue";

export const InsuranceTableLayout = () => {
	const { data } = useInsuranceQueue();
	const [filtersOpen, setFiltersOpen] = useState(false);

	return (
		<div style={{ backgroundColor: "white" }}>
			<InsuranceTableHeader info={`${data.length} Items`} />
			<ExpandableSection
				id="Filter-controlled-expandable-section"
				isOpen={filtersOpen}
				onToggleOpen={() => {
					setFiltersOpen(!filtersOpen);
				}}
				title="Filters"
			>
				<InsuranceFilters />
			</ExpandableSection>
			<Desktop>
				<InsuranceCancellationTable data={data} />
			</Desktop>
			<Mobile>
				{data.map((account) => (
					<InsuranceCard key={account.id} account={account} />
				))}
			</Mobile>
		</div>
	);
};
