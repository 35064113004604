import React, { useContext, useReducer } from "react";
import "./ImageEditor.scss";
import { editorReducer, initialState } from "./ToastSlice";

const EditorStateContext = React.createContext();
const EditorDispatchContext = React.createContext();

const EditorStateProvider = EditorStateContext.Provider;
const EditorDispatchProvider = EditorDispatchContext.Provider;

export function ImageEditorProvider({ children, ...props }) {
	const [state, dispatch] = useReducer(editorReducer, initialState);

	return (
		<EditorStateProvider value={state}>
			<EditorDispatchProvider value={dispatch}>
				{children}
			</EditorDispatchProvider>
		</EditorStateProvider>
	);
}

export const useEditorState = () => {
	const state = useContext(EditorStateContext);
	return state;
};

export const useEditorDispatch = () => {
	const dispatch = useContext(EditorDispatchContext);
	return dispatch;
};

export const useImageEditor = () => {
	return [useEditorState(), useEditorDispatch()];
};
