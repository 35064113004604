import {
	ControlledCheckbox,
	ControlledInput,
	ControlledTextArea,
	SkipConditionReport,
	LockTypeSelect,
	SkipReason,
} from "../components/Form/ControlledPrice";
import {
	DOOR_STRAP,
	FREE_FORM_DOCUMENTATION,
	HITCH_UNDER_UNIT,
	HUD_NUMBER_VERIFIED,
	KEY_LOCATION,
	labelsIndex,
	LOCK_BOX_CODE,
	LOCK_TYPE,
	OVERVIEW,
	SERIAL_NUMBER_VERIFIED,
	SKIP_CONDITION_REPORT,
	SKIP_REASON,
} from "../constants/constants";

export const overviewConfig = [
	{
		component: ControlledCheckbox,
		props: {
			name: `${OVERVIEW}.${SERIAL_NUMBER_VERIFIED}`,
			costName: SERIAL_NUMBER_VERIFIED,
			label: labelsIndex[SERIAL_NUMBER_VERIFIED],
			defaultValue: false,
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: `${OVERVIEW}.${HUD_NUMBER_VERIFIED}`,
			costName: HUD_NUMBER_VERIFIED,
			label: labelsIndex[HUD_NUMBER_VERIFIED],
			defaultValue: false,
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: `${OVERVIEW}.${HITCH_UNDER_UNIT}`,
			costName: HITCH_UNDER_UNIT,
			label: labelsIndex[HITCH_UNDER_UNIT],
			defaultValue: false,
		},
	},
	{
		component: ControlledCheckbox,
		props: {
			name: `${OVERVIEW}.${DOOR_STRAP}`,
			costName: DOOR_STRAP,
			label: labelsIndex[DOOR_STRAP],
			defaultValue: false,
		},
	},
	{
		component: LockTypeSelect,
		props: {
			name: `${OVERVIEW}.${LOCK_TYPE}`,
			costName: LOCK_TYPE,
			label: labelsIndex[LOCK_TYPE],
			defaultValue: 0,
		},
	},
	{
		component: ControlledInput,
		props: {
			name: `${OVERVIEW}.${KEY_LOCATION}`,
			type: "text",
			label: labelsIndex[KEY_LOCATION],
			defaultValue: "",
		},
	},
	{
		component: ControlledInput,
		props: {
			name: `${OVERVIEW}.${LOCK_BOX_CODE}`,
			type: "text",
			label: labelsIndex[LOCK_BOX_CODE],
			defaultValue: "",
		},
	},
	{
		component: ControlledTextArea,
		props: {
			name: `${OVERVIEW}.${FREE_FORM_DOCUMENTATION}`,
			type: "text",
			label: labelsIndex[FREE_FORM_DOCUMENTATION],
			defaultValue: "",
		},
	},
	{
		component: SkipConditionReport,
		props: {
			name: `${OVERVIEW}.${SKIP_CONDITION_REPORT}`,
			label: labelsIndex[SKIP_CONDITION_REPORT],
			defaultValue: false,
		},
	},
	{
		component: SkipReason,
		props: {
			name: `${OVERVIEW}.${SKIP_REASON}`,
			label: labelsIndex[SKIP_REASON],
			defaultValue: 0,
		},
	},
];
