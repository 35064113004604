import classes from "./FavoriteStar.module.scss";
import { useFavorites } from "@/features/user/hooks/useFavorites";
import { useMemo } from "react";
import { Button } from "@salesforce/design-system-react";

export const FavoriteStar = ({ id }) => {
	const { favorites, toggleFavorite, isSuccess, isLoading, current } =
		useFavorites();

	const isFavorited = useMemo(() => favorites?.includes(id), [favorites, id]);

	return isSuccess && isLoading && current === id ? (
		<Button
			variant="icon"
			iconCategory="utility"
			iconName="spinner"
			onClick={() => {
				if (!isLoading && !current) {
					toggleFavorite(id);
				}
			}}
			style={{ marginLeft: ".25rem" }}
			className={classes.spinner}
		/>
	) : (
		<Button
			variant="icon"
			iconCategory="utility"
			iconName="favorite"
			onClick={() => {
				if (!isLoading && !current) {
					toggleFavorite(id);
				}
			}}
			disabled={current}
			className={
				isSuccess && isFavorited ? classes.favorited : classes.notFavorited
			}
		/>
	);
};
