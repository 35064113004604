import { SLDSInput } from "@salesforce/design-system-react";
import React, { useEffect } from "react";
import { StaticInput } from "../StaticInput";
import { useController, useFormContext } from "react-hook-form";
import { ControlledConcurrency } from "../ControlledConcurrency/ControlledConcurrency";
import { removeSpecialCharacters } from "@/Utilities/utils";
import { useFormSection } from "../ControlledForm/FormSection";
import { useIsMobile } from "@/Responsive";
const Input = ({ ...props }) => {
	const {
		field: { ref, value, ...rest },
		fieldState: { error },
	} = useController({ name: props.name });
	const { focusField, setFocus } = useFormContext();

	const { isStatic, onClickEdit, isReadOnly } = useFormSection();

	useEffect(() => {
		if (focusField === props.name) {
			setFocus(props.name);
		}
	}, [focusField, props.name, setFocus]);
	return (
		<span style={{ gridColumn: `span ${props.span ?? 2}` }}>
			{isStatic ? (
				<StaticInput
					name={props.name}
					readOnly={isReadOnly || props.readOnly}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					value={value ?? ""}
					onClickEdit={() => onClickEdit(props.name)}
					{...props}
				/>
			) : (
				<SLDSInput
					inputRef={ref}
					errorText={error?.message}
					value={value?.toString() ?? ""}
					{...props}
					{...rest}
					onChange={(e, { value }) =>
						rest.onChange(value ? removeSpecialCharacters(value) : null)
					}
					{...(props.type === "number" && {
						onChange: (e, { value }) => {
							rest.onChange(value ? Number(value) : 0);
						},
					})}
				/>
			)}
		</span>
	);
};

export const ControlledInput = ({ ...props }) => {
	const {
		field: { value },
	} = useController({
		name: props.name,
	});
	const { isConcurrencyError, concurrencyDiff } = useFormContext();
	const isMobile = useIsMobile();
	const column = isMobile ? (props.span ? props.span : 2) : 2;
	return !isConcurrencyError ? (
		<Input {...props} />
	) : (
		<span style={{ gridColumn: `span ${column}` }}>
			{concurrencyDiff[props.name] ? (
				<ControlledConcurrency
					{...props}
					options={getConcurrencyOptions(concurrencyDiff[props.name])}
				/>
			) : (
				<SLDSInput
					name={props.name}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					value={value}
					readOnly={true}
					styleInput={{ gridColumn: `span ${props.span ?? 2}` }}
				/>
			)}
		</span>
	);
};

const getConcurrencyOptions = (options) => {
	return options.map((option) => {
		return {
			value: option,
			label: option ? option.toString() : "-- Leave Blank --",
		};
	});
};
