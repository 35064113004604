import { gatewayApi } from "@/ReduxToolkit";
import { packPropertyBag } from "@/ReduxToolkit/MediaProperties";
import { selectPhotoTypeId } from "../selectors/mediaTypes";
import { getFormData } from "./utils";

export const saveCopyEndpoint = {
	queryFn: async (
		{ file, assetId, id, tags, title, imageURL, dataURL },
		{ getState },
		_extra,
		baseQuery
	) => {
		const photoType = selectPhotoTypeId(getState());

		const properties = packPropertyBag({
			webReady: false,
			tags: tags || [],
			order: null,
			isPrimary: false,
			title: title || "",
			propertyBag: [],
		});

		const data = {
			createdBy: "055b1771-f2e1-4b05-9418-c5dc2d594c1a",
			groupingId: assetId,
			file: file,
			MediaTypeId: photoType,
			propertyBag: JSON.stringify(properties),
		};

		const response = await baseQuery({
			url: "api/Media",
			method: "POST",
			headers: {
				"content-type": undefined,
			},
			body: getFormData(data),
		});

		if (response.error) return { error: response.error };

		return response;
	},
	onQueryStarted: async (
		{ id, imageURL, dataURL },
		{ dispatch, getState, queryFulfilled }
	) => {
		const patchResult = dispatch(
			gatewayApi.util.updateQueryData("getMedia", id, (draft) => {
				Object.assign(draft, { path: dataURL });
			})
		);

		try {
			await queryFulfilled;
			patchResult.undo();
		} catch {
			patchResult.undo();
		}
	},
	invalidatesTags: (_result, _error, media) => [
		{ type: "AssetMedia", id: media.assetId },
	],
};
