import { useState } from "react";
import { Input, Modal, InputIcon } from "@salesforce/design-system-react";
import { useSelector } from "react-redux";
import { selectPricing } from "../../api/selectors";
import { useDispatch } from "react-redux";
import { setPricingValue } from "@/ReduxToolkit/ConditionReportSlice";
import classes from "./PricingModal.module.scss";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const PricingModal = NiceModal.create(() => {
	const modal = useModal();
	const dispatch = useDispatch();
	const [search, setSearch] = useState("");
	const pricing = useSelector(selectPricing());

	const camelToTitleCase = (value) => {
		const result = value.replace(/([A-Z])/g, " $1");
		const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
		return finalResult;
	};

	return (
		<Modal
			isOpen={modal.visible}
			onRequestClose={modal.remove}
			heading="Condition Report Pricing"
		>
			<div style={{ padding: "1rem", minHeight: "50vh" }}>
				<div className={classes.searchContainer}>
					<Input
						iconLeft={
							<InputIcon
								assistiveText={{
									icon: "Search",
								}}
								name="search"
								category="utility"
							/>
						}
						iconRight={
							<InputIcon
								assistiveText={{
									icon: "Clear",
								}}
								name="clear"
								category="utility"
								onClick={() => {
									setSearch("");
								}}
							/>
						}
						id="unique-id-2"
						placeholder="Search for Pricing"
						onChange={(event, { value }) => {
							setSearch(value);
						}}
						value={search}
						label="Search Pricing"
					/>
				</div>

				{Object.keys(pricing)
					.filter((unit) =>
						camelToTitleCase(unit).toLowerCase().includes(search.toLowerCase())
					)
					.map((unit) => (
						<Input
							label={camelToTitleCase(unit)}
							key={unit}
							type="number"
							value={pricing[unit]}
							onChange={(event, { value }) => {
								dispatch(
									setPricingValue({ unit: unit, price: Number(value) ?? 0 })
								);
							}}
						/>
					))}
			</div>
		</Modal>
	);
});

NiceModal.register("pricing-modal", PricingModal);

export const usePricingModal = () => useModal("pricing-modal");
