import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";

export const useHomeDisposition = (assetId: string) => {
	const { data: disposition, isSuccess } = useGetAssetQuery(assetId, {
		selectFromResult: ({ data, isSuccess }) => {
			return {
				data: data?.dispositions?.[0],
				isSuccess,
			};
		},
	});

	return { disposition, isSuccess };
};
