import {
	useSearchCommunitiesMutation,
	useGetCommunityByIdQuery
} from "./endpoints";

export const useCommunities = () => {
	const [trigger, { isSuccess, data: result }] = useSearchCommunitiesMutation();
	const search = (formData) => {
		trigger(formData);
	};
	return { search, result, isSuccess };
};

export const useCommunity = (id) => {
	const { data, isSuccess, isError } = useGetCommunityByIdQuery(id, {
		skip: id === null
	});
	if (!id) {
		return {
			data: ""
		};
	}
	return { data, isSuccess, isError };
};
