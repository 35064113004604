import { useGetInsuranceQueueQuery } from "@/ReduxToolkit/GatewayApi";
import { useSelector } from "react-redux";
import { selectInsuranceQueueFilters } from "../store/insuranceQueueSlice";

export const useInsuranceQueue = () => {
	const { data, ...rest } = useGetInsuranceQueueQuery();

	const filterValues = useSelector(selectInsuranceQueueFilters);

	const filters = (assets) => {
		let newTableData = data || [];
		for (let i in filterValues) {
			if (filterValues[i] !== "") {
				newTableData = newTableData.filter((value) =>
					value[i]
						?.toString()
						.toUpperCase()
						.includes(filterValues[i].toUpperCase())
				);
			}
		}
		return newTableData;
	};

	return { data: filters(data) || [], ...rest };
};
