import { useAssetDetails } from "@/features/asset-details-tab/api/useAssetDetails";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Modal } from "@salesforce/design-system-react";
import classes from "./LocationModal.module.scss";
import Spinner from "@/Components/UI/Spinner/Spinner";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Form, SubmitButtons } from "@/Components/Form";
import { ControlledInput as Input } from "@/Components/Form";
import { ControlledAddress as Address } from "@/features/asset-details-tab/components/location/ControlledAddress";
import { StateSelect } from "@/features/enums/EnumSelect";
import {
	ADDRESS,
	ADDRESS_2,
	CITY,
	COUNTY,
	LATITUDE,
	LONGITUDE,
	STATE,
	ZIP,
} from "@/features/asset/constants";
import { FormSection } from "@/Components/Form/ControlledForm/FormSection";
import { useFormContext } from "react-hook-form";

export const LOCATION_MODAL_TITLE = "Location Modal";

const containerStyle = {
	width: "100%",
	height: "100%",
};

const MapComponent = () => {
	const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: apiKey,
	});

	const { setValue, watch } = useFormContext();
	const onPointDrag = (lat, long) => {
		setValue(LATITUDE.path, lat, {
			shouldDirty: true,
		});
		setValue(LONGITUDE.path, long, {
			shouldDirty: true,
		});
	};

	const longitude = watch(LONGITUDE.path);

	const latitude = watch(LATITUDE.path);
	const point = {
		lat: Number(latitude),
		lng: Number(longitude),
	};
	return isLoaded ? (
		<GoogleMap mapContainerStyle={containerStyle} center={point} zoom={13}>
			<Marker
				draggable
				onDragEnd={(e) => onPointDrag(e.latLng.lat(), e.latLng.lng())}
				position={point}
			/>
		</GoogleMap>
	) : (
		<></>
	);
};

const AddressSearchForm = ({ id, onUpdate }) => {
	return (
		<Form
			assetId={id}
			options={{
				isStatic: false,
			}}
			onUpdate={onUpdate}
		>
			<FormSection
				isExpandable={false}
				title={LOCATION_MODAL_TITLE}
				isStatic={false}
			>
				<div className={classes.form}>
					<div style={containerStyle}>
						<MapComponent />
					</div>
					<div>
						<Address name={ADDRESS.path} label="Address" />
						<Input name={ADDRESS_2.path} label="Address 2" />
						<Input name={CITY.path} label="City" />
						<StateSelect name={STATE.path} labels={{ label: "State" }} />
						<Input name={ZIP.path} label="Zip" />
						<Input name={COUNTY.path} label="County" readOnly />
						<Input name={LONGITUDE.path} label="Longitude" />
						<Input name={LATITUDE.path} label="Latitude" />
						<SubmitButtons />
					</div>
				</div>
			</FormSection>
		</Form>
	);
};

export const LocationModal = NiceModal.create(({ assetId, onUpdate }) => {
	const modal = useModal();
	const onClose = () => {
		modal.remove();
	};
	const { isSuccess } = useAssetDetails(assetId);
	return (
		<Modal
			size="large"
			heading="Update Asset Location"
			isOpen={modal.visible}
			onRequestClose={onClose}
		>
			<div className={classes.contentContainer}>
				{isSuccess ? (
					<AddressSearchForm id={assetId} onUpdate={onUpdate} />
				) : (
					<Spinner />
				)}
			</div>
		</Modal>
	);
});

NiceModal.register("asset-location-modal", LocationModal);

export const useLocationModal = () => {
	return useModal("asset-location-modal");
};
