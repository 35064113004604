import dayjs from "dayjs";
import { get } from "lodash";

const resCodes = {
	WK: { lastName: "Kyser", firstName: "Wes" },
	MEC: { firstName: "Clary", lastName: "Mike" },
	TW: { firstName: "Wilson", lastName: "Thereasa" },
	AG1: { firstName: "Gough", lastName: "Austin" },
	BC1: { firstName: "Cowen", lastName: "Byron" },
	RV1: { firstName: "Vaughan", lastName: "Richard" },
	JB4: { firstName: "Bolt", lastName: "James" },
	SR1: { firstName: "Russell", lastName: "Scott" },
	MW1: { firstName: "Wiggins", lastName: "Marshall" },
	TC: { firstName: "Caudill", lastName: "Timothy" },
	BC3: { firstName: "Cudzilo", lastName: "Ben" },
	KB4: { firstName: "Blanton", lastName: "Keith" },
	GD1: { firstName: "Deason", lastName: "Glenn" },
	WS1: { firstName: "Snyder", lastName: "Walter" },
	RR1: { firstName: "Romero", lastName: "Raul" },
	TB2: { firstName: "Brock", lastName: "Tyler" },
	TR4: { firstName: "Ray", lastName: "Travis" },
	JW1: { firstName: "Walker", lastName: "James" },
	BB1: { firstName: "Bassett", lastName: "Bryan" },
	DWM: { firstName: "Martin", lastName: "William" },
	RRM: { firstName: "Moore", lastName: "Rusty" },
	KN1: { firstName: "Nelson", lastName: "Kevin" },
	WM: { firstName: "Mercer", lastName: "William" },
	CR: { firstName: "Roche", lastName: "Chip" },
	KB3: { firstName: "Browning", lastName: "Ken" },
	KJ: { firstName: "Joyner", lastName: "Kelly" },
	JC4: { firstName: "Clemons", lastName: "Jonathan" },
	LL: { firstName: "Coe", lastName: "Lauren" },
	RJM: { firstName: "Manuel", lastName: "Randy" },
	PB1: { firstName: "Brackins", lastName: "Phil" },
	BH1: { firstName: "Holt", lastName: "Brandon" },
	LT3: { firstName: "Minor", lastName: "Latonya" },
};

export const camelToTitleCase = (value) => {
	const result = value.replace(/([A-Z])/g, " $1");
	const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
	return finalResult;
};

export const resCode = (res) => {
	return resCodes[res]
		? `${resCodes[res].firstName[0]}. ${resCodes[res].lastName}`
		: res;
};

export const assetListStringify = (value) => {
	if (Array.isArray(value)) {
		return `[${value.join(",")}]`;
	} else {
		return String(value);
	}
};

export const getFormattedDate = (date) => {
	const returnedDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
	return returnedDate;
};

export const toFlatPropertyMap = (obj, keySeparator = ".") => {
	const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
		const isArray = Array.isArray(obj);
		for (const [key, value] of Object.entries(obj)) {
			const stringValue = isArray
				? `${parentProperty}[${key}]`
				: `${parentProperty}${keySeparator}${key}`;

			const property = parentProperty ? stringValue : key;
			if (value && typeof value === "object") {
				flattenRecursive(value, property, propertyMap);
			} else {
				propertyMap[property] = value;
			}
		}
		return propertyMap;
	};
	return flattenRecursive(obj);
};

export const diffConcurrencyData = (serverData, submissionData) => {
	const server = toFlatPropertyMap(serverData);
	const submission = toFlatPropertyMap(submissionData);

	let result = {};

	const token = /concurrencyToken/i;

	for (const [key, value] of Object.entries(server)) {
		if (value !== submission[key] && !token.test(key)) {
			result[key] = [value, submission[key]];
		}
	}

	return result;
};

/*
	This will remove the following special characters from an input box
	; < > [ ] { } & $ %
*/
export const removeSpecialCharacters = (value) => {
	return value.replace(/[;[\]<>{}&$%]/g, "");
};

export const createPatch = (obj1, obj2) => {
	const flattenAndCompare = (obj1, obj2, parentProperty = "", result = []) => {
		const isArray = Array.isArray(obj2);

		if (isArray) {
			const newItems = obj2.filter((item) => item.id === undefined);

			const removedItems = get(obj1, parentProperty)
				.map((item, index) => {
					const obj = obj2.find(({ id }) => id && id === item.id);
					return !obj && index;
				})
				.filter((item) => item);

			removedItems.forEach((index) => {
				result.push({
					op: "remove",
					path: `/${replacePath(parentProperty)}/${index}`,
				});
			});

			obj2
				.filter((item) => item.id)
				.forEach((item, index) => {
					for (const [key, value] of Object.entries(item)) {
						// (_, index) => {
						if (value && typeof value === "object") {
							flattenAndCompare(
								obj1,
								value,
								`/${replacePath(parentProperty)}/${index}/${key}`,
								result
							);
						}

						if (value !== get(obj1, parentProperty)?.[index]?.[key]) {
							result.push({
								op: "add",
								path: `/${replacePath(parentProperty)}/${index}/${key}`,
								value: value, //obj2[get(obj1, parentProperty).length + index],
							});
						}
					}
				});

			if (
				get(obj1, parentProperty).length >=
				obj2.filter((item) => item.id).length
			) {
				newItems.forEach((item) => {
					result.push({
						op: "add",
						path: `/${replacePath(parentProperty)}/-`,
						value: item,
					});
				});
			}
		} else {
			for (const [key, value] of Object.entries(obj2)) {
				const stringValue = isArray
					? `${parentProperty}[${key}]`
					: `${parentProperty}.${key}`;
				const property = parentProperty ? stringValue : key;

				if (value && typeof value === "object") {
					flattenAndCompare(obj1, value, property, result);
				} else {
					if (key === "concurrencyToken") {
						result.unshift({
							op: "add",
							path: `/${replacePath(property)}`,
							value,
						});
					}

					if (
						value !== get(obj1, property) &&
						get(obj1, property) !== undefined
					) {
						result.push({
							op: "replace",
							path: `/${replacePath(property)}`,
							value,
						});
					}
				}
			}
		}

		return result;
	};

	return flattenAndCompare(obj1, obj2);
};

function replacePath(path) {
	const re = /(\.|\[|\])+/gm;
	return path.replace(re, "/");
}

export const isVideo = (file) => {
	return /video/.test(file.type)
};
