import React, { useRef, useState } from "react";
import {
	Button,
	DynamicIcon,
	Icon,
	SLDSCombobox,
	SLDSModal
} from "@salesforce/design-system-react";
import { GiCloudUpload } from "react-icons/gi";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useUploadImagingDocumentMutation } from "../api/endpoints";
import {
	FormProvider,
	useController,
	useForm,
	useFormContext,
	useWatch
} from "react-hook-form";
import { vestResolver } from "@hookform/resolvers/vest";
import { uploadImagingValidation } from "./uploadImagingValidation";
import classes from "./UploadImaging.module.scss";
import { useGetDocumentSubtypesQuery } from "../api/endpoints";

export const UploadImaging = NiceModal.create(({ accountNumber }) => {
	const [error, setError] = useState(false);
	const [uploadSuccessful, setUploadSuccesful] = useState(false);
	const { reset, ...methods } = useForm({
		resolver: vestResolver(uploadImagingValidation),
		defaultValues: {
			accountNumber: accountNumber,
			cabinetCode: "REMARKET",
			documentSubtype: "",
			document: null
		},
		mode: "onChange"
	});

	const modal = useModal();
	const [trigger, { isLoading }] = useUploadImagingDocumentMutation();
	const upload = async (data) => {
		const res = await trigger(data);
		if (res?.error) {
			setError(true);
		} else {
			setUploadSuccesful(true);
			setTimeout(() => {
				setUploadSuccesful(false);
				reset();
			}, 3000);
		}
	};
	const handleClose = () => {
		modal.remove();
	};

	return (
		<SLDSModal
			onRequestClose={handleClose}
			isOpen={modal.visible}
			heading="Upload Imaging Document"
			footer={
				<div style={{ display: "grid", height: "2rem", placeItems: "center" }}>
					<div>
						<Button
							disabled={!methods.formState.isDirty}
							onClick={() => reset()}
						>
							Cancel
						</Button>
						<Button
							disabled={
								!methods.formState.isDirty || isLoading || uploadSuccessful
							}
							variant={uploadSuccessful ? "success" : "brand"}
							type="submit"
							value="submit"
							form="upload-imaging-document-form"
						>
							{uploadSuccessful && (
								<p style={{ color: "white" }}>Upload Succesful!</p>
							)}
							{!uploadSuccessful &&
								(isLoading ? (
									<DynamicIcon
										className={classes.dynamicIcon}
										title="waiting"
										variant="typing"
										size="xx-small"
									/>
								) : (
									"Upload"
								))}
						</Button>
					</div>
				</div>
			}
		>
			<FormProvider {...methods}>
				<form
					id="upload-imaging-document-form"
					className={classes.formContainer}
					onSubmit={methods.handleSubmit(upload)}
				>
					{error && (
						<p style={{ color: "red" }}>
							Something went wrong when uploading the document. Please report
							the error to the Service Desk
						</p>
					)}
					<UploadDropZone name="document" />
					<DocumentSubtype name="documentSubtype" />
				</form>
			</FormProvider>
		</SLDSModal>
	);
});

NiceModal.register("upload-imaging", UploadImaging);
export const useUploadImagingModal = () => useModal("upload-imaging");

const UploadDropZone = ({ name }) => {
	const fileInputRef = useRef();
	const [isDraggedOver, setIsDraggedOver] = useState(false);
	const {
		field: { value }
	} = useController({ name });

	const { ...methods } = useFormContext();

	const handleDroppedUpload = (e) => {
		e.stopPropagation();
		e.preventDefault();
		const file = e.dataTransfer.files[0];
		// Only supporting application/pdf as of now
		if (file.type === "application/pdf") {
			methods.setValue(name, file, {
				shouldDirty: true,
				shouldTouch: true
			});
		}
		setIsDraggedOver(false);
	};

	const handleClick = (e) => {
		const file = e.target.files[0];
		methods.setValue(name, file, {
			shouldDirty: true,
			shouldTouch: true
		});
		e.target.value = null;
	};
	const handleFileUpload = () => {
		fileInputRef.current.click();
	};

	if (value) {
		const docType = value.type === "application/pdf" ? "pdf" : "image";
		return (
			<div style={{ display: "flex", alignItems: "center" }}>
				<Icon
					assistiveText={{ label: "Document Type" }}
					category="doctype"
					name={docType}
					size="medium"
				/>
				<p style={{ paddingLeft: ".5rem" }}>{value.name}</p>
			</div>
		);
	}

	return (
		<div
			className={`${classes.dropContainer} ${
				isDraggedOver ? classes.draggedOver : classes.notDraggedOver
			}`}
			onDragOver={(e) => {
				e.preventDefault();
				setIsDraggedOver(true);
			}}
			accept="application/pdf"
			onDragLeave={() => setIsDraggedOver(false)}
			onDrop={handleDroppedUpload}
		>
			<GiCloudUpload
				style={{ fill: "#18A0FB", width: "3rem", height: "3rem" }}
			/>
			<p style={{ margin: ".5rem" }}>Drag and Drop a PDF to Upload or</p>
			<Button
				htmlFor="file-upload"
				assistiveText={{ icon: "Add media" }}
				onClick={() => handleFileUpload()}
			>
				Choose file
			</Button>
			<input
				style={{ display: "none" }}
				type="file"
				id="file-upload-hidden"
				accept="application/pdf"
				multiple={false}
				ref={fileInputRef}
				onChange={handleClick}
			/>
		</div>
	);
};

const DocumentSubtype = ({ name, ...props }) => {
	const {
		field: { ...rest },
		fieldState: { error }
	} = useController({ name });
	const { data: subtypes, isSuccess } = useGetDocumentSubtypesQuery();

	const doc = useWatch({ name: "document" });

	if (!doc || !isSuccess) {
		return <></>;
	}
	const options = Object.entries(subtypes)?.map(([id, description]) => {
		return {
			id: `${subtypes}-option-${name}-${description}`,
			value: id,
			label: `${id} - ${description}`
		};
	});
	const selection = options.filter((option) => {
		return option?.value?.toString() === rest.value?.toString();
	});
	return (
		<SLDSCombobox
			labels={{
				label: "Document Type",
				placeholder: "Select a document type"
			}}
			events={{
				onSelect: (e, { selection: [selection] }) => {
					rest.onChange(selection?.value ?? null);
				}
			}}
			selection={selection}
			errorText={error?.message}
			multiple={false}
			required={true}
			options={options}
			variant="readonly"
		/>
	);
};
