export const heatPumps = {
	"0109316": {
		system: "2 ton 14 SEER Package HP",
		price: 1957.12,
	},
	"0109317": {
		system: "2.5 ton 14 SEER Package HP",
		price: 2037.06,
	},

	"0109318": {
		system: "3 ton 14 SEER Package HP",
		price: 2112.87,
	},
	"0109319": {
		system: "3.5 ton 14 SEER Package HP",
		price: 2278.26,
	},
	"0109320": {
		system: "4 ton 14 SEER Package HP",
		price: 2418.85,
	},

	"0109321": {
		system: "5 ton 14 SEER Package HP",
		price: 2516.69,
	},
};

export const getHeatPumpPricing = () => {
	return Object.values(heatPumps).map((val) => val.price);
};
