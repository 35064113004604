import React, { useState } from "react";
import { useController, Control, UseFormWatch } from "react-hook-form";
import { BuyerFormType } from "./NewBuyer";
import { useGetStatesQuery } from "../../api/endpoints";
import {
	SLDSInput,
	Combobox,
	SLDSCheckbox,
} from "@salesforce/design-system-react";
import classes from "./BuyerForm.module.scss";
import { useSelectIdentificationTypeInformation } from "@/features/lookups/api/index";
import dayjs from "dayjs";
import { isISODate } from "@/lib/vest";

export const State = ({
	required,
	name,
	label,
	control,
}: {
	required?: boolean;
	control: Control<BuyerFormType, any, BuyerFormType>;
	name: "driversLicense.state" | "state";
	label: string;
}) => {
	const {
		field,
		fieldState: { error },
	} = useController({
		name: name,
		control: control,
	});

	const { data: statesQueryData } = useGetStatesQuery();
	const states = statesQueryData
		?.map((item) => {
			return {
				label: item.name,
				value: item.id,
				id: item.id,
			};
		})
		.sort((a, b) => {
			if (a.label > b.label) return 1;
			if (b.label > a.label) return -1;

			return 0;
		});

	return (
		<div className={`slds-form-element ${error && "slds-has-error"} `}>
			<label className="slds-form-element__label">
				{required && (
					<abbr className="slds-required" title="required">
						*
					</abbr>
				)}
				{label}
			</label>
			<select
				defaultValue=""
				{...field}
				value={field.value?.toString()}
				className="slds-input"
			>
				<option hidden disabled value="">
					-- select an option --
				</option>
				{states?.map((state) => {
					return (
						<option key={state.id} value={state.value}>
							{state.label}
						</option>
					);
				})}
			</select>
			{error && <div className="slds-form-element__help">{error?.message}</div>}
		</div>
	);
};

export const Input = ({
	control,
	name,
	label,
	placeholder,
	required = false,
	className,
	type = "text",
}: {
	className?: string;
	type?: string;
	required?: boolean;
	name: keyof BuyerFormType;
	placeholder?: string;
	control: Control<BuyerFormType, any, BuyerFormType>;
	label: string;
}) => {
	const {
		field,
		fieldState: { error },
	} = useController({
		name: name,
		control: control,
	});

	return (
		<SLDSInput
			{...field}
			value={field.value ?? ""}
			errorText={error?.message}
			className={className}
			placeholder={placeholder}
			type={type}
			required={required}
			label={label}
		/>
	);
};

export const DriversLicenseNumber = ({
	watch,
	control,
}: {
	watch: UseFormWatch<BuyerFormType>;
	control: Control<BuyerFormType, any, BuyerFormType>;
}) => {
	const driversLicenseState = watch("driversLicense.state");
	const { data: statesQueryData } = useGetStatesQuery();

	const stateLicenseInfo = statesQueryData?.find(
		(item) => item.id === driversLicenseState
	);

	const regex = stateLicenseInfo
		? new RegExp(stateLicenseInfo?.numberValidation)
		: /[A-Za-z0-9]+/i;
	const {
		field: { value, ...rest },
	} = useController({
		name: "driversLicense.number",
		control: control,
		rules: {
			pattern: regex,
		},
	});

	const isValid = () => {
		if (!value) {
			return true;
		} else {
			return regex.test(value?.toString());
		}
	};

	return (
		<SLDSInput
			label="Number"
			{...rest}
			value={value}
			errorText={
				isValid() ? "" : `Must match pattern ${stateLicenseInfo?.numberFormat}`
			}
			placeholder={
				stateLicenseInfo ? stateLicenseInfo.numberFormat : "Drivers License #"
			}
		/>
	);
};

const validateFunction = (input: string) => {
	if (!input) {
		return true
	} else return dayjs(input).format("MM/DD/YYYY") === input
}

export const DateInput = ({
	control,
	name,
	label,
	placeholder,
	required = false,
	className,
}: {
	className?: string;
	required?: boolean;
	name: keyof BuyerFormType | "driversLicense.expiration";
	placeholder?: string;
	control: Control<BuyerFormType, any, BuyerFormType>;
	label: string;
}) => {

	const { field,
		fieldState: { error },
	} = useController({
		name: name,
		control: control,
	});

	const convertValue = (value: string) =>
		isISODate(value) ? dayjs(value).utc(false).format("MM/DD/YYYY") : value;

	const onChangeHandler = (e: any, { value }: { value: string }) => {

		if (!value) {
			field.onChange(null);
		}
		else if (validateFunction(value)) {
			field.onChange(dayjs(value).utc(false).toISOString());

		} else {
			field.onChange(value);
		}
	};

	return (
		<SLDSInput
			{...field}
			errorText={
				error ? error.message : ""}
			value={convertValue(field.value?.toString() ?? "")}
			className={`${className} ${classes.dateInput}`}
			onChange={onChangeHandler}
			placeholder={placeholder}
			required={required}
			label={label}
		/>
	);
};

type IdentificationOptions = {
	id: number;
	value: number;
	label: string;
};
export const IdentificationTypesSelect = ({
	control,
}: {
	control: Control<BuyerFormType, any, BuyerFormType>;
}) => {
	const [inputValue, setInputValue] = useState<string>("");
	const data = useSelectIdentificationTypeInformation();

	const options = Object.entries(data).map(([key, value]) => {
		return {
			id: key,
			value: parseInt(key),
			label: value,
		};
	});
	const { field } = useController({
		name: "identificationTypes",
		control: control,
	});

	const selection = options.filter((item) => field.value?.includes(item.value));
	return (
		<Combobox
			classNameContainer={classes.spanTwo}
			{...field}
			options={options}
			events={{
				onSelect: (
					e: any,
					{ selection }: { selection: IdentificationOptions[] }
				) => {
					field.onChange(selection.map((item) => item.value));
				},
				onChange: (e: any, { value }: { value: string }) => {
					setInputValue(value);
				},
				onRequestRemoveSelectedOption: (
					e: any,
					{ selection }: { selection: IdentificationOptions[] }
				) => {
					field.onChange(selection.map((item) => item.value));
				},
			}}
			value={inputValue}
			multiple
			labels={{
				label: "Identification Methods",
				placeholder: "-- select identification method --",
			}}
			selection={selection}
		/>
	);
};

export const Checkbox = ({
	control,
	name,
	label,
}: {
	name: keyof BuyerFormType | "driversLicense.expiration";
	control: Control<BuyerFormType, any, BuyerFormType>;
	label: string;
}) => {
	const { field } = useController<BuyerFormType>({
		name: name,
		control: control,
	});

	return (
		<div className="slds-form-element">
			<label htmlFor={`checkbox-${name}`} className="slds-form-element__label">
				{label}
			</label>
			<SLDSCheckbox id={`checkbox-${name}`} checked={field.value} {...field} />
		</div>
	);
};
