import { get } from "lodash";
import { test, enforce, each } from "vest";
import {
	BROKERAGE_NAME,
	LISTING_AGENT_NAME,
	LISTING_AGENT_EMAIL,
	LISTING_EXPIRATION,
	MLS_NUMBER,
	LISTING_AGENT_PHONE,
} from "@/features/asset/constants";

export const listingValidation = (data = {}) => {
	// BROKERAGE_NAME
	// LISTING_AGENT_NAME
	each([BROKERAGE_NAME.path, LISTING_AGENT_NAME.path], (field) => {
		test(field, "Must be a String", () => {
			enforce(get(data, field)).oneOf(enforce.isString(), enforce.isNull());
		});
	});

	// LISTING_AGENT_EMAIL
	test(LISTING_AGENT_EMAIL.path, "Must be a valid email", () => {
		enforce(get(data, LISTING_AGENT_EMAIL.path)).anyOf(
			enforce.isEmail(),
			enforce.isEmpty(),
			enforce.isNull()
		);
	});

	// LISTING_EXPIRATION
	test(LISTING_EXPIRATION.path, "Must be a valid date", () => {
		enforce(get(data, LISTING_EXPIRATION.path)).oneOf(
			enforce.isISODate(),
			enforce.isNull()
		);
	});

	// LISTING_AGENT_PHONE
	test(
		LISTING_AGENT_PHONE.path,
		"Must be a 10 digit phone number (##########)",
		() => {
			enforce(get(data, LISTING_AGENT_PHONE.path)).anyOf(
				enforce.isNumeric().lengthEquals(10),
				enforce.isEmpty(),
				enforce.isNull()
			);
		}
	);

	// MLS_NUMBER
	test(MLS_NUMBER.path, "Must only contain numbers and letters", () => {
		enforce(get(data, MLS_NUMBER.path)).anyOf(
			enforce.isEmpty(),
			enforce.isAlphanumeric(),
			enforce.isNull()
		);
	});
};
