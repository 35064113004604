import { ReactNode, createContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface FormFocusContextType {
	focusField: string | null;
	setFocusField: (field: string | null) => void;
}
export const FormFocusContext = createContext({} as FormFocusContextType);

export const FormFocusProvider = ({ children }: { children: ReactNode }) => {
	const { setFocus } = useFormContext();
	const [focusField, setFocusField] = useState<string | null>(null);

	useEffect(() => {
		if (focusField) {
			setFocus(focusField, { shouldSelect: true });
		}
	}, [focusField, setFocus]);

	return (
		<FormFocusContext.Provider value={{ focusField, setFocusField }}>
			{children}
		</FormFocusContext.Provider>
	);
};
