import { Button, Modal } from "@salesforce/design-system-react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

export const ConfirmationDialog = NiceModal.create(
	({ title, message, action, confirmButtonText, ...props }) => {
		const modal = useModal();

		const handleClose = () => {
			modal.remove();
		};

		const handleConfirmedAction = () => {
			action();
			modal.remove();
		};

		return (
			<Modal
				isOpen={modal.visible}
				onRequestClose={handleClose}
				header={<h2 style={{ fontSize: "1rem" }}>{title}</h2>}
			>
				<div style={{ display: "grid", placeItems: "center", padding: "1rem" }}>
					{message}

					<div style={{ display: "flex", marginTop: "1rem" }}>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleConfirmedAction} variant="destructive">
							{confirmButtonText}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
);

NiceModal.register("confirmation-dialog", ConfirmationDialog);

export const useConfirmationDialog = () => useModal("confirmation-dialog");
