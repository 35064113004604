import classes from "./CommunitiesSearch.module.scss";
import { Radio, VisualPicker, Button } from "@salesforce/design-system-react";
import { useForm, useWatch } from "react-hook-form";
import { useIsMobile } from "@/Responsive";
import { CommunitiesTable } from "./communities-table/CommunitiesTable";

export const CommunitiesSearch = ({ communities, submit, onCancel }) => {
	const { handleSubmit, setValue, control } = useForm({
		defaultValues: { community: "" },
	});
	const communitySelected = useWatch({ name: "community", control });
	const isMobile = useIsMobile();
	const onSubmit = (data) => {
		submit(data);
	};
	return (
		<>
			{communities.length > 0 ? (
				<form id="communityPicker" onSubmit={handleSubmit(onSubmit)}>
					<div className={classes.container}>
						{isMobile ? (
							<VisualPicker>
								{communities.map((item) => (
									<Radio
										id={item.id}
										key={item.id}
										onChange={() => setValue("community", item.id)}
										onRenderVisualPicker={() => <Community data={item} />}
									/>
								))}
							</VisualPicker>
						) : (
							<CommunitiesTable
								data={communities}
								communitySelected={communitySelected}
								onChange={(id) => setValue("community", id)}
							/>
						)}
						<div
							style={{
								display: "grid",
								backgroundColor: "white",
								marginTop: ".5rem",
								placeContent: "center",
								width: "100%",
								height: "4rem",
								position: "sticky",
								bottom: "0px",
								background: "rgba(236, 235, 234, 0.9)",
								zIndex: "50",
							}}
						>
							<div>
								<Button onClick={() => onCancel()}>Cancel</Button>
								<Button
									variant="brand"
									form="communityPicker"
									onClick={() => handleSubmit(onSubmit)()}
									disabled={!communitySelected}
								>
									Save
								</Button>
							</div>
						</div>
					</div>
				</form>
			) : (
				<p style={{ color: "red" }}>
					No communities found, please refine your search
				</p>
			)}
		</>
	);
};

const Community = ({ data }) => {
	const address = data.address;
	return (
		<div className={classes.radio}>
			<span>
				<FieldWithLabel label="Manager Name" value={data.managerName} />
			</span>
			<span>
				<FieldWithLabel label="Community Number" value={data.id} />
			</span>
			<span>
				<FieldWithLabel label="Community Name" value={data.name} />
			</span>

			<span>
				<FieldWithLabel label="Phone Number" value={data.phone} />
			</span>
			<div className={classes.addressContainer}>
				<FieldWithLabel
					label="Address"
					value={
						<div className={classes.address}>
							<span>
								{address.streetAddressLine1} {address.city}, {address.state},
								{address.zip}
							</span>
						</div>
					}
				/>
			</div>
		</div>
	);
};

const FieldWithLabel = ({ label, value }) => {
	return (
		<ul className={classes.field}>
			<li>
				<label className={classes.fieldLabel}>{label}</label>
				<span className={classes.fieldValue}>{value}</span>
			</li>
		</ul>
	);
};
