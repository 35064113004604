import { useState } from "react";
import { MediaCard } from "@/features/media-manager/components/MediaCard/MediaCard";
import { MediaContainerHeader } from "../MediaContainerHeader";
import { WebReadyControls } from "../media-controls";
import { useWebReadyMedia } from "../../api/hooks/useWebReadyMedia";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { useMediaLoadingState } from "../../api/hooks/useMediaLoadingStates";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import {
	horizontalListSortingStrategy,
	SortableContext,
	useSortable,
} from "@dnd-kit/sortable/dist";
import useVirtual from "react-cool-virtual";
import { CSS } from "@dnd-kit/utilities";
import { WEB_READY_MEDIA } from "@/ReduxToolkit/MediaSlice";
import { usePhotoSlider } from "../PhotoSizeSlider/PhotoSizeSlider";
import classes from "@/features/media-manager/components/media-container/MediaContainer.module.scss";
import { useDroppable } from "@dnd-kit/core";
import { useCallback } from "react";

export const WebReadyBucket = ({ onShowPreview = () => {}, ...props }) => {
	const { assetId } = useAssetId();
	const { data: media, isSuccess } = useWebReadyMedia(assetId);

	const [showContent, setShowContent] = useState(true);
	const toggleShowContent = () => setShowContent(!showContent);
	const { isLoadingWebReady, loadingMessage } = useMediaLoadingState();

	const { photoSlider } = usePhotoSlider();

	return (
		isSuccess && (
			<>
				{/* {updateLoading === "rejected" && (
					<SalesforceToast
						description={"Something went wrong"}
						variant="error"
						onRequestClose={() => dispatch(dismissLoadError())}
					/>
				)} */}
				<MediaContainerHeader
					label="Web Ready"
					media={media}
					controls={() => (
						<WebReadyControls
							isVisible={showContent}
							onShowContent={toggleShowContent}
						/>
					)}
					container="web"
					isOrdered
					info={
						isLoadingWebReady ? (
							<LoadingIndicator loadingMessage={loadingMessage} />
						) : null
					}
				/>
				{showContent && (
					<div
						className={`${classes.contentWrapper} ${
							props.isDraggedOver ? classes.draggedOver : ""
						}`}
					>
						<WebReadyMediaCards sliderWidth={photoSlider} />
					</div>
				)}
			</>
		)
	);
};

function WebReadyMediaCards() {
	const { assetId } = useAssetId();
	const { data: media } = useWebReadyMedia(assetId);

	const items = media.map((image) => image.id);
	const { photoSlider } = usePhotoSlider();
	const cols = useCallback(
		(width) => {
			const size = Math.round(width / photoSlider) + 0.25;
			return width / size;
		},
		[photoSlider]
	);

	const {
		outerRef,
		innerRef,
		items: virtualItems,
	} = useVirtual({
		itemCount: items.length,
		horizontal: true,
		itemSize: (_, width) => {
			return cols(width);
		},
		onResize: ({ width }) => {
			return cols(width);
		},
	});

	return (
		<div
			ref={outerRef}
			style={{
				overflowX: "auto",
				minWidth: "100%",
				height: "fit-content",
				width: "100%",
				backgroundColor: "white",
				position: "relative",
			}}
		>
			<div
				ref={innerRef}
				style={{
					display: "flex",
					minWidth: "100%",
				}}
			>
				<SortableContext
					id="WebReady"
					items={media}
					strategy={horizontalListSortingStrategy}
				>
					{virtualItems.map((item) => {
						return (
							<SortableItem
								key={`webReady-${items[item.index] ?? item.index}`}
								id={items[item.index]}
								groupingId={assetId}
								width={cols(item.width)}
							/>
						);
					})}
				</SortableContext>
				<WebReadyDroppable count={virtualItems.length} />
			</div>
		</div>
	);
}
function SortableItem(props) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
		setActivatorNodeRef,
	} = useSortable({
		id: props.id,
		data: {
			type: "webReady",
			mediaId: props.id,
		},
	});

	const style = {
		minWidth: `${props.width}px`,
		height: "fit-content",
		position: "relative",
		zIndex: 1,
		backgroundColor: "transparent",
		transform: CSS.Transform.toString({
			...transform,
			scaleX: 0.98,
			scaleY: 0.98,
		}),
		transition,
	};

	return isDragging ? (
		<div ref={setNodeRef} {...attributes} {...listeners}>
			<div
				style={{
					...style,
					border: "none",
				}}
			></div>
		</div>
	) : (
		<div ref={setNodeRef} style={style} {...attributes}>
			<MediaCard
				ref={setActivatorNodeRef}
				media={{ groupingId: props.groupingId, id: props.id }}
				mediaId={props.id}
				bucket={WEB_READY_MEDIA}
				listeners={listeners}
				isDraggable
			/>
		</div>
	);
}

function WebReadyDroppable({ count }) {
	const { setNodeRef } = useDroppable({
		id: "emptyWebReady",
		data: {
			type: "webReady",
		},
	});
	return (
		<div
			style={{
				...(!count && { minHeight: "5rem" }),
				width: "100%",
			}}
			ref={setNodeRef}
		></div>
	);
}
