import { createAction, createListenerMiddleware, addListener, TypedStartListening, TypedAddListener } from "@reduxjs/toolkit";
import { register } from "@/serviceWorkerRegistration";
import type { RootState, AppDispatch } from './store'

export const listenerMiddleware = createListenerMiddleware();

const onFocus = createAction("__rtkq/focused");

listenerMiddleware.startListening({
	actionCreator: onFocus,
	effect: (action, api) => {
		register({
			//@ts-ignore
			onUpdate: (registration) => {
				if (registration && registration.waiting) {
					registration.waiting.postMessage({ type: "SKIP_WAITING" });
				}
			},
		});
	},
});

export const startAppListening = listenerMiddleware.startListening as TypedStartListening<RootState, AppDispatch>

export const addAppListener = addListener as TypedAddListener<RootState, AppDispatch>