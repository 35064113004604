import shutterButtonsvg from "./shutterButton.svg";
import classes from "./Camera.module.scss";
export const ShutterButton = ({ takePicture }) => {
	return (
		<div className={classes.shutterButtonContainer}>
			<button
				style={{ border: "none", backgroundColor: "transparent" }}
				onClick={() => takePicture()}
			>
				<img
					className={classes.shutterButton}
					src={shutterButtonsvg}
					alt="capture"
				/>
			</button>
		</div>
	);
};
