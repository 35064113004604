import { useAssetListParams } from "./useAssetListParams";
import { useDefaultFilters, useSelectAssetList } from "./useLazyAssetList";
import { useGetSearchFilters } from "./useSearchAssetList";
import { getAllAssets, getSortPath } from "./endpoints";

export const useSortAssetList = () => {
	const [{ sort: property, direction, view, q }, { setSorting }] =
		useAssetListParams();

	const [trigger] = getAllAssets.useLazyQuerySubscription();
	const getDefaultFilters = useDefaultFilters();

	const { originalArgs } = useSelectAssetList();

	const getSearchFilters = useGetSearchFilters();
	const searchPaths = (search) =>
		getSearchFilters(search).map((search) => search.propertyPath);

	const triggerSort = async ({ property, direction }) => {
		const defaultFilters = await getDefaultFilters();
		const filters =
			originalArgs?.filteringCriteria ?? (await defaultFilters[view]());
		const name = originalArgs?.name ?? view;
		trigger({
			name: name,
			paginationCriteria: {
				itemsPerPage: 20,
				pageNumber: 0,
			},
			filteringCriteria: filters,
			sortCriteria: {
				direction,
				propertyPath: getSortPath(property),
			},
			...(q?.length > 0 && {
				fuzzySearchCriteria: { propertyPath: searchPaths(q), value: q },
			}),
		});

		setSorting({ direction, property });
	};

	return {
		property,
		direction,
		trigger: triggerSort,
	};
};
