import { openToast } from "@/ReduxToolkit/ToastsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateMediaPropertiesMutation } from "../endpoints";
import { selectWebReadyMedia } from "../selectors";

const createAddToWebReadyUpdate = ({
	webReadyMedia,
	added,
	destination = null,
}) => {
	const newWebReadies = Array.isArray(added)
		? added.filter((image) => !image.webReady)
		: added;

	if (Array.isArray(added))
		return [
			...newWebReadies.map((image, index) => ({
				...image,
				webReady: true,
				order:
					destination !== null
						? destination + index
						: webReadyMedia.length + index,
				...((destination !== null || webReadyMedia.length === 0) &&
					destination + index === 0 && { isPrimary: true }),
			})),
			...webReadyMedia
				.slice(destination !== null ? destination : webReadyMedia.length)
				.map((image, index) => ({
					...image,
					...(destination !== null && { order: destination + index + 1 }),
					isPrimary: false,
				})),
		];
	return [
		{
			...added,
			order: destination !== null ? destination : webReadyMedia.length,
			webReady: true,
			...(destination !== null && destination === 0 && { isPrimary: true }),
		},
		...webReadyMedia.slice(destination).map((image, index) => ({
			...image,
			...(destination !== null && { order: destination + index + 1 }),
			isPrimary: false,
		})),
	];
};

export const useAddtoWebReady = (assetId) => {
	const webReadyMedia = useSelector(selectWebReadyMedia(assetId));
	const dispatch = useDispatch();

	const update = (added, destination) =>
		createAddToWebReadyUpdate({ webReadyMedia, added, destination });

	const [trigger, result] = useUpdateMediaPropertiesMutation({
		fixedCacheKey: "add-to-web",
	});

	const addToWebReady = (added, destination) => {
		trigger(update(added, destination));
	};

	useEffect(() => {
		if (result?.error) {
			dispatch(
				openToast({
					id: result.requestId,
					labels: {
						heading: `Error`,
						details: `Something went wrong when adding media to web ready`,
					},
					variant: "error",
					timeout: 5000,
				})
			);
		}
	}, [dispatch, result?.error, result.requestId]);

	return { addToWebReady, ...result };
};
