import {
	useGetAssetLocationsQuery,
	useGetFoundationTypesQuery,
	useGetLoanTypesQuery,
	useGetRemarketingStatusesQuery,
	useGetRoofTypesQuery,
	useGetSidingTypesQuery,
} from "@/features/lookups";
import {
	useGetAssetDetailsConcurrencyErrorsQuery,
	useRemoveAssetDetailConcurrencyErrorMutation,
} from "@/features/offline-use/api/endpoints/syncAssetDetails";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { closeToast, openToast } from "@/ReduxToolkit/ToastsSlice";
import { diffConcurrencyData } from "@/Utilities/utils";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { usePatchAssetMutation } from "./endpoints";
import { applyPatch } from "fast-json-patch";

export const useAssetDetails = (assetId) => {
	const { data, isSuccess: isSuccessAsset } = useGetAssetQuery(assetId);

	const { isSuccess: isSuccessRemarketingStatuses } =
		useGetRemarketingStatusesQuery();

	const { isSuccess: isSuccessLoanTypes } = useGetLoanTypesQuery();

	const { isSuccess: isSuccessFoundationLookup } = useGetFoundationTypesQuery();
	const { isSuccess: isSuccessRoofLookup } = useGetRoofTypesQuery();
	const { isSuccess: isSuccessSidingLookup } = useGetSidingTypesQuery();
	const { isSuccess: isSuccessLocations } = useGetAssetLocationsQuery();

	const isSuccess =
		isSuccessAsset &&
		isSuccessFoundationLookup &&
		isSuccessRoofLookup &&
		isSuccessSidingLookup &&
		isSuccessRemarketingStatuses &&
		isSuccessLoanTypes &&
		isSuccessLocations;

	const { data: persistedConcurrencyErrors } =
		useGetAssetDetailsConcurrencyErrorsQuery(assetId, { skip: !isSuccess });

	const [removeConcurrencyError] =
		useRemoveAssetDetailConcurrencyErrorMutation();

	const hasError =
		persistedConcurrencyErrors &&
		Object.keys(persistedConcurrencyErrors).includes(assetId);

	const patch = persistedConcurrencyErrors?.[assetId]?.patch?.filter(
		(patch) => !patch.path.match(/concurrency/)
	);

	const { newDocument: originalArgs } = (patch &&
		applyPatch(data, patch, false, false)) ?? { newDocument: {} };
	const [trigger, { reset: resetResult }] = usePatchAssetMutation();

	const reset = () => {
		removeConcurrencyError(assetId);
		dispatch(closeToast(assetId));
		resetResult();
	};

	const existingNoteIds = useMemo(
		() => data?.notes?.map((note) => note.id) || [],
		[data?.notes]
	);

	const submittedNotesInExisting = useMemo(
		() =>
			originalArgs?.notes?.filter(
				(note) => note.id && existingNoteIds.includes(note.id)
			) || [],
		[existingNoteIds, originalArgs?.notes]
	);

	const newSubmittedNotes = useMemo(
		() => originalArgs?.notes?.filter((note) => !note.id) || [],
		[originalArgs?.notes]
	);

	const submission = useMemo(() => {
		return data?.notes?.map((note) =>
			originalArgs?.notes?.map((note) => note.id).includes(note.id)
				? submittedNotesInExisting.find((submitted) => submitted.id === note.id)
				: null
		);
	}, [data?.notes, originalArgs?.notes, submittedNotesInExisting]);

	const isConcurrencyError = isSuccess && hasError;

	const concurrencyDiff = useMemo(
		() =>
			isConcurrencyError
				? diffConcurrencyData(
						{
							...data,
							notes: [...data?.notes, ...newSubmittedNotes],
						},
						{
							...originalArgs,
							notes: submission,
						}
					)
				: {},
		[data, isConcurrencyError, newSubmittedNotes, originalArgs, submission]
	);

	const isEmptyDiff = Object.keys(concurrencyDiff || {}).length === 0;

	const dispatch = useDispatch();

	useEffect(() => {
		if (isConcurrencyError && !isEmptyDiff) {
			dispatch(
				openToast({
					id: assetId,
					labels: {
						heading: "Error",
						details: "There is newer data for this asset.",
					},
					variant: "error",
				})
			);
		}
	}, [assetId, dispatch, isConcurrencyError, isEmptyDiff]);

	const update = async (arg, callback = () => {}) => {
		const { error } = await trigger(arg);

		if (error === undefined) {
			callback();
		}

		dispatch(closeToast(assetId));

		if (isConcurrencyError) {
			removeConcurrencyError(assetId);
		}
	};

	return {
		data,
		isSuccess,
		update,
		isConcurrencyError: isConcurrencyError && !isEmptyDiff,
		...(isConcurrencyError &&
			!isEmptyDiff && {
				concurrencyDiff,
			}),
		reset,
	};
};
