import classes from "./AssetCard.module.scss";
import { Button } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
export const AssetCard = (data) => {
	const navigate = useNavigate();
	const asset = data.asset;
	const location = asset.locations[0];
	return (
		<div className={classes.Container}>
			<Button
				onClick={() => {
					navigate("/account/" + asset.accountId + "/asset/" + asset.id);
				}}
				style={{
					border: "none",
					color: "blue",
					padding: "10px",
					margin: "0px",
				}}
			>
				{location.address}, {location.city} {location.state}, {location.zip}
			</Button>
		</div>
	);
};
