import {
	DataTableCell,
	Icon,
	Input,
	Tooltip,
} from "@salesforce/design-system-react";
import { PropertyPath, get } from "lodash";
import { useController } from "react-hook-form";
import { useFormSection } from "../hooks/useFormSection";
import { useIsEditable } from "../hooks/useIsEditable";
import { StaticInput } from "./StaticInput";
import { getColumn } from "./utils";
import { useNextField } from "../hooks/useNextField";
import { WorksheetCellProps } from "../types";
import { ChangeEvent } from "react";

export const BaseInputCell = ({ children, ...props }: WorksheetCellProps) => {
	const {
		field: { ref, value, onChange, ...rest },
		fieldState: { error },
	} = useController({ name: children as string });

	const column = getColumn(children as string);
	const { isStatic, editField } = useFormSection(column);
	const isEditable = useIsEditable();

	const nextField = useNextField({ name: children as string });

	const handleEnter = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			e.preventDefault();
			nextField();
		}
	};

	return (isStatic || !isEditable) && error === undefined ? (
		<DataTableCell
			title={`${props.label} ${props.item?.category as string}`}
			{...props}
		>
			<StaticInput
				onDoubleClick={() => {
					editField(children as string);
				}}
				{...rest}
				editable
				containerStyle={{ ...(!isEditable && { paddingRight: "1.5rem" }) }}
				onClickEdit={() => editField(children as string)}
			>
				{value ?? get(props.data, children as PropertyPath)}
			</StaticInput>
		</DataTableCell>
	) : (
		<DataTableCell title={`${props.label} ${props?.item?.category}`} {...props}>
			<div style={{ display: "flex" }}>
				<Input
					defaultValue={get(props.data, children as PropertyPath)}
					styleInput={{
						width: "13rem",
						minHeight: "unset",
						lineHeight: "1.5rem",
						textAlign: "right",
						paddingRight: 0,
					}}
					styleContainer={{
						display: "flex",
						justifyContent: "flex-end",
						width: "100%",
					}}
					onChange={(_e: ChangeEvent, { value }: { value: number | string }) =>
						onChange(Number(Number(value).toFixed(2)))
					}
					value={value.toString()}
					inputRef={ref}
					type="number"
					step={100}
					min={0}
					errorText={error?.message && true}
					disabled={!isEditable}
					onKeyPress={handleEnter}
					{...rest}
				/>
				{error && (
					<Tooltip
						content={error?.message}
						variant="list-item"
						theme="error"
						onMouseOver={(e: MouseEvent) => {
							e.preventDefault();
							e.stopPropagation();
						}}
					>
						<div
							style={{
								display: "flex",
								placeItems: "center",
								height: "100%",
								padding: "0 .25rem ",
							}}
						>
							<Icon
								colorVariant="error"
								name="info_alt"
								category="utility"
								size="x-small"
							/>
						</div>
					</Tooltip>
				)}
			</div>
		</DataTableCell>
	);
};

BaseInputCell.displayName = DataTableCell.displayName;
