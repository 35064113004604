import { prefetchLookups } from "@/features/lookups";
import { prefetchDocumentationLookups } from "@/features/documentation/api/endpoints";
import { cacheOfflineAssets } from "@/ReduxToolkit/offlineAssetSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const OfflineAssetProvider = ({ children }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		prefetchLookups(dispatch);
		prefetchDocumentationLookups(dispatch);
		dispatch(cacheOfflineAssets());
	}, [dispatch]);

	return children;
};
