import { enforce, test, create } from "vest";
import { Sale } from "../types";

export const createSaleValidation = create((data: Sale) => {
	test(`totalAmount`, "Total Amount must be > $0.00", () => {
		enforce(data.totalAmount).gt(0);
	});

	test(`totalAmount`, "Must only have 2 decimal places", () => {
		enforce(data.totalAmount).matches(/^(\d*\.{0,1}\d{0,2}$)/);
	});
});
