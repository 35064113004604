import { gatewayApi } from "@/ReduxToolkit";
import {
	assetDetailsConcurrencyDB,
	offlineAssetEditsDB,
	offlineConditionReportUploadsDB,
	offlineDocumentationDB,
	offlineMediaUploadsDB
} from "@/storage";

const syncAssetDetails = (builder) => ({
	getAssetDetailsConcurrencyErrors: builder.query({
		queryFn: async (arg, api, _extra, baseQuery) => {
			const concurrencyErrorIds = await assetDetailsConcurrencyDB.keys();

			const concurrencyErrors = await Promise.all(
				concurrencyErrorIds.map(async (id) => [
					id,
					await assetDetailsConcurrencyDB.getItem(id)
				])
			);

			const result = Object.fromEntries(concurrencyErrors);

			return { data: result };
		},
		providesTags: () => [{ type: "ConcurrencyError", id: "LIST" }]
	}),
	addAssetDetailConcurrencyError: builder.mutation({
		queryFn: async ({ id, data }, api, _extra, baseQuery) => {
			await assetDetailsConcurrencyDB.setItem(id, data);
			await offlineAssetEditsDB.removeItem(id);

			return { data: null };
		},
		invalidatesTags: () => [{ type: "ConcurrencyError", id: "LIST" }]
	}),
	removeAssetDetailConcurrencyError: builder.mutation({
		queryFn: async (assetId, api, _extra, baseQuery) => {
			await assetDetailsConcurrencyDB.removeItem(assetId);

			if (await offlineAssetEditsDB.getItem(assetId)) {
				await offlineAssetEditsDB.removeItem(assetId);
			}

			return { data: null };
		},
		invalidatesTags: () => [{ type: "ConcurrencyError", id: "LIST" }]
	}),
	getAssetDetailEdits: builder.query({
		queryFn: async (arg, api, _extra, baseQuery) => {
			const assetIds = await offlineAssetEditsDB.keys();

			const assetDetailEdits = await Promise.all(
				assetIds.map(async (id) => await offlineAssetEditsDB.getItem(id))
			);

			return { data: assetDetailEdits };
		},
		providesTags: () => [{ type: "AssetDetailEdits", id: "LIST" }]
	}),
	getOfflineSyncItems: builder.query({
		queryFn: async () => {
			const assetDetails = await offlineAssetEditsDB.keys();
			const mediaUploads = await offlineMediaUploadsDB.keys();
			const conditionReports = await offlineConditionReportUploadsDB.keys();
			const notes = await offlineDocumentationDB.keys();

			return {
				data: {
					total:
						assetDetails.length +
						mediaUploads.length +
						conditionReports.length +
						notes.length,
					assetDetails: assetDetails.length,
					mediaUploads: mediaUploads.length,
					conditionReports: conditionReports.length,
					documentation: notes.length
				}
			};
		},
		providesTags: () => ["SyncItems"]
	}),
	removeAssetDetailEdit: builder.mutation({
		queryFn: async (id) => {
			await offlineAssetEditsDB.removeItem(id);

			return { data: null };
		},
		invalidatesTags: () => ["SyncItems", "PendingAssetDetails"]
	}),
	removeOfflineMediaUpload: builder.mutation({
		queryFn: async (id) => {
			await offlineMediaUploadsDB.removeItem(id);

			return { data: null };
		},
		invalidatesTags: () => ["SyncItems", "PendingMediaUploads"]
	}),
	removeOfflineConditionReport: builder.mutation({
		queryFn: async (id) => {
			await offlineConditionReportUploadsDB.removeItem(id);

			return { data: null };
		},
		invalidatesTags: () => ["SyncItems", "PendingConditionReport"]
	}),
	removeOfflineDocument: builder.mutation({
		queryFn: async (id) => {
			await offlineDocumentationDB.removeItem(id);

			return { data: null };
		},
		invalidatesTags: () => ["SyncItems"]
	})
});

gatewayApi.enhanceEndpoints({
	addTagTypes: ["ConcurrencyError", "AssetDetailEdits", "SyncItems"]
});

gatewayApi.injectEndpoints({ endpoints: syncAssetDetails });

export const {
	useGetAssetDetailsConcurrencyErrorsQuery,
	useRemoveAssetDetailConcurrencyErrorMutation,
	useGetOfflineSyncItemsQuery,
	useLazyGetOfflineSyncItemsQuery
} = gatewayApi;

export const {
	getAssetDetailsConcurrencyErrors,
	addAssetDetailConcurrencyError,
	removeAssetDetailConcurrencyError,
	getOfflineSyncItems,
	removeAssetDetailEdit,
	removeOfflineMediaUpload,
	removeOfflineConditionReport,
	removeOfflineDocument
} = gatewayApi.endpoints;
