import { Button } from "@salesforce/design-system-react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useFormReset } from "../hooks/useFormReset";
import { useFormSection } from "../hooks/useFormSection";
import classes from "./Submitbuttons.module.scss";
import spinner from "./spinner.module.scss";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";
import { usePricingWorksheet } from "../usePricingWorksheet";

export const SubmitButtons = ({ form }: { form: string }) => {
	const {
		formState: { isSubmitting, isSubmitSuccessful, dirtyFields },
	} = useFormContext();

	const { assetId } = useAssetId();
	const { currentWorksheet, isFetching } = usePricingWorksheet(assetId);

	const reset = useFormReset();
	const { isStatic } = useFormSection();

	const handleReset = () => {
		reset();
	};

	useEffect(() => {
		if (isSubmitSuccessful && !isFetching) {
			reset(currentWorksheet);
		}
	}, [currentWorksheet, isFetching, isSubmitSuccessful, reset]);

	return (
		(!isStatic || Object.keys(dirtyFields).length > 0) && (
			<div className={classes.container}>
				<div>
					<Button onClick={handleReset}>Cancel</Button>
					<Button
						variant="brand"
						type="submit"
						form={form}
						disabled={
							!Object.keys(dirtyFields).length || isSubmitting || isFetching
						}
						{...((isSubmitting || isFetching) && {
							iconClassName: spinner.spinner,
							iconName: "spinner",
							iconPosition: "left",
							iconCategory: "utility",
						})}
					>
						Save
					</Button>
				</div>
			</div>
		)
	);
};
