import { Button, Combobox, Popover } from "@salesforce/design-system-react";
import { useAllMediaSort } from "@/features/media-manager/api/hooks/useAllMedia";
import { useAssetId } from "@/features/asset/components/AssetIdProvider";

export const sortPropertyOptions = [
	{
		id: "sort-dateCreated",
		label: "Date Created",
		value: "dateFileCreated",
	},
	{
		id: "sort-dateModified",
		label: "Date Modified",
		value: "dateModified",
	},
	{
		id: "sort-title",
		label: "Title",
		value: "title",
	},
];

export const sortDirectionOptions = [
	{
		id: "sort-desc",
		label: "Descending",
		value: "desc",
	},
	{
		id: "sort-asc",
		label: "Ascending",
		value: "asc",
	},
];

export function SortMediaButton() {
	const { assetId } = useAssetId();

	const [sortParameters, updateSort] = useAllMediaSort(assetId);

	const sortPropertySelection = sortPropertyOptions.filter(
		(option) => option.value === sortParameters?.property
	);
	const sortDirectionSelection = sortDirectionOptions.filter(
		(option) => option.value === sortParameters?.direction
	);

	return (
		<Popover
			align="bottom right"
			heading="Sort Media"
			body={
				<>
					<Combobox
						labels={{
							label: "Sort by",
						}}
						variant="readonly"
						options={sortPropertyOptions}
						selection={sortPropertySelection}
						value={sortParameters.property}
						events={{
							onSelect: (e, { selection: [selected] }) => {
								if (selected) {
									updateSort({ property: selected.value });
								}
							},
						}}
					/>
					<Combobox
						labels={{
							label: "Direction",
						}}
						variant="readonly"
						options={sortDirectionOptions}
						selection={sortDirectionSelection}
						value={sortParameters.direction}
						events={{
							onSelect: (e, { selection: [selected] }) => {
								if (selected) updateSort({ direction: selected.value });
							},
						}}
					/>
				</>
			}
		>
			<Button
				variant="icon"
				iconCategory="utility"
				iconName="sort"
				iconVariant="border-filled"
			/>
		</Popover>
	);
}
