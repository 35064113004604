import React from "react";
import { AssetCard } from "@/features/asset-list/components/AssetCard/AssetCard";
import { useNavigate } from "react-router";
import classes from "./MobileTableView.module.scss";
import useVirtual from "react-cool-virtual";

import { FilterPanelLayout } from "@/features/media-manager/components/layouts";
import { AssetListFilterPanelContainer } from "../Filters/Filters";
import { useAssetList } from "../../api/useLazyAssetList";
import { usePageAssetList } from "../../api/usePageAssetList";
import { ACCOUNT_ID } from "@/features/asset/constants";
import { get } from "lodash";
import { useAssetListParams } from "../../api/useAssetListParams";
import { useGetOfflineAssetsQuery, useIsOnline } from "@/features/offline-use";
import Illustration from "@/media/error_Illustration.svg";

export const MobileCards = () => {
	const { data } = useAssetList();
	const [{ view }] = useAssetListParams();
	const { data: offlineAssets } = useGetOfflineAssetsQuery(undefined, {
		selectFromResult: ({ data }) => ({ data: data?.ids ?? [] })
	});
	const isOnline = useIsOnline();
	const { pagination, trigger: triggerPage } = usePageAssetList();
	const totalAssets = pagination?.totalItems;

	const { outerRef, innerRef, items } = useVirtual({
		itemCount: totalAssets ?? 100,
		scrollDuration: 50,
		itemSize: 94,
		resetScroll: true,
		loadMoreCount: 20,
		isItemLoaded: (index) => {
			const currentPage = pagination.page;
			return currentPage === index;
		},
		loadMore: (event) => {
			if (event.loadIndex !== 0) {
				triggerPage(event.loadIndex);
			}
		}
	});

	const navigate = useNavigate();
	return (view === "offlineAssets" || !isOnline) &&
		offlineAssets?.length === 0 ? (
		<div style={{ display: "grid", placeContent: "center", height: "30vh" }}>
			<img src={Illustration} alt="desert" />
			<p style={{ textAlign: "center" }}>
				You have no assets saved for Field Use
			</p>
		</div>
	) : (
		<FilterPanelLayout panel={<AssetListFilterPanelContainer />}>
			<div>
				{!isOnline ? (
					<div className={classes.MobileTableView}>
						{data.map((item) => {
							return (
								<div key={`mobile-table-${item.id}`} className={classes.card}>
									<AssetCard
										key={`mobile-table-${item.id}`}
										asset={item}
										onPhotoButtonClick={() =>
											navigate(
												`/account/${get(item, ACCOUNT_ID.path)}/asset/${
													item.id
												}?tab=media`
											)
										}
									></AssetCard>
								</div>
							);
						})}
					</div>
				) : (
					<div ref={outerRef} className={classes.MobileTableView}>
						<div ref={innerRef}>
							{items.map(({ index, measureRef }) => {
								const handlePhotoButtonClick = () =>
									navigate(
										`/account/${get(data?.[index], ACCOUNT_ID.path)}/asset/${
											data?.[index]?.id
										}?tab=media`
									);
								if (data[index]?.id) {
									return (
										<div
											ref={measureRef}
											key={`mobile-table-${data[index]?.id ?? index}`}
											className={classes.card}
										>
											<AssetCard
												forwardRef={measureRef}
												asset={data[index] ?? {}}
												onPhotoButtonClick={handlePhotoButtonClick}
											/>
										</div>
									);
								}
								return (
									<div
										ref={measureRef}
										key={`mobile-table-${data[index]?.id ?? index}`}
										className={classes.card}
									></div>
								);
							})}
						</div>
					</div>
				)}
			</div>
		</FilterPanelLayout>
	);
};

export const MobileTableView = () => {
	return <MobileCards />;
};
