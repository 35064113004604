import config from "@/providers/config/config";
import classes from "../RolesAndFlags.module.scss";

export function SwaggerLink() {
	return (
		<div className={classes.container}>
			<h1 className={classes.subtitle}>Swagger</h1>
			<div className={classes.flagsGrid}>
				<a
					href={`https://api.${
						config?.HOMEBASE_ENV !== "#{HOMEBASE_ENV}"
							? config?.HOMEBASE_ENV?.toLowerCase()
							: "itg"
					}.rmk.vmfpub.com/homebasegateway/swagger/index.html`}
					target="_blank"
					rel="noopener noreferrer"
				>
					Open Gateway Swagger
				</a>
			</div>
		</div>
	);
}
