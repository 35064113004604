import React, { useEffect, useState } from "react";
import {
	DataTableCell,
	Button,
	RadioGroup,
	Radio,
} from "@salesforce/design-system-react";
import "@/features/asset-list/components/DesktopTable/DesktopTable.scss";
import DayJS from "dayjs";
import { ReviewStatusBadge } from "./ReviewStatus";
import { useRequestInsuranceCancellationMutation } from "@/ReduxToolkit/GatewayApi";
import classes from "./TableValues.module.scss";
import { useSelector } from "react-redux";
import { useAccessInsuranceQueue } from "@/providers/roles/AccessHooks";
import { selectCurrentUser } from "@/features/user/api/selectors";
export const AccountDataTableCell = ({ children, ...props }) => {
	return (
		<DataTableCell title={children.toString()} {...props}>
			<div
				className="AccountCell"
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<p>{children.toString()}</p>
			</div>
		</DataTableCell>
	);
};
AccountDataTableCell.displayName = DataTableCell.displayName;

export const DateCell = ({ children, ...props }) => {
	let date = DayJS(children).format("MM/DD/YYYY");

	return (
		<DataTableCell title={date} {...props}>
			<p>{date}</p>
		</DataTableCell>
	);
};
DateCell.displayName = DataTableCell.displayName;

export const InsuranceStatusCell = ({ children, ...props }) => {
	return (
		<DataTableCell title={children} {...props} className={classes.centered}>
			<ReviewStatusBadge reviewStatus={children} />
		</DataTableCell>
	);
};
InsuranceStatusCell.displayName = DataTableCell.displayName;

export const InsuranceDecision = ({ children, ...props }) => {
	// Use Mutation
	const [updateInsuranceCancellation, { isLoading: isUpdating, isSuccess }] =
		useRequestInsuranceCancellationMutation();

	const radioValues = ["To Customer", "To VMF"];
	const [radio, setRadio] = useState("");
	const disabledCheckboxes =
		props.item.reviewStatus !== "needsReview" || isUpdating;
	const { canWrite } = useAccessInsuranceQueue();
	const isRoleEditable = canWrite;
	const disabledSubmit =
		props.item.reviewStatus !== "needsReview" &&
		props.item.reviewStatus !== "autoRefund"
			? true
			: props.item.reviewStatus === "needsReview" && radio === "";

	const submitButtonValue =
		props.item.reviewStatus === "autoRefund" ? "Approve" : "Submit";
	useEffect(() => {
		if (isSuccess) {
			setRadio("");
		}
	}, [isSuccess]);
	const { email } = useSelector(selectCurrentUser);
	const submitValue = async () => {
		const recipientValue =
			props.item.reviewStatus === "autoRefund"
				? ""
				: radio === "To Customer"
				? "Customer"
				: "VMF";
		const submission = {
			cancellationQueueId: Number(props.item.id),
			recipient: recipientValue,
			updatingUser: email,
		};
		await updateInsuranceCancellation(submission);
	};

	const icon = {
		...(isUpdating
			? {
					iconName: "spinner",
					iconCategory: "utility",
					iconPosition: "left",
			  }
			: {}),
	};

	return (
		<DataTableCell {...props}>
			{isRoleEditable && (
				<div style={{ display: "flex" }}>
					<div>
						<RadioGroup
							disabled={disabledCheckboxes}
							onChange={(event) => setRadio(event.target.value)}
						>
							{radioValues.map((value) => {
								return (
									<Radio
										disabled={disabledCheckboxes}
										key={value}
										id={props.item.id + value}
										labels={{ label: value }}
										value={value}
										checked={radio === value}
										variant="base"
									/>
								);
							})}
						</RadioGroup>
					</div>

					<Button
						style={{
							fontSize: "12px",
							width: "5rem",
							minWidth: "fit-content",
							alignSelf: "center",
						}}
						disabled={disabledSubmit}
						responsive
						variant="brand"
						{...icon}
						onClick={() => submitValue()}
					>
						{submitButtonValue}
					</Button>
				</div>
			)}
		</DataTableCell>
	);
};
InsuranceDecision.displayName = DataTableCell.displayName;
