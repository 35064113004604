import { ControlledPriceCheckbox } from "../components/Form/ControlledPrice";
import {
	selectPaintEntireHome,
	selectCarpet,
	selectSalesCleanEntireHome,
	selectLinoluemVinyl,
	selectLVP,
} from "../api/selectors";
import {
	CARPET,
	EXTERMINATE,
	HOT_WATER_HEATER,
	KILZ_FLOOR_WHOLE_HOUSE,
	PAINT_ENTIRE_HOME,
	INTERIOR_TRASH_OUT,
	REPLACE_FURNACE,
	SALES_CLEAN,
	THERMOSTAT,
	VINYL_YARDS,
	WINTERIZATION,
	LAMINATE_YARDS,
	GENERAL,
	LAMP_AND_TIMER,
	SHAMPOO_CARPET_ONLY,
	labelsIndex,
} from "../constants/constants";

export const generalConfig = [
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${CARPET}`,
			costName: CARPET,
			label: labelsIndex[CARPET],
			selector: selectCarpet,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${HOT_WATER_HEATER}`,
			costName: HOT_WATER_HEATER,
			label: labelsIndex[HOT_WATER_HEATER],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${PAINT_ENTIRE_HOME}`,
			costName: PAINT_ENTIRE_HOME,
			label: labelsIndex[PAINT_ENTIRE_HOME],
			selector: selectPaintEntireHome,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${SALES_CLEAN}`,
			costName: SALES_CLEAN,
			label: labelsIndex[SALES_CLEAN],
			selector: selectSalesCleanEntireHome,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${INTERIOR_TRASH_OUT}`,
			costName: INTERIOR_TRASH_OUT,
			label: labelsIndex[INTERIOR_TRASH_OUT],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${WINTERIZATION}`,
			costName: WINTERIZATION,
			label: labelsIndex[WINTERIZATION],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${EXTERMINATE}`,
			costName: EXTERMINATE,
			label: labelsIndex[EXTERMINATE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${KILZ_FLOOR_WHOLE_HOUSE}`,
			costName: KILZ_FLOOR_WHOLE_HOUSE,
			label: labelsIndex[KILZ_FLOOR_WHOLE_HOUSE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${REPLACE_FURNACE}`,
			costName: REPLACE_FURNACE,
			label: labelsIndex[REPLACE_FURNACE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${THERMOSTAT}`,
			costName: THERMOSTAT,
			label: labelsIndex[THERMOSTAT],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${VINYL_YARDS}`,
			costName: VINYL_YARDS,
			label: labelsIndex[VINYL_YARDS],
			selector: selectLinoluemVinyl,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${LAMINATE_YARDS}`,
			costName: LAMINATE_YARDS,
			label: labelsIndex[LAMINATE_YARDS],
			selector: selectLVP,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${LAMP_AND_TIMER}`,
			costName: LAMP_AND_TIMER,
			label: labelsIndex[LAMP_AND_TIMER],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${GENERAL}.${SHAMPOO_CARPET_ONLY}`,
			costName: SHAMPOO_CARPET_ONLY,
			label: labelsIndex[SHAMPOO_CARPET_ONLY],
		},
	},
];
