import { Combobox, Icon } from "@salesforce/design-system-react";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { useMemo, useState } from "react";
import { debounce } from "lodash";
export const CustomAddress = ({ changeLocation, startingAddress = "" }) => {
	const [location, setLocation] = useState({
		address: startingAddress,
		latitude: "",
		longitude: "",
		selection: [],
	});
	const [geoCodeLocations, setGeoCodeLocations] = useState([]);

	const addressSearch = (query) => {
		try {
			if (query) {
				TrimbleMaps.Search.singleSearch({
					query: query,
					maxResults: 5,
				}).then((response) => {
					if (response?.Locations) {
						const locations = modelSearchItems(response.Locations);
						setGeoCodeLocations(locations);
					} else {
						setGeoCodeLocations([]);
					}
				});
			} else {
				setGeoCodeLocations([]);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const debouncedAddressSearch = useMemo(
		() => debounce((query) => addressSearch(query), 500),
		[]
	);
	const onAddressChange = (value) => {
		setLocation({ ...location, address: value });
		debouncedAddressSearch(value);
	};

	return (
		<>
			<Combobox
				value={location.address}
				events={{
					onChange: (event, { value }) => {
						onAddressChange(value);
					},
					onRequestRemoveSelectedOption: (event, data) => {
						setLocation({
							...location,
							selection: data.selection,
						});
					},
					onSelect: (event, data) => {
						setLocation({
							...location,
							selection: [data.selection[data.selection.length - 1]],
						});
						const { icon, ...rest } = data.selection[data.selection.length - 1];

						changeLocation({
							...rest,
							accountNumber: "Custom Location",
						});
					},
				}}
				onChange={(e, { value }) => onAddressChange(value)}
				labels={{
					label: "Address",
					placeholder: "Address",
				}}
				selection={location.selection}
				multiple
				options={geoCodeLocations}
			/>
		</>
	);
};

const modelSearchItems = (items) => {
	return items?.map((item) => {
		const { Lat: latitude, Lon: longitude } = item.Coords;
		const { City: city, County: county, State: state, Zip: zip } = item.Address;
		return {
			id: item.ShortString,
			label: item.ShortString,
			city,
			county,
			state,
			zip,
			type: "account",
			latitude: latitude,
			longitude: longitude,
			icon: (
				<Icon
					assistiveText={{ label: "Account" }}
					category="standard"
					name="campaign"
				/>
			),
		};
	});
};
