import {
	ControlledPriceInput,
	ControlledPriceCheckbox,
} from "../components/Form/ControlledPrice";
import {
	selectAirConditioner,
	selectHeatPump,
	selectPaintExterior,
	selectRoofShingle,
	selectSkirting,
	selectPressureWash,
	selectFHAReady,
} from "../api/selectors";
import {
	AIR_CONDITIONER,
	BUSHHOG,
	DECK_FIVE_BY_FIVE,
	DECK_REPAIR,
	DECK_TEN_BY_TEN,
	DOORS_AND_SCREEN_DOOR,
	ELECTRIC_METER,
	EXTERIOR,
	EXTERIOR_LIGHTS,
	EXTERIOR_TRASH_OUT,
	GRADING_GRAVEL_WORK,
	GRAVEL,
	HEAT_PUMP,
	labelsIndex,
	LANDSCAPE,
	LOCK_CHANGE,
	MOW_AND_TRIM_INITIAL,
	MOW_AND_TRIM_LESS_ONE_ACRE,
	MOW_AND_TRIM_MORE_ONE_ACRE,
	PAINT_EXTERIOR,
	PAINT_SHUTTERS,
	PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR,
	ROOF_SHINGLE,
	SAND_STAIN_DECK,
	SERVICE_HEAT_AND_COOLING_UNIT,
	SKIRTING,
	STEPPING_STONES,
	PRESSURE_WASH,
	WINDOW_SCREEN,
	FHA_READY,
} from "../constants/constants";

export const exteriorConfig = [
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${AIR_CONDITIONER}`,
			costName: AIR_CONDITIONER,
			label: labelsIndex[AIR_CONDITIONER],
			selector: selectAirConditioner,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${HEAT_PUMP}`,
			costName: HEAT_PUMP,
			selector: selectHeatPump,
			label: labelsIndex[HEAT_PUMP],
		},
		label: labelsIndex[HEAT_PUMP],
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${ELECTRIC_METER}`,
			costName: ELECTRIC_METER,
			label: labelsIndex[ELECTRIC_METER],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${PAINT_EXTERIOR}`,
			costName: PAINT_EXTERIOR,
			label: labelsIndex[PAINT_EXTERIOR],
			selector: selectPaintExterior,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${PRESSURE_WASH}`,
			costName: PRESSURE_WASH,
			label: labelsIndex[PRESSURE_WASH],
			selector: selectPressureWash,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${ROOF_SHINGLE}`,
			costName: ROOF_SHINGLE,
			label: labelsIndex[ROOF_SHINGLE],
			selector: selectRoofShingle,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${SKIRTING}`,
			costName: SKIRTING,
			label: labelsIndex[SKIRTING],
			selector: selectSkirting,
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${BUSHHOG}`,
			costName: BUSHHOG,
			label: labelsIndex[BUSHHOG],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${DECK_TEN_BY_TEN}`,
			costName: DECK_TEN_BY_TEN,
			label: labelsIndex[DECK_TEN_BY_TEN],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${DECK_FIVE_BY_FIVE}`,
			costName: DECK_FIVE_BY_FIVE,
			label: labelsIndex[DECK_FIVE_BY_FIVE],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${EXTERIOR_TRASH_OUT}`,
			costName: EXTERIOR_TRASH_OUT,
			label: labelsIndex[EXTERIOR_TRASH_OUT],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${GRADING_GRAVEL_WORK}`,
			costName: GRADING_GRAVEL_WORK,
			label: labelsIndex[GRADING_GRAVEL_WORK],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${MOW_AND_TRIM_INITIAL}`,
			costName: MOW_AND_TRIM_INITIAL,
			label: labelsIndex[MOW_AND_TRIM_INITIAL],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${SAND_STAIN_DECK}`,
			costName: SAND_STAIN_DECK,
			label: labelsIndex[SAND_STAIN_DECK],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${SERVICE_HEAT_AND_COOLING_UNIT}`,
			costName: SERVICE_HEAT_AND_COOLING_UNIT,
			label: labelsIndex[SERVICE_HEAT_AND_COOLING_UNIT],
		},
	},
	{
		component: ControlledPriceCheckbox,
		props: {
			name: `${EXTERIOR}.${FHA_READY}`,
			costName: FHA_READY,
			selector: selectFHAReady,
			label: labelsIndex[FHA_READY],
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${DECK_REPAIR}`,
			costName: DECK_REPAIR,
			label: labelsIndex[DECK_REPAIR],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${DOORS_AND_SCREEN_DOOR}`,
			costName: DOORS_AND_SCREEN_DOOR,
			label: labelsIndex[DOORS_AND_SCREEN_DOOR],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${EXTERIOR_LIGHTS}`,
			costName: EXTERIOR_LIGHTS,
			label: labelsIndex[EXTERIOR_LIGHTS],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${GRAVEL}`,
			costName: GRAVEL,
			label: labelsIndex[GRAVEL],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${LANDSCAPE}`,
			costName: LANDSCAPE,
			label: labelsIndex[LANDSCAPE],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${LOCK_CHANGE}`,
			costName: LOCK_CHANGE,
			label: labelsIndex[LOCK_CHANGE],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${MOW_AND_TRIM_LESS_ONE_ACRE}`,
			costName: MOW_AND_TRIM_LESS_ONE_ACRE,
			label: labelsIndex[MOW_AND_TRIM_LESS_ONE_ACRE],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${MOW_AND_TRIM_MORE_ONE_ACRE}`,
			costName: MOW_AND_TRIM_MORE_ONE_ACRE,
			label: labelsIndex[MOW_AND_TRIM_MORE_ONE_ACRE],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${PAINT_SHUTTERS}`,
			costName: PAINT_SHUTTERS,
			label: labelsIndex[PAINT_SHUTTERS],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR}`,
			costName: PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR,
			label: labelsIndex[PAINT_TAR_ROOF_VENTS_MINOR_ROOF_REPAIR],
			type: "number",
		},
	},

	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${STEPPING_STONES}`,
			costName: STEPPING_STONES,
			label: labelsIndex[STEPPING_STONES],
			type: "number",
		},
	},
	{
		component: ControlledPriceInput,
		props: {
			name: `${EXTERIOR}.${WINDOW_SCREEN}`,
			costName: WINDOW_SCREEN,
			label: labelsIndex[WINDOW_SCREEN],
			type: "number",
		},
	},
];
