import { Combobox, InputIcon } from "@salesforce/design-system-react";
import { useController, useFormContext } from "react-hook-form";
import { useEffect, useRef } from "react";
import { isBoolean } from "lodash";

export const ControlledConcurrency = ({
	options: optionsProp,
	name,
	...props
}) => {
	const {
		field: { ref, ...rest },
		fieldState: { isTouched, error },
	} = useController({
		name: name,
	});

	const options = optionsProp.map((option, index) => {
		return {
			id: `option-${name}-${index}`,
			label: option.label,
			value: option.value,
		};
	});

	const selection = options.filter((option) => {
		return option.value
			? option.value?.toString() === rest.value?.toString()
			: !rest.value;
	});
	const comboboxValue = rest.value?.toString();
	const { focusField } = useFormContext();
	const focusRef = useRef(null);
	useEffect(() => {
		if (focusRef && focusField === name) {
			focusRef.current?.focus();
		}
	}, [focusField, name]);

	return (
		<span
			ref={(ref) => (focusRef.current = ref?.querySelector("input"))}
			style={{ gridColumn: `span ${props.span ?? 2}` }}
		>
			<Combobox
				name={name}
				variant="readonly"
				options={options}
				selection={selection}
				events={{
					onSelect: (e, { selection: [selection] }) => {
						const isNumeric =
							Number(selection?.value).toString() !== "NaN" &&
							!isBoolean(selection?.value);
						rest.onChange(
							isNumeric ? Number(selection?.value) : selection?.value || null
						);
					},
				}}
				inputRef={ref}
				errorText={!isTouched && (error?.message || "Resolve data")}
				iconLeft={<InputIcon name="error" category="utility" />}
				{...props}
				labels={{ label: props.label || props.labels.label }}
				{...rest}
				value={comboboxValue}
			/>
		</span>
	);
};
