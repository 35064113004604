import { Combobox, Icon, SLDSInput } from "@salesforce/design-system-react";
import TrimbleMaps from "@/lib/trimblemaps";
import { useMemo, useRef, useState } from "react";
import { debounce } from "lodash";
import { useController, useFormContext } from "react-hook-form";
import { ControlledConcurrency } from "@/Components/Form/ControlledConcurrency/ControlledConcurrency";
import { useFormSection } from "@/Components/Form/ControlledForm/FormSection";
import { StaticInput } from "@/Components/Form/StaticInput";
import {
	CITY,
	COUNTY,
	LATITUDE,
	LONGITUDE,
	STATE,
	ZIP,
} from "@/features/asset/constants";

const modelSearchItems = (items) => {
	return items?.map((item) => {
		const { Lat: latitude, Lon: longitude } = item.Coords;
		const {
			StreetAddress: address,
			City: city,
			State: state,
			County: county,
			Zip: zip,
		} = item.Address;
		return {
			id: item.ShortString,
			address,
			label: item.ShortString,
			type: "account",
			latitude,
			longitude,
			city,
			state,
			county,
			zip,
			icon: (
				<Icon
					assistiveText={{ label: "Account" }}
					category="standard"
					name="campaign"
				/>
			),
		};
	});
};

const getConcurrencyOptions = (options) => {
	return options.map((option) => {
		return {
			value: option,
			label: option ? option.toString() : "-- Leave Blank --",
		};
	});
};

export const Address = ({ name, locationIndex, ...props }) => {
	const {
		field: { ref, ...rest },
		fieldState: { error },
	} = useController({ name: name });
	const [geoCodeLocations, setGeoCodeLocations] = useState([]);

	const { setValue } = useFormContext();
	/*
        Change the rest values of the other fields
    */
	const changeLocation = (location) => {
		setValue(CITY.path, location.city, {
			shouldTouch: true,
		});
		setValue(STATE.path, location.state, {
			shouldDirty: true,
		});
		setValue(ZIP.path, location.zip, {
			shouldDirty: true,
		});
		setValue(COUNTY.path, location.county, {
			shouldDirty: true,
		});
		setValue(LATITUDE.path, location.latitude, {
			shouldDirty: true,
		});
		setValue(LONGITUDE.path, location.longitude, {
			shouldDirty: true,
		});
		rest.onChange(location.address);
	};
	const addressSearch = (query) => {
		try {
			if (query) {
				TrimbleMaps.Search.singleSearch({
					query: query,
					maxResults: 5,
				}).then((response) => {
					if (response?.Locations) {
						const locations = modelSearchItems(response.Locations);
						setGeoCodeLocations(locations);
					} else {
						setGeoCodeLocations([]);
					}
				});
			} else {
				setGeoCodeLocations([]);
			}
		} catch (e) {
			console.log(e);
		}
	};
	const debouncedAddressSearch = useMemo(
		() => debounce((query) => addressSearch(query), 500),
		[]
	);
	const onAddressChange = (value) => {
		rest.onChange(value);
		debouncedAddressSearch(value);
	};
	const { isStatic, onClickEdit, isReadOnly } = useFormSection();
	const focusRef = useRef(null);

	if (isStatic) {
		return (
			<StaticInput
				name={props.name}
				readOnly={isReadOnly || props.readOnly}
				key={`field-${props.name}`}
				label={props.label || props.labels.label}
				value={rest.value ?? ""}
				onClickEdit={() => onClickEdit(props.name)}
				{...props}
				span={props.span ?? 2}
			/>
		);
	}
	return (
		<>
			<span
				ref={(ref) => (focusRef.current = ref?.querySelector("input"))}
				style={{ gridColumn: `span ${props.span ?? 2}` }}
			>
				<Combobox
					{...props}
					value={rest.value ?? ""}
					events={{
						onChange: (event, { value }) => {
							onAddressChange(value);
						},
						onSelect: (event, data) => {
							const location = data.selection[data.selection.length - 1];
							changeLocation(location);
						},
						onFocus: () => {
							debouncedAddressSearch(rest.value ?? "");
						},
					}}
					onChange={(e, { value }) => onAddressChange(value)}
					labels={{
						label: "Address",
						placeholder: "Address",
					}}
					errorText={error?.message}
					multiple
					options={geoCodeLocations}
				/>
			</span>
		</>
	);
};

export const ControlledAddress = ({ ...props }) => {
	const {
		field: { value },
	} = useController({
		name: props.name,
	});
	const { isConcurrencyError, concurrencyDiff } = useFormContext();
	return !isConcurrencyError ? (
		<Address {...props} />
	) : (
		<span style={{ gridColumn: `span ${props.span ?? 2}` }}>
			{concurrencyDiff[props.name] ? (
				<ControlledConcurrency
					{...props}
					options={getConcurrencyOptions(concurrencyDiff[props.name])}
				/>
			) : (
				<SLDSInput
					name={props.name}
					key={`field-${props.name}`}
					label={props.label || props.labels.label}
					value={value}
					readOnly={true}
					styleInput={{ gridColumn: `span ${props.span ?? 2}` }}
				/>
			)}
		</span>
	);
};
