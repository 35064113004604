import React, { useState } from "react";
import { SideDrawer } from "./SideDrawer/SideDrawer";
import classes from "./MobileHeader.module.scss";
import { Button } from "@salesforce/design-system-react";
import { useNavigate } from "react-router-dom";
import { Badge } from "./ProfileIcon/Badge";

const MobileHeader = (props) => {
	const [rightSideBar, setRightSideBar] = useState(false);
	const navigate = useNavigate();

	return (
		<>
			<SideDrawer
				orientation="right"
				sideDrawerRight
				open={rightSideBar}
				closed={() => setRightSideBar(false)}
			/>
			<header className={classes.Toolbar}>
				<div>
					<img
						src={"/homebase_logo.png"}
						className={classes.HomeBaseLogo}
						onClick={() => navigate("/")}
						alt="HomeBase Logo"
					/>
				</div>
				<div className={classes.MenuButton}>
					<Button
						assistiveText={{ icon: "Icon Bare Small", label: "Profile" }}
						iconCategory="utility"
						iconName="apps"
						iconSize="large"
						onClick={() => setRightSideBar(true)}
						variant="icon"
					/>
					<div className={classes.BadgeContainer}>
						<Badge />
					</div>
				</div>
			</header>
		</>
	);
};

export default MobileHeader;
