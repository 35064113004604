import {
	ACCOUNT_NUMBER,
	ADDRESS,
	ASSIGNED_DATE,
	CITY,
	STATE,
	ZIP,
} from "@/features/asset/constants";
import { useLocationModal } from "../TripPlanning/LocationModal";
import { DataTableCell, Tooltip } from "@salesforce/design-system-react";
import { AiFillWarning } from "react-icons/ai";
import { BsFillPencilFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
	selectLocationsSelected,
	toggleLocation,
} from "../../store/tripPlanningSlice";
import dayjs from "dayjs";
import { AccountDataTableCell } from "@/features/asset-list/components/TableValues/TableValues";

import { usePageAssetList } from "@/features/asset-list/api/usePageAssetList";

const LocationSelect = ({ item, ...props }) => {
	const modal = useLocationModal();
	const hasLatLong = !(!item.latitude || !item.longitude);
	const dispatch = useDispatch();
	const locationsSelected = useSelector(selectLocationsSelected);
	const { trigger: triggerPage } = usePageAssetList();
	const onChange = (item) => dispatch(toggleLocation(item));
	return (
		<DataTableCell {...props}>
			<div
				style={{
					display: "flex",
					alignItems: "center",
				}}
			>
				{hasLatLong ? (
					<input
						type="checkbox"
						checked={
							locationsSelected?.filter((value) => value.id === item.id)
								.length > 0
						}
						onChange={() =>
							onChange({
								id: item.id,
								latitude: item.latitude,
								longitude: item.longitude,
								accountNumber: item.accountNumber,
								address: item.address,
								state: item.state,
								city: item.city,
								zip: item.zip,
							})
						}
					/>
				) : (
					<Tooltip content="Location Info Missing">
						<AiFillWarning
							style={{ width: ".8rem", height: ".8rem", fill: "orange" }}
						/>
					</Tooltip>
				)}

				<button
					style={{
						outline: "none",
						border: "none",
						backgroundColor: "transparent",
					}}
					onClick={() =>
						modal.show({ assetId: item.id, onUpdate: () => triggerPage(0) })
					}
				>
					<BsFillPencilFill style={{ width: ".8rem", height: ".8rem" }} />
				</button>
			</div>
		</DataTableCell>
	);
};
LocationSelect.displayName = DataTableCell.displayName;

const IndexCell = ({ items, item, ...props }) => {
	const index = items.map((i) => i.id).indexOf(item.id) + 1;
	return <DataTableCell {...props}>{index}</DataTableCell>;
};
IndexCell.displayName = DataTableCell.displayName;

export const columns = {
	index: {
		label: "#",
		title: "#",
		width: "10px",
		component: IndexCell,
		searchable: false,
		sortable: false,
	},
	[ACCOUNT_NUMBER.key]: {
		component: AccountDataTableCell,
		width: "25px",
		label: "Account",
		path: ACCOUNT_NUMBER.path,
		searchable: true,
	},
	[ADDRESS.key]: {
		label: ADDRESS.label,
		path: ADDRESS.path,
		title: ADDRESS.path,
		width: "30px",
		searchable: true,
	},
	[CITY.key]: {
		label: CITY.label,
		title: CITY.path,
		path: CITY.path,
		width: "20px",
		searchable: true,
	},
	[STATE.key]: {
		label: STATE.label,
		title: STATE.path,
		path: STATE.path,
		width: "15px",
		searchable: true,
	},
	[ZIP.key]: {
		label: ZIP.label,
		title: ZIP.path,
		path: ZIP.path,
		width: "15px",
		searchable: true,
	},
	[ASSIGNED_DATE.key]: {
		label: "Assigned",
		formatter: (value) => dayjs(value).format("MM/DD/YYYY"),
		width: "20px",
		path: ASSIGNED_DATE.path,
		searchable: false,
	},
	select: {
		label: "Select",
		component: LocationSelect,
		title: "Select",
		width: "12px",
		searchable: false,
		sortable: false,
	},
};
