import React, { useState } from "react";
import classes from "./AccountHeader.module.scss";
import {
	Icon,
	PageHeader,
	Button,
	PageHeaderControl,
} from "@salesforce/design-system-react";
import { headerDetails } from "./headerDetails";

//This header is for the account page /accountNumber/{accountNumber}
export const AccountHeader = ({ account }) => {
	// Get the details for the asset header (where business logic would go)
	const details = headerDetails(account);

	const [showDetails, setShowDetails] = useState(true);
	const chevron = showDetails ? "chevronup" : "chevrondown";

	// Action bar for Asset Header
	const actions = () => (
		<>
			<PageHeaderControl>
				<Button
					iconCategory="utility"
					iconName={chevron}
					iconSize="small"
					iconVariant="bare"
					variant="icon"
					onClick={() => setShowDetails(!showDetails)}
				/>
			</PageHeaderControl>
		</>
	);

	return (
		<>
			<div className={classes.assetHeader}>
				<PageHeader
					details={details}
					icon={
						<Icon
							assistiveText={{ label: "Home" }}
							category="standard"
							name="home"
							style={{
								backgroundColor: "rgb(13, 105, 13)",
								fill: "#ffffff",
							}}
						/>
					}
					onRenderActions={actions}
					title={account.accountNumber.toString() || ""}
					label="Account Details"
					variant={showDetails ? "record-home" : "object-home"}
				/>
			</div>
		</>
	);
};
