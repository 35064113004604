import React from "react";
import DesktopHeader from "../Components/DesktopHeader/DesktopHeader";
import "../App.scss";
import classes from "./Layout.module.scss";
import MobileHeader from "../Components/MobileNavigation/MobileHeader/MobileHeader";
import { Desktop, Mobile } from "../Responsive";
import AssetList from "../features/asset-list/components/AssetList";
import { AppLauncherHeader } from "../Components/DesktopHeader/Header";
import { HomebaseStatus } from "@/Components/UI/HomebaseStatus/HomebaseStatus";
import { Button } from "@salesforce/design-system-react";
import { pca, useIsAuth } from "@/auth";

export const AssetListContainer = () => {
	return (
		<div className={classes.AssetListContainer}>
			<AssetList />
		</div>
	);
};

export const Dashboard = ({ children, ...props }) => {
	const isAuth = useIsAuth();
	return (
		<>
			<Desktop>
				<DesktopHeader />
			</Desktop>
			<Mobile>
				<MobileHeader />
				<HomebaseStatus />
			</Mobile>
			<div className={classes.Content}>
				<Desktop>
					<AppLauncherHeader />
				</Desktop>
				{/* <LoginAlert /> */}
				<div className={classes.CustomBrandBand}>
					{isAuth ? (
						children
					) : (
						<div
							style={{
								display: "grid",
								placeItems: "center",
								height: "86vh",
								backgroundColor: "white",
								borderRadius: ".5rem",
							}}
						>
							<div
								style={{
									display: "flex",
									alignItems: "center",
									flexDirection: "column",
								}}
							>
								<p>Please log in to HomeBase</p>
								<Button variant="brand" onClick={() => pca.loginRedirect()}>
									Login
								</Button>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
