import React from "react";
import classes from "./EmailMedia.module.scss";
import {
	Modal,
	Button,
	Input,
	InputIcon,
	Textarea,
	File,
	ExpandableSection,
	DynamicIcon,
} from "@salesforce/design-system-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useGetAssetQuery } from "@/ReduxToolkit/GatewayApi";
import { useEmailMediaMutation } from "../../api/endpoints";
import { selectSelectedMediaEntities } from "../../api/selectors";
import { selectCurrentUser } from "@/features/user/api/selectors";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

const UNKNOWN = "Not Specified";

export const EmailMedia = NiceModal.create(
	({ selectedIds, accountId, assetId, webInWip, bucket }) => {
		const modal = useModal();
		const { email } = useSelector(selectCurrentUser);
		const isEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.trim());

		const [emailMedia, { isLoading, isSuccess, isError }] =
			useEmailMediaMutation();

		const schema = yup.object().shape({
			toAddress: yup
				.string()
				.transform((value) => Array.from(new Set(value.split(","))).join(",")) // dedupe - optional step
				.required("To is a required field")
				.test(
					"toAddress",
					"One or more invalid email addresses",
					(value) => value && value.split(",").every(isEmail)
				),
			ccAddress: yup
				.string()
				.transform((value) => Array.from(new Set(value.split(","))).join(",")) // dedupe - optional step
				.test(
					"ccAddress",
					"One or more invalid email addresses",
					(value) => value === "" || value.split(",").every(isEmail)
				),
			bccAddress: yup
				.string()
				.transform((value) => Array.from(new Set(value.split(","))).join(",")) // dedupe - optional step
				.test(
					"bccAddress",
					"One or more invalid email addresses",
					(value) => value === "" || value.split(",").every(isEmail)
				),
			subject: yup.string().required("Subject is a required field"),
			message: yup.string().required("Message is a required field"),
		});
		const { data: assetInfo } = useGetAssetQuery(assetId);
		const { ...asset } = assetInfo || {};
		const location = asset?.physicalLocation;
		// const { data: allMedia } = //useSelectAllMedia().media;
		const selectedEntities = useSelector(
			selectSelectedMediaEntities({ assetId, bucket })
		);

		const pictures = selectedEntities?.filter((media) =>
			selectedIds.includes(media.id)
		);

		const size =
			asset.length && asset.width
				? `${asset.length} X ${asset.width}`
				: UNKNOWN;

		const messageBuilder = `Dear , 
	
I've attached ${pictures.length} photos from Display Account #${
			asset.displayAccount || ""
		}.
Click on a photo to view a larger size.
  
Address: ${location.address}, ${location.city} ${location.state}, ${
			location.zip
		}
  
Year: ${asset.year ?? UNKNOWN}
Make: ${asset.manufacturer ?? UNKNOWN} 
Size: ${size}
Bedrooms: ${asset.bedrooms ?? UNKNOWN}
Bathrooms: ${asset.bathrooms ?? UNKNOWN}
  
Thanks,
${email}`;
		const toAddress = "";
		const bccAddress = "";
		const subject = `Selected photos from display Account Number #${
			asset.displayAccount || ""
		}`;

		const {
			control,
			handleSubmit,
			formState: { errors },
		} = useForm({
			resolver: yupResolver(schema),
			mode: "onBlur",
		});

		const onSubmit = async (data) => {
			const emailData = {
				...data,
				message: data.message.replace(/\n/g, "<br />"),
				accountNumber: asset?.account?.accountNumber,
				fromAddress: email,
				senderName: email,
				mediaPaths: pictures.map((pic) => pic.path),
			};

			const value = emailMedia(emailData);

			try {
				await value.unwrap();
				setTimeout(() => modal.remove(), 5000);
			} catch (e) {
				console.log(e);
			}
		};

		return (
			<div>
				<Modal
					isOpen={modal.visible}
					size="medium"
					dismissOnClickOutside={false}
					onRequestClose={() => {
						modal.remove();
					}}
					header={
						<>
							<h1 className="slds-text-heading_medium">Email Media</h1>
							{isSuccess && (
								<p className="slds-text-color_success"> Email Sent!</p>
							)}
						</>
					}
					footer={
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div className={classes.emailActions}>
								<Button
									key="Cancel"
									label="Cancel"
									onClick={() => {
										modal.remove();
									}}
									disabled={isLoading}
								/>
								<Button
									type="submit"
									value="submit"
									variant={isSuccess ? "success" : "brand"}
									form="form-id"
									disabled={isLoading}
								>
									{!isLoading && !isSuccess && "Send"}
									{isLoading && (
										<DynamicIcon
											title="waiting"
											variant="typing"
											size="xx-small"
										/>
									)}
									{isSuccess && "Sent!"}
								</Button>
							</div>
							{isError && (
								<p
									style={{
										color: "red",
										textAlign: "center",
									}}
								>
									Something went wrong, try again
								</p>
							)}
						</div>
					}
				>
					<form
						id="form-id"
						onSubmit={handleSubmit((data) => {
							onSubmit(data);
						})}
						method="POST"
					>
						<div className="slds-p-around_x-small">
							<div className={classes.sldsElement}>
								<p className="bold-label">From: {email}</p>
							</div>
							<ExpandableSection
								className={classes.expandableRecipients}
								title="Recipient Contact Info"
							>
								<div className={classes.sldsElement}>
									<Controller
										name="toAddress"
										control={control}
										defaultValue=""
										render={({ field: { ref, ...rest } }) => (
											<Input
												ref={ref}
												label="To: (Separate Email addresses with commas)"
												className={classes.label}
												type="text"
												name="toAddress"
												autoComplete="email"
												defaultValue={toAddress}
												iconLeft={<InputIcon name="email" />}
												errorText={errors.toAddress?.message}
												placeholder="John.Doe@claytonhomes.com,Jane.Doe@claytonhomes.com"
												{...rest}
											/>
										)}
									/>
								</div>
								<div className={classes.sldsElement}>
									<Controller
										name="ccAddress"
										control={control}
										defaultValue={email}
										render={({ field: { ref, ...rest } }) => (
											<Input
												label="CC: (Separate Email addresses with commas)"
												className={classes.label}
												type="text"
												name="ccAddress"
												autoComplete="email"
												defaultValue={email}
												iconLeft={<InputIcon name="email" />}
												errorText={errors.ccAddress?.message}
												placeholder="Email"
												{...rest}
											/>
										)}
									/>
								</div>
								<div className={classes.sldsElement}>
									<Controller
										name="bccAddress"
										control={control}
										defaultValue={bccAddress}
										render={({ field: { ref, ...rest } }) => (
											<Input
												label="BCC: (Separate Email addresses with commas)"
												className={classes.label}
												type="text"
												name="bccAddress"
												autoComplete="email"
												defaultValue=""
												iconLeft={<InputIcon name="email" />}
												errorText={errors.bccAddress?.message}
												placeholder="Email"
												{...rest}
											/>
										)}
									/>
								</div>
							</ExpandableSection>
							<div className={classes.sldsElement}>
								<Controller
									name="subject"
									control={control}
									defaultValue={subject}
									render={({ field: { ref, ...rest } }) => (
										<Input
											label="Subject:"
											className="bold-label"
											type="text"
											name="subject"
											autoComplete="subject"
											errorText={errors.subject?.message}
											placeholder="Subject"
											{...rest}
										/>
									)}
								/>
							</div>
							<div className={classes.sldsTextArea}>
								<Controller
									name="message"
									control={control}
									defaultValue={messageBuilder}
									render={({ field: { ref, ...rest } }) => (
										<Textarea
											label="Message:"
											className={classes.textArea}
											name="message"
											id="emailMediaTextArea"
											autoComplete="Message"
											errorText={errors.message?.message}
											{...rest}
										/>
									)}
								/>
							</div>
							<div className={classes.sldsElement}>
								<p>{selectedIds.length} image(s) to be sent:</p>
							</div>
							<div className={classes.pictures}>
								{pictures.map((media) => (
									<File
										id={media.id}
										labels={{
											title: media.title,
										}}
										hasNoVisibleTitle
										className={classes.picture}
										key={media.id}
										image={media.path}
										onClickImage={() => window.open(media.path)}
									/>
								))}
							</div>
						</div>
					</form>
				</Modal>
			</div>
		);
	}
);

NiceModal.register("email-media", EmailMedia);

export const useEmailMediaModal = () => {
	const modal = useModal("email-media");

	return modal;
};
